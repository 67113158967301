import FormElement from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { defaultValue } from "./AccessLevelTree/defaultValue";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";

function mergeBooleanObjects(obj1, obj2) {
	const merged = { ...obj1 };

	for (const key in obj2) {
		if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
			// Recursively merge nested objects
			merged[key] = mergeBooleanObjects(obj1[key] || {}, obj2[key]);
		} else if (!(key in obj1) && typeof obj2[key] === 'boolean') {
			// Add the key only if it does not exist in obj1 and is a boolean
			merged[key] = obj2[key];
		}
	}

	return merged;
}

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.general.roles");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback((values) => {
		submit({ ...values, level: Number(values?.level) });
	}, [submit])

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
			level: values?.level?.toString(),
			permissions: mergeBooleanObjects(values?.permissions
				? values.permissions
				: defaultValue,
				values?.permissions
					? defaultValue
					: {}
			),
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Content mode={mode} disabled={disabled || !isMasterCompany} />
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled && isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
