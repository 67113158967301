import { useCallback, useEffect, useMemo, useState } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { omit } from "lodash";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { useGroups } from "../../../../Settings/General/OrganisationLevelGroups/context";
import { useEarningGroupsContext } from "../../../../TimeSheet/earningGroupsContext";
import { useAddedPayCode } from "../../../../TimeSheet/components/TimeSheetTable/hooks/useAddedPayCode";
import { calculateSectionsWithAutomaticBreaks, getAutomaticBreaks, recalculateSections } from "../../../../NewScheduler/Component/helpers/sections";
import { useCalculatedSections } from "../../../../NewScheduler/Component/hooks/useCalculatedSections";
import { useCreateEvent, useEditEvent } from "../api/useEventsApi";
import { useCompany } from "../../../../../utils/hooks/company";
import { getPrimaryActiveResource } from "../../../../../utils/helpers/user";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { useAccess } from "../../../../../utils/hooks/access";
import { usePreset } from "../PresetContext";
import { getFormFormattedValues } from "../../../../NewScheduler/Component/helpers/getFormFormattedValues";


function EventForm({ isTeamScheduler, resource, eventRecord, addAnotherEvent, schedulerInstance, close, mutate }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const company = useCompany();
    const { mode, day } = usePreset();
    const { allocatedGroups } = useGroups();
    const [isFieldsValueSet, setIsFieldsValueSet] = useState(false);
    const {
        hasAccess: canEdit
    } = useAccess(isTeamScheduler ? "schedule.canEdit" : "schedule.canEditMySchedule");
    const {
        hasAccess: canCreate
    } = useAccess(!isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
    
    const { data: earningGroups } = useEarningGroupsContext();
    const { create, loading: loadingCreate } = useCreateEvent({ isTeamScheduler })
    const { edit, loading: loadingEdit } = useEditEvent({ isTeamScheduler });

    const startDate = Form.useWatch("startDate", form);
    const endDate = Form.useWatch("endDate", form);

    const defaultEarningGroup = earningGroups?.find(group => group?.code === "REG");
    const defaultPayCode = useAddedPayCode(resource);
    const primaryJob = getPrimaryActiveResource(resource?.jobs);
    const primaryLocation = getPrimaryActiveResource(resource?.locations);

    const levels = useMemo(() => {
        return allocatedGroups?.reduce((total, group) => {
            const level = group?.level.replace("_", "");
            if (resource && resource?.[level]) {
                total[level] = resource[level];
            }
            return total;
        }, {});
    }, [allocatedGroups, resource]);

    const defaultSection = useMemo(() => {
        const scheduleSettings = eventRecord?.calculationGroup?.schedule || resource?.calculationGroup?.schedule || company?.settings?.schedule;
        const shiftStart = scheduleSettings?.shiftStart ? moment.parseZone(scheduleSettings.shiftStart) : moment().startOf("day").hour(9);
        const shiftEnd = scheduleSettings?.shiftEnd ? moment.parseZone(scheduleSettings.shiftEnd) : moment().startOf("day").hour(17);
       
        let start = moment(eventRecord.startDate);
        let end = moment(eventRecord.endDate);

        if (schedulerInstance?.viewPreset?.data?.id !== "hourAndDay") {
            start = combineDateAndTime(moment(eventRecord.startDate), shiftStart);
            end = combineDateAndTime(moment(eventRecord.startDate), shiftEnd);
        }

        const duration = moment(end).diff(moment(start), "seconds");

        return {
            type: "regular",
            payCode: defaultPayCode,
            location: primaryLocation,
            job: primaryJob,
            duration: renderDurationAsFormat(duration, "HH:mm"),
            start,
            end,
            ...levels
        };
    }, [
        eventRecord,
        resource?.calculationGroup?.schedule,
        company?.settings?.schedule,
        schedulerInstance?.viewPreset?.data?.id,
        defaultPayCode,
        primaryLocation,
        primaryJob,
        levels
    ]);

    const sectionsWithAutomaticBreak = useMemo(() => {
        let end = moment(endDate).isBefore(moment(startDate), "seconds") ? moment(endDate).add(1, "day") : endDate;
        const duration = moment(end).diff(moment(startDate), "seconds");

        let sections = [{
            ...defaultSection,
            start: startDate,
            end,
            duration: renderDurationAsFormat(duration, "HH:mm"),
            canCalculateAutomaticBreaks: eventRecord?.isCreating,
        }];

        const breaks = getAutomaticBreaks(
            eventRecord?.calculationGroup?.schedule || resource?.calculationGroup?.breaks,
            sections,
            sections[0]
        );

        if (breaks?.length > 0) {
            sections = calculateSectionsWithAutomaticBreaks(sections, breaks);
        }
        return sections;
    }, [eventRecord, startDate, endDate, defaultSection, resource?.calculationGroup?.breaks]);

    const sections = useCalculatedSections({
        startTime: startDate,
        endTime: endDate,
        eventRecord,
        schedulerInstance,
        defaultSection,
        sections: sectionsWithAutomaticBreak
    });

    const initialValues = useMemo(() => {
        const startDate = moment.parseZone(eventRecord?.startDate).toDate();
        let endDate = moment.parseZone(eventRecord?.endDate).toDate();

        if (moment(endDate).isBefore(moment(startDate), "seconds")) {
            endDate = moment(endDate).add(1, "day");
        }

        const duration = moment(endDate).diff(moment(startDate), "seconds");
        const eventType = eventRecord?.eventType || defaultEarningGroup;
        return {
            day: mode === "day" ? day : !eventRecord?.isCreating ? eventRecord?.date : moment(eventRecord?.startDate).format("dddd").toLowerCase(),
            date: moment(eventRecord?.startDate).toDate(),
            startDate: !eventType?.allowSegmentation ? moment.parseZone(sections?.[0]?.start).toDate() : startDate,
            endDate: !eventType?.allowSegmentation ? moment.parseZone(sections?.[sections?.length - 1]?.end).toDate() : endDate,
            duration: renderDurationAsFormat(duration, "HH:mm"),
            resourceId: eventRecord?.user || eventRecord?.resourceId,
            eventType,
            payCode: !eventType?.allowSegmentation && (eventRecord?.payCode || defaultPayCode),
            job: !eventType?.allowSegmentation && (sections?.[0]?.job || primaryJob),
            location: !eventType?.allowSegmentation && (sections?.[0]?.location || primaryLocation),
            sections: addAnotherEvent ? [defaultSection] : sections,
            ...levels
        };
    }, [
        eventRecord,
        defaultEarningGroup,
        mode,
        day,
        sections,
        defaultPayCode,
        primaryJob,
        primaryLocation,
        addAnotherEvent,
        defaultSection,
        levels
    ]);

    const onClose = useCallback(() => {
        if (schedulerInstance?.editingRecord?.isCreating) {
            schedulerInstance?.editingRecord.remove();
            delete schedulerInstance?.editingRecord;
        }
        close();
    }, [close, schedulerInstance?.editingRecord]);

    const onFailure = useCallback(() => {
        if (schedulerInstance?.editingRecord?.isCreating) {
            schedulerInstance?.editingRecord.remove();
            delete schedulerInstance?.editingRecord;
        }
    }, [schedulerInstance?.editingRecord]);

    const onSuccess = useCallback((response, type) => {
        mutate((prev) => {
            const data = {
                data: {
                    resources: prev?.data?.resources,
                    events: type === "create" ? [
                        ...(prev?.data?.events || []),
                        response,
                    ] : prev?.data?.events?.map((event) => {
                        if (event?.id === response?.id) {
                            return response;
                        } else {
                            return event;
                        }
                    })
                },
                meta: prev?.meta,
            };
            return data;
        }, { revalidate: false });
    }, [mutate]);

    const onFinish = useCallback(async (values) => {
        const date = moment(eventRecord?.startDate || eventRecord?.date).format('YYYY-MM-DD');

        let startDate = combineDateAndTime(moment(date), moment(values.startDate));
        let endDate = combineDateAndTime(moment(date), moment(values.endDate));

        if (startDate && endDate && moment(endDate).isBefore(startDate, "seconds")) {
            endDate.add(1, "day");
        }

        const isMandatoryBreak = eventRecord?.calculationGroup?.breaks?.manual?.mandatory ||
            resource?.calculationGroup?.breaks?.manual?.mandatory;
        const breakAmount = isMandatoryBreak
            ? Number(
                eventRecord?.calculationGroup?.breaks?.manual?.duration ||
                resource?.calculationGroup?.breaks.manual.duration ||
                0
            )
            : 0;

        let sections = recalculateSections(
            values.sections,
            defaultSection,
            breakAmount * 60,
            eventRecord?.calculationGroup?.breaks || resource?.calculationGroup?.breaks,
            date
        );

        let breaks = getAutomaticBreaks(
            eventRecord?.calculationGroup?.breaks || resource?.calculationGroup?.breaks,
            sections,
            defaultSection
        );
        const sectionBreaks = sections.filter(section => section?.type === "break");
        breaks = breaks.slice(sectionBreaks.length);

        let calculatedSections = sections;
        if (
            breaks.length > 0 &&
            (eventRecord?.calculationGroup?.breaks?.automatic?.status ||
                resource?.calculationGroup?.breaks.automatic.status) &&
            !eventRecord?.isCreating
        ) {
            const lastBreakIndex = sections.findLastIndex(shift => shift.type === "break");
            calculatedSections = [
                ...sections.slice(0, lastBreakIndex + 1),
                ...calculateSectionsWithAutomaticBreaks(sections.slice(lastBreakIndex + 1), breaks)
            ];
        }

        const data = getFormFormattedValues({
            id: eventRecord?.id,
            ...values,
            startDate,
            endDate,
            sections: calculatedSections,
            company
        });

        await eventRecord.setAsync({
            ...data,
            day,
            startDate: moment(startDate).toISOString(true),
            endDate: moment(endDate).toISOString(true),
            duration: moment(endDate).diff(moment(startDate), "seconds"),
            durationUnit: "second"
        });

        if ((eventRecord.isCreating || addAnotherEvent) && canCreate) {
            await create(
                "default",
                omit({ ...data, date: values?.day }, ["id"]),
                schedulerInstance,
                (response) => onSuccess(response, "create"),
                onFailure
            );
            close();
        } else if (canEdit) {
            await edit(
                "default",
                { ...data, date: values?.day },
                schedulerInstance,
                (response) => onSuccess(response),
                onFailure
            );
            close();
        }
    }, [addAnotherEvent, canCreate, canEdit, close, company, create, day, defaultSection, edit, eventRecord, onFailure, onSuccess, resource?.calculationGroup?.breaks, schedulerInstance]);

    const eventType = Form.useWatch("eventType", form);
    const _sections = Form.useWatch("sections", form);

    useEffect(() => {
        if (eventRecord?.isCreating && sections?.[0]?.start && !addAnotherEvent && !isFieldsValueSet) {
            form.setFieldsValue({
                sections,
                ...defaultSection,
            });
            setIsFieldsValueSet(true);
        }

        if (
            (!eventType?.allowSegmentation && eventRecord?.id?.indexOf("_generatedEvent") > -1) ||
            addAnotherEvent
        ) {
            form.setFieldsValue(defaultSection)
        }
    }, [defaultSection, eventRecord, eventType?.allowSegmentation, form, sections, addAnotherEvent, isFieldsValueSet]);

    useEffect(() => {
        if (eventType?.allowSegmentation) {
            if (_sections?.length > 0) {
                form.setFieldValue("payCode", _sections?.[0]?.payCode);
                form.setFieldValue("location", sections?.[0]?.location);
                form.setFieldValue("job", sections?.[0]?.job);
                if (_sections?.[0]?.start) {
                    form.setFieldValue("startDate", _sections[0].start);
                };
                if (_sections?.[_sections?.length - 1]?.end) {
                    form.setFieldValue("endDate", _sections[_sections.length - 1]?.end);
                }
            }
        }
    }, [form, eventType?.allowSegmentation, _sections, sections]);

    return (
        <Form
            className="h-100 p-0 m-0 w-full"
            form={form}
            initialValues={initialValues}
            onFinish={onFinish}
        >

            <div
                className="px-4 py-3"
                style={{ height: "calc(100% - 75px)", overflowY: "auto", overflowX: "hidden" }}
            >
                <Content
                    form={form}
                    eventRecord={eventRecord}
                    resource={resource}
                    defaultSection={defaultSection}
                    addAnotherEvent={addAnotherEvent}
                />
            </div>
            <div className="d-flex justify-content-end align-items-center p-4">
                <Button
                    color="muted"
                    type="button"
                    onClick={onClose}
                    disabled={loadingCreate || loadingEdit}
                >
                    {t("cancel")}
                </Button>

                <Button
                    color="primary"
                    type="submit"
                    disabled={loadingCreate || loadingEdit}
                    loading={loadingCreate || loadingEdit}
                >
                    {t("save")}
                </Button>
            </div>
        </Form>
    );
}

export default EventForm;
