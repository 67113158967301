import classnames from "classnames";
import { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";

const ReportSettings = ({ activeSetting, handleSettingChange }) => {
  const { t } = useTranslation();

  return (
    <div
      className="settings-sidebar"
      style={{ minWidth: "200px", marginRight: "20px" }}
    >
      <ul className="list-unstyled">
        {[
          { key: "measures", label: t("measures") },
          { key: "orders", label: t("orders") },
          { key: "filters", label: t("default-filters") },
          // { key: "customFilters", label: t("custom-filters") },
          { key: "calculations", label: t("calculations") },
          { key: "unions", label: t("unions") },
        ].map(({ key, label }) => (
          <BaseField shouldUpdate key={key}>
            {(_, __, { getFieldValue }) => {
              const measures = getFieldValue("measures") || [];
              const unions = getFieldValue("unions") || [];
              const isMeasures = key === "measures";
              const isUnions = key === "unions";

              let disabled = false;
              if (isUnions && measures.length > 0) {
                disabled = true;
              }
              if (isMeasures && unions.length > 0) {
                disabled = true;
              }

              return (
                <li
                  className={classnames("cursor-pointer py-2", {
                    "text-primary font-weight-bold": activeSetting === key,
                    "text-muted cursor-not-allowed": disabled,
                  })}
                  onClick={() => {
                    if (!disabled) handleSettingChange(key);
                  }}
                >
                  {label}
                </li>
              );
            }}
          </BaseField>
        ))}
      </ul>
    </div>
  );
}

export default ReportSettings;
