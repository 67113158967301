import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";

const ConfirmModal = ({ isOpen, close, selected, submit }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} close={close} centered>
            <div className="d-flex justify-content-end cursor-pointer mt-2">
                <FontAwesomeIcon icon={faXmark} onClick={close}/>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center py-5">
                <FontAwesomeIcon icon={faCircleExclamation} className="text-primary mb-3" size="2xl" />
                {selected === "breaks" ? (
                    <h3>{t("confirm-break-cancelation")}</h3>
                ) : (
                    <h3>{t("confirm-overtime-cancelation")}</h3>
                )}
            </div>
            <div className="d-flex align-items-center justify-content-end">
                <Button color="muted" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button color="primary" onClick={submit}>
                    {t("save")}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
