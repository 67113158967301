import { useCallback, useEffect } from "react";
import { useValidateDomain } from "../../../utils/api/company";
import { isCancelError } from "../../../utils/helpers/errors";
import { getSubDomainFromDomain } from "../../../utils/helpers/url";
import { useQrCodeClockIn } from "../context";

export const useValidate = () => {
	const { domain, hasValidatedDomain, dispatch } = useQrCodeClockIn();
	const validateDomain = useValidateDomain();

	const validate = useCallback(
		async (controller) => {
			dispatch({ type: "validate-domain" });
			try {
				const subDommain = getSubDomainFromDomain(domain);
				const { status, name } = await validateDomain(subDommain, {
					signal: controller.signal,
				});
				if (!status) {
					dispatch({ type: "validate-domain-failed" });
				} else {
					dispatch({
						type: "validate-domain-succeeded",
						payload: name,
					});
				}
			} catch (err) {
				if (isCancelError(err)) {
					dispatch({ type: "validate-domain-cancel" });
					return;
				}
				dispatch({ type: "validate-domain-failed" });
			}
		},
		[domain, dispatch, validateDomain],
	);

	useEffect(() => {
		const controller = new AbortController();
		localStorage.removeItem("TOKEN");
        sessionStorage.removeItem("TOKEN");
		
		if (!hasValidatedDomain) {
			validate(controller);
		}
		return () => controller.abort();
	}, [hasValidatedDomain, validate]);
};
