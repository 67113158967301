import { PayCodeCalcRule } from "../../components/Inputs/PaycodeCalcRules";

export const getRateFromJobAndPayCode = (job, payCode) => {
    let rate = 0;
    if (payCode && payCode.defaultRate) {
        rate = payCode.defaultRate;
    } else if (payCode?.calcRule && job) {
        switch (payCode.calcRule) {
            case PayCodeCalcRule.RATE1:
                rate = typeof job?.otherRate1 === 'number' ? job?.otherRate1 : (job?.hourlyRate || 0);
                break;
            case PayCodeCalcRule.RATE2:
                rate = typeof job?.otherRate2 === 'number' ? job?.otherRate2 : (job?.hourlyRate || 0);
                break;
            case PayCodeCalcRule.RATE3:
                rate = typeof job?.otherRate3 === 'number' ? job?.otherRate3 : (job?.hourlyRate || 0);
                break;
            case PayCodeCalcRule.RATE4:
                rate = typeof job?.otherRate4 === 'number' ? job?.otherRate4 : (job?.hourlyRate || 0);
                break;
            case PayCodeCalcRule.REG:
                rate = job?.hourlyRate || 0;
                break;
            case PayCodeCalcRule.REGB:
                    rate = Number(job?.hourlyRate || 0) + Number(payCode.extraRate || 0) ;
                    break;
            case PayCodeCalcRule.AMT:
                break;
            case PayCodeCalcRule.UNPAID:
                rate = 0;
                break;
            default:
                rate = job?.hourlyRate || 0;
                break;
        }
    } else if (job && job.hourlyRate) {
        rate = job?.hourlyRate || 0;
    }
    return parseFloat(`${rate}`);
}