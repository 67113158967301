import { useTranslation } from "react-i18next";
import { useAccess } from "../../../utils/hooks/access";
import { useUser } from "../../../utils/hooks/user";

const DesktopContextMenu = ({
  visible,
  x,
  y,
  item,
  onCreateFolder,
  onEditItem,
  onDeleteItem,
  onExportItem,
  onInfoItem,
}) => {
  const { t } = useTranslation();
  const user = useUser();

  const { hasAccess: canExport } = useAccess("reports.canExport");
  const { hasAccess: canCreate } = useAccess("reports.canCreate");
  const { hasAccess: canEdit } = useAccess("reports.canEdit");
  const { hasAccess: canDelete } = useAccess("reports.canDelete");

  if (!visible) return null;

  return (((canExport || canDelete || canEdit) && item) || (!item && canCreate)) && (
    <ul
      className="desktop-context-menu"
      style={{ top: y, left: x, position: 'fixed' }}
    >
      {item ? (
        <>
          {canEdit && user?.id === item?.createdBy && !item?.reportData?.isDefault &&
            <li onClick={onEditItem}>{t('edit')}</li>
          }
          {item.type !== 'folder' && (
            <>
              {canExport &&
                <li onClick={onExportItem}>{t('export')}</li>
              }
              <li onClick={onInfoItem}>{t('info')}</li>
            </>
          )}
          {canDelete && user?.id === item?.createdBy && !item?.reportData?.isDefault &&
            <li onClick={onDeleteItem}>{t('delete')}</li>
          }
        </>
      ) : canCreate &&
      <li onClick={onCreateFolder}>{t('create-new-folder')}</li>
      }
    </ul>
  );
};

export default DesktopContextMenu;
