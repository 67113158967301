import { useCallback } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function ResponsiveGridLayout({
  layout,
  resizeHandles,
  editable,
  onLayoutChange,
  onBreakpointChange,
  currentBreakpoint,
  children,
}) {
  const gridLayoutChange = useCallback(
    (newLayout) => {
      onLayoutChange(
        newLayout.map((newLayoutItem) => {
          const layoutItem = layout.find(
            (layoutItem) => newLayoutItem.i === layoutItem.i
          );
          return {
            ...layoutItem,
            ...newLayoutItem,
          };
        })
      );
    },
    [layout, onLayoutChange]
  );

  return (
    <ResponsiveReactGridLayout
      className="layout"
      layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={30}
      width={1200}
      resizeHandles={resizeHandles}
      isDraggable={editable}
      onLayoutChange={gridLayoutChange}
      onBreakpointChange={onBreakpointChange}
      measureBeforeMount={false}
      breakpoint={currentBreakpoint}
    >
      {children}
    </ResponsiveReactGridLayout>
  );
}

export default ResponsiveGridLayout;
