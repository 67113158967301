import { useState } from "react";
import useSwr from "swr";
import Select from "../../../../../components/Inputs/Select";
import { useCompanyId } from "../../../../../utils/hooks/company";
import { systemReports } from "../../../../Reports/SystemReports/Table/systemReports";
import { OptGroup } from "rc-select";
import { t } from "i18next";

function ReportSelect({ form, onChange, value, ...props }) {
    const [componentValue, setComponentValue] = useState(value);
    const companyId = useCompanyId();
    
    const { data, isLoading } = useSwr(() => {
        if (!companyId) {
            return null;
        }
        return {
            url: "/reports",
            params: {
                company: companyId,
                pagination: "false",
                all: true,
            },
        };
    });

    return (
        <Select
            loading={isLoading}
            showSearch
            value={componentValue}
            optionFilterProp="children"
            onChange={(value, { isSystem }) => {
                form.setFieldValue("isSystem", isSystem);
                const systemReport = systemReports?.find((report) => report.id === value);
                const customReport = data?.result?.find((report) => report?.id === value);

                const report = systemReport || customReport;
                onChange(report);
                setComponentValue(value);
            }}
            {...props}
        >
            {isLoading ? null : (
                <>
                    <OptGroup label={t("system-reports")}>
                        {systemReports.map((report) => (
                            <Select.Option key={report.id} value={report.id} isSystem={true}>
                                {report.name}
                            </Select.Option>
                        ))}
                    </OptGroup>
                    <OptGroup label={t("custom-reports")}>
                        {data?.result.map((option) => (
                            <Select.Option key={option?.id} value={option?.id} isSystem={false}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </OptGroup>
                </>
            )}
        </Select>
    );
}

export default ReportSelect;