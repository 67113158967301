import Calculation from "../selectors/Calculation";
import Filter from "../selectors/Filter";
import Measure from "../selectors/Measure";
import Order from "../selectors/Order";
import Unions from "../selectors/Unions";
import ReportSettings from "./ReportSettings";

const SettingsTab = ({ activeSetting, handleSettingChange, form, setFormData }) => (
  <div className="d-flex">
    <ReportSettings
      activeSetting={activeSetting}
      handleSettingChange={handleSettingChange}
    />
    <div className="w-100">
      {activeSetting === "measures" && <Measure setFormData={setFormData} />}
      {activeSetting === "orders" && <Order setFormData={setFormData} />}
      {activeSetting === "filters" && (
        <Filter form={form} setFormData={setFormData} />
      )}
      {activeSetting === "calculations" && <Calculation setFormData={setFormData} />}
      {activeSetting === "unions" && <Unions setFormData={setFormData} />}
    </div>
  </div>
);

export default SettingsTab;
