import { omit } from "lodash";
import { useCallback, useState } from "react";

export const useEditMode = (layout, setLayout, submit, userPreferences) => {
  const [editable, setEditable] = useState(false);
  const [staticCards, setStaticCards] = useState({});
  const [resizeHandles, setResizeHandles] = useState([]);

  const toggleEditable = useCallback(() => {
    setEditable(!editable);
    setResizeHandles(!editable ? ["se"] : []);
  }, [editable]);

  const toggleStaticCard = useCallback(
    (key) => {
      setStaticCards((prevStaticCards) => {
        const newStaticCards = { ...prevStaticCards };
        newStaticCards[key] = !prevStaticCards[key];
        setLayout((prevLayout) =>
          prevLayout.map((item) =>
            item.i === key ? { ...item, static: newStaticCards[key] } : item
          )
        );
        const data = layout
          .map((item) => ({
            ...item,
            static: item.i === key ? newStaticCards[key] : item.static,
          }))
          .reduce((total, current) => {
            total = { ...total, [current.i]: omit(current, ["i"]) };
            return total;
          }, {});
        submit({
          entity: "user",
          config: { ...userPreferences?.dashboardSettings, ...data },
        });
        return newStaticCards;
      });
    },
    [layout, setLayout, submit, userPreferences?.dashboardSettings]
  );

  const enableAllHandles = useCallback(() => {
    setResizeHandles(['se', 'sw', 'ne', 'n', 's', 'e', 'w']);
  }, []);

  return {
    editable,
    setEditable,
    toggleEditable,
    staticCards,
    setStaticCards,
    toggleStaticCard,
    resizeHandles,
    setResizeHandles,
    enableAllHandles
  };
};
