import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getPrimaryActiveResource, renderUserName } from "../../../utils/helpers/user";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../utils/hooks/company";
import moment from "moment-timezone";
import { useUser } from "../../../utils/hooks/user";

const columnHelper = createColumnHelper();

export const useColumns = () => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();
    const timeFormat = useCompanyTimeFormat();
    const user = useUser();

    const primaryLocation = useMemo(
        () => getPrimaryActiveResource(user?.locations || []),
        [user?.locations]
    );

    return useMemo(() => {
        const columns = [
            columnHelper.accessor("name", {
                enableSorting: false,
                header: t("name"),
                cell: (info) => t(info.getValue()),
            }),
            columnHelper.accessor("createdBy", {
                enableSorting: false,
                header: t("created-by"),
                cell: (info) => renderUserName(info.row.original.createdBy),
            }),
            columnHelper.accessor("createdAt", {
                enableSorting: false,
                header: t("created-at"),
                cell: (info) => {
                    const createdAt = info.getValue();
                    const timezone = primaryLocation?.timezone || "UTC";

                    return (
                        <>
                            {moment(createdAt).tz(timezone).format(dateFormat)} -{" "}
                            {moment(createdAt).tz(timezone).format(timeFormat)}
                        </>
                    );
                },
            }),
            columnHelper.accessor("expiresAt", {
                enableSorting: false,
                header: t("expires-at"),
                cell: (info) => {
                    const expiresAt = t(info.getValue());
                    return moment(expiresAt).format(dateFormat)
                },
            }),
            columnHelper.accessor("status", {
                enableSorting: false,
                header: t("status"),
                cell: (info) => t(info.getValue()),
            }),
            columnHelper.display({
                id: "actions",
                header: t("actions"),
                cell: (info) => {
                    const canDownload = info.row.original?.data;

                    return (
                        canDownload && (
                            <a href={canDownload.url} className="text-primary cursor-pointer ml-3">
                                <FontAwesomeIcon icon={faDownload} size="lg" />
                            </a>
                        )
                    );
                },
            }),
        ];
        return columns;
    }, [dateFormat, timeFormat, primaryLocation?.timezone, t]);
};
