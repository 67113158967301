import CalculationContent from "./Calculation";
import MeasureContent from "./Measure";

const Content = ({ selectedType, data, dimensions }) => {

  return (
    <div>
      {selectedType === "measures" ? <MeasureContent data={data} dimensions={dimensions} /> : <CalculationContent data={data} dimensions={dimensions} />}
    </div>
  );
};

export default Content;
