import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap";
import Field from "../../../components/Field";
import Text from "../../../components/Inputs/Text";
import ColumnModal from "./ColumnModal";
import ChartTab from "./components/ChartTab";
import ColumnsTab from "./components/ColumnsTab";
import NavTabs from "./components/NavTabs";
import ReportButtons from "./components/ReportButtons";
import SettingsTab from "./components/SettingsTab";
import useReportForm from "./hooks/useReportForm";

const NReportForm = () => {
  const { t } = useTranslation();

  const {
    form,
    previewModal,
    isColumnModalOpen,
    openPreviewModal,
    openColumnModal,
    closeColumnModal,
    selectedType,
    activeTab,
    toggleTab,
    activeSetting,
    handleSettingChange,
    onFinish,
    handleValuesChange,
    formData,
    setFormData,
  } = useReportForm();

  return (
    <Card className="h-100">
      <Form
        className="h-100"
        form={form}
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
      >
        <CardBody className="h-100 w-100 p-4">
          <div
            className="mb-3"
            style={{ height: "calc(100% - 50px)", overflowY: "auto", overflowX: "hidden" }}
          >
            <Row className="mb-4">
              <Col md={6}>
                <Field
                  name="name"
                  label={t("report-name")}
                  rules={[
                    {
                      required: true,
                      message: t("report-name-required"),
                    },
                  ]}
                >
                  <Text placeholder={t("report-name")} value="" />
                </Field>
              </Col>
              <Col md={6} className="d-flex align-items-center justify-content-end gap-2">
                <ReportButtons
                  formData={formData}
                  openPreviewModal={openPreviewModal}
                />
              </Col>
            </Row>

            <NavTabs activeTab={activeTab} toggleTab={toggleTab} t={t} />

            <TabContent activeTab={activeTab}>
              <TabPane tabId="columns">
                <ColumnsTab openColumnModal={openColumnModal} setFormData={setFormData} />
              </TabPane>

              <TabPane tabId="settings">
                <SettingsTab
                  activeSetting={activeSetting}
                  handleSettingChange={handleSettingChange}
                  form={form}
                  setFormData={setFormData}
                />
              </TabPane>

              <TabPane tabId="chart">
                <ChartTab />
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Form>

      {previewModal}

      {isColumnModalOpen && (
        <ColumnModal
          isOpen={isColumnModalOpen}
          close={closeColumnModal}
          selectedType={selectedType}
          form={form}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </Card>
  );
};

export default NReportForm;
