import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";
import { useFields } from "../api";

export const useColumns = ({ config }) => {
  const { t } = useTranslation();

  const {
    fields,
    getFields,
  } = useFields();

  useEffect(() => {
    const controller = new AbortController();
    getFields(controller);
    return () => controller.abort();
  }, [getFields]);

  const mergedConfig = useMemo(() => {
    if (!fields || !config) return [];

    const formattedFields = fields?.map((field) => field?.fields?.map(item => ({ ...item, table: field?.table })))?.flat();

    return config.map((column) => {
      const [type, name] = column.name.split(".");

      const matchingField = formattedFields?.find(
        (fieldGroup) => fieldGroup?.table === type && fieldGroup?.name === name
      );

      return {
        ...column,
        display_name: column.display_name || matchingField?.display_name || name,
        fieldType: matchingField?.fieldType,
        options: matchingField?.options,
      };
    });
  }, [fields, config]);

  return useMemo(() => {
    const columnHelper = createColumnHelper();
    return mergedConfig?.map((column) =>
      columnHelper.accessor(column?.dataIndex || (column?.aggregation ?  `${(column?.name || "").replaceAll(".", "_")}_${column?.aggregation}` : (column?.name || "").replaceAll(".", "_")), {
        header: column?.alias || column?.display_name || t((column?.name || "").replaceAll(/[_\\.]/g, ' ')),
        cell: info => {
          const value = info.getValue();
          if (typeof value === 'object') {
            return renderSettingsResourceLabel(value);
          }
          return value !== null ? value : '';
        },
      })
    );
  }, [mergedConfig, t])
}
