import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import {
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../utils/helpers/settings";
import { useCompany, useCompanyDateFormat, useCompanyTimeFormat } from "../../../utils/hooks/company";
import {
	renderDurationAsFormat,
	dateTimeFormat,
} from "../../../utils/helpers/date";
import Select from "../../../components/Inputs/Select";
import { createColumnHelper } from "@tanstack/react-table";
import { renderDeviceIcon } from "../../../utils/helpers/shift/clock";

function OriginColumn({ value, id }) {
	const { t } = useTranslation();
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const toggle = useCallback(() => {
		setIsTooltipOpen(!isTooltipOpen);
	}, [setIsTooltipOpen, isTooltipOpen]);

	return (
		<div>
			<span className="w-100 text-center" id={`device${id}`}>
				{renderDeviceIcon(value)}
			</span>

			<Tooltip
				target={`device${id}`}
				isOpen={isTooltipOpen}
				toggle={toggle}
			>
				<span className="text-sm font-weight-bolder px-2">
					{t(value)}
				</span>
			</Tooltip>
		</div>
	);
}

const columnHelper = createColumnHelper();

export const useColumns = ({ openDetails, openProfileDrawer }) => {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);
	const company = useCompany();
	const dateFormat = useCompanyDateFormat();
	const timeFormat = useCompanyTimeFormat();

	const useInClockInGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	// console.log(useInClockInGroup, groups);

	return useMemo(() => {
		const cols = [
			columnHelper.accessor("userRes.employeeNumber", {
				enableColumnFilter: true,
				header: t("employee-number"),
				cell: ({ row: { original: { userRes } } }) => (
					<span className="d-flex align-items-center">
						<FontAwesomeIcon
							icon={faUserAlt}
							className="text-muted text-xs mr-2 cursor-pointer"
							onClick={() => openProfileDrawer(userRes)}
						/>
						<span className="mt-1">{userRes?.employeeNumber}</span>
					</span>
				)
			}),
			columnHelper.accessor("userRes.fullName", {
				enableColumnFilter: true,
				header: t("name"),
			}),
			columnHelper.accessor("location.locationId", {
				enableColumnFilter: true,
				header: t("location"),
				cell: (info) => {
					const { location } = info.row.original;
					return location && renderSettingsResourceLabel(location);
				},
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/locations"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
					/>
				),
			}),
			columnHelper.accessor('date', {
				header: t("date"),
				cell: ({ getValue }) => {
					const value = getValue();
					return value && dateTimeFormat(value, dateFormat);
				},
			}),
			columnHelper.accessor("in", {
				header: t("clock-in"),
				cell: ({ getValue }) => {
					const value = getValue();
					if (!value) {
						return "--";
					}
					return (
						<span
							className="clock-in px-2 py-1"
							style={{ width: "50px" }}
						>
							{dateTimeFormat(value, timeFormat)}
						</span>
					);
				},
			}),
			columnHelper.accessor("out", {
				header: t("clock-out"),
				cell: ({ getValue }) => {
					const value = getValue();
					if (!value) {
						return "--";
					}
					return (
						<span
							className="clock-out px-2 py-1"
							style={{ width: "50px" }}
						>
							{dateTimeFormat(value, timeFormat)}
						</span>
					);
				},
			}),
			columnHelper.accessor("totalPaidHours", {
				header: t("paid-hours"),
				cell: ({ getValue }) => {
					return renderDurationAsFormat(getValue(), timeFormat);
				},
			}),
			columnHelper.accessor("breakHours", {
				header: t("unpaid-hours"),
				cell: ({ getValue }) => {
					return renderDurationAsFormat(getValue(), timeFormat);
				},
			}),
			columnHelper.accessor("totalHours", {
				header: t("total-hours"),
				cell: ({ getValue }) => {
					const value = getValue();
					return value && renderDurationAsFormat(value, timeFormat);
				},
			}),
			columnHelper.accessor("type", {
				header: t("type"),
				cell: ({ getValue }) => {
					const value = getValue();
					return value && renderSettingsResourceLabel(value);
				},
			}),
			columnHelper.accessor("origin", {
				enableColumnFilter: true,
				header: t("origin"),
				cell: ({ getValue, row: { id } }) => (
					<OriginColumn value={getValue()} id={id} />
				),
				filterType: "resource-select",
				Filter: (
					<Select showSearch multiple>
						<Select.Option value="web-application">
							{t("web-application")}
						</Select.Option>
						<Select.Option value="phone">
							{t("mobile-application")}
						</Select.Option>
						<Select.Option value="qr-code">
							{t("qr-code")}
						</Select.Option>
						<Select.Option value="phone-call">
							{t("phone-call")}
						</Select.Option>
						<Select.Option value="device">
							{t("physical-clock-option")}
						</Select.Option>
					</Select>
				),
			}),
			columnHelper.accessor("clockInIp", {
				header: t("clock-in-ip"),
			}),
			columnHelper.accessor("clockOutIp", {
				header: t("clock-out-ip"),
			}),
		];

		if (useInClockInGroup) {
			cols.push(
				columnHelper.accessor("project.id", {
					enableColumnFilter: true,
					header: useInClockInGroup.description,
					cell: (info) => {
						const { project } = info.row.original;
						return project && renderOrgLevelResourceLabel(project);
					},
					filterType: "resource-select",
					Filter: (
						<ResourceSelect
							labelPropName="description"
							resourcePath={`${useInClockInGroup.id}/org-levels`}
							mode="multiple"
							renderLabel={renderOrgLevelResourceLabel}
							getSearchFilters={getSettingsOrgResourceSearchFilters}
							valuePropName="id"
							hasSearch
						/>
					),
				})
			);
		}

		cols.push(
			columnHelper.display({
				enableHiding: false,
				id: "details",
				header: t("details"),
				cell: ({ row: { original } }) => {
					return(
						<i
							className="fa-solid fa-eye cursor-pointer"
							onClick={() => openDetails(original)}
						/>
				)},
			})
		);

		return cols;
	}, [
		t,
		useInClockInGroup,
		company,
		openDetails,
		timeFormat,
		openProfileDrawer,
	]);
};
