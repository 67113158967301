import { useEffect, useMemo, useState } from "react";

export const useLayoutState = (initialLayout, userPreferences) => {
  const [layout, setLayout] = useState(initialLayout);
  const [hiddenItems, setHiddenItems] = useState([]);

  const layoutPreferences = useMemo(() => {
    const keys = Object.keys(userPreferences?.dashboardSettings || {});
    let basePreferences = keys.map((key) => ({
      i: key,
      ...userPreferences?.dashboardSettings[key],
      static: userPreferences?.dashboardSettings[key]?.static || false,
    }));

    if (keys?.length) {
      initialLayout?.forEach((item) => {
        if (!userPreferences?.dashboardSettings[item.i]) {
          basePreferences.push(item);
        }
      });
    }

    return basePreferences;
  }, [initialLayout, userPreferences?.dashboardSettings]);

  useEffect(() => {
    if (layoutPreferences?.length > 0) {
      const updatedLayoutPreferences = layoutPreferences.map((item) => ({
        ...item,
        x: item.x === null ? Infinity : item.x,
        y: item.y === null ? Infinity : item.y,
        w: item.w === null ? Infinity : item.w,
        h: item.h === null ? Infinity : item.h,
        static: item.static || false,
        hidden: item.hidden || false,
      }));

      setLayout(updatedLayoutPreferences);
      setHiddenItems(updatedLayoutPreferences.filter((item) => item.hidden));
    }
  }, [layoutPreferences]);

  return { layout, setLayout, hiddenItems, setHiddenItems };
};
