import moment from "moment-timezone";
import { useForm } from "rc-field-form";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useCreateReport, useGetReport, useReports } from "../../api";
import { usePreviewModal } from "../../Preview/usePreviewModal";

const useReportForm = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigate = useNavigate();
  const { id, parentId } = useParams();

  const { createReport } = useCreateReport();
  const { editReport } = useReports();
  const { getReport, report } = useGetReport();
  const { open: openPreviewModal, modal: previewModal } = usePreviewModal();
  const {
    open: openColumnModal,
    visible: isColumnModalOpen,
    close: closeColumnModal,
    selected: selectedType
  } = useVisible();

  const [activeTab, setActiveTab] = useState("columns");
  const [activeSetting, setActiveSetting] = useState("measures");
  const [formData, setFormData] = useState();

  const processFilterValues = useCallback((values) => {
    if (!values) return [];

    const processValue = (value) => {
      if (value == null) return null;

      if (value instanceof Date || moment.isMoment(value)) {
        return moment(value).format("YYYY-MM-DD");
      }

      if (typeof value === "object") {
        if (value?.id) {
          return {
            id: value?.id,
            code: value?.code,
            description: value?.description,
            glSegment: value?.glSegment,
            name: value?.name,
          }
        }
        return null;
      }

      if (typeof value === "string") {
        if (moment(value, moment.ISO_8601, true).isValid()) {
          return moment(value).format("YYYY-MM-DD");
        }
        return value;
      }

      return String(value);
    };

    if (Array.isArray(values)) {
      return values.map(processValue).filter(Boolean);
    }

    if (typeof values === "string" && values.includes(",")) {
      return values
        .split(",")
        .map((s) => s.trim())
        .filter(Boolean);
    }

    const result = processValue(values);
    return result ? [result] : [];
  }, []);

  const handleSettingChange = useCallback(
    (setting) => {
      setFormData((prev) => ({
        ...prev,
        [setting]: form.getFieldValue(setting),
      }));
      setActiveSetting(setting);
    },
    [form]
  );

  const toggleTab = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab]
  );

  const handleValuesChange = useCallback(
    (changedValues, allValues) => {
      setFormData((prevData) => ({
        ...prevData,
        ...allValues,
      }));

      if (changedValues.measures?.length > 0) {
        form.setFieldsValue({ unions: [] });
      }

      if (changedValues.unions?.length > 0) {
        form.setFieldsValue({ measures: [] });
      }
    },
    [form]
  );

  const onFinish = useCallback(
    (values) => {
      const finalValues = { ...values, ...formData };

      const dimensions = (finalValues.dimensions || []).map((dimension) => {
        if (dimension.type === "calculation") {
          return {
            type: "calculation",
            name: dimension.name,
            formula: dimension.formula,
            dataIndex: dimension.dataIndex,
          };
        } else if (dimension.aggregation) {
          return {
            type: "measure",
            name: `${dimension.name}.${dimension.aggregation}`,
            alias: dimension.alias,
          };
        } else {
          return dimension;
        }
      });

      const calculations = finalValues.calculations?.map((calc, i) => ({
        ...calc,
        dataIndex: `calculation_${i}`,
      }));

      const reportPayload = {
        name: finalValues.name,
        type: "report",
        parentId,
        query: {
          order: finalValues.orders || [],
          dimensions: dimensions.flat(),
          measures: (finalValues.measures || []).map((measure) => ({
            name: `${measure.name}.${measure.aggregation}`,
            alias: measure.alias || undefined,
          })),
          calculations,
          union: finalValues.unions || [],
          spread: [],
          filters: (finalValues.filters || []).map((filter, i) => ({
            join: finalValues.filters?.[i - 1]?.join,
            member: filter.name,
            operator: filter.operator,
            values: processFilterValues(filter.values),
          })),
          customFilters: finalValues.customFilters,
          total: true,
          limit: 1200,
          offset: 0,
        },
        chartConfigurations: finalValues.chartConfigurations || [],
      };

      if (id) {
        editReport(id, reportPayload, (response) => {
          navigate(`/reports/view/${response.id}`);
          toast.success(t("report-updated-successfully"));
        });
      } else {
        createReport(reportPayload, (response) => {
          navigate(`/reports/view/${response.id}`);
          toast.success(t("report-created-successfully"));
        });
      }
    },
    [
      formData,
      parentId,
      id,
      editReport,
      navigate,
      t,
      createReport,
      processFilterValues,
    ]
  );

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...formData,
      });
    }
  }, [activeTab, activeSetting, formData, form]);

  useEffect(() => {
    const controller = new AbortController();
    if (id) {
      getReport(id, controller);
    }

    return () => {
      controller.abort();
    };
  }, [getReport, id]);

  useEffect(() => {
    if (report && !formData) {
      const values = {
        name: report.name,
        type: report.type,
        orders: report.reportData?.settings?.sort || [],
        dimensions: report.reportData?.columns.map((col) =>
          col.type === "calculation"
            ? col
            : {
              type: col.name.split(".")[0],
              alias: col.alias,
              name:
                col.type === "measure"
                  ? `${col.name.split(".")[0]}.${col.name.split(".")[1]}`
                  : col.name,
              aggregation:
                col.type === "measure"
                  ? col.aggregation || col.name.split(".")[2]
                  : undefined,
            }
        ),
        measures: report.reportData?.settings?.measures.map((col) => ({
          type: col.name.split(".")[0],
          alias: col.alias || undefined,
          name: `${col.name.split(".")[0]}.${col.name.split(".")[1]}`,
          aggregation: col.name.split(".")[2],
        })),
        unions: report.reportData?.settings?.union,
        filters: report.reportData?.settings?.filters?.map((filter) => ({
          type: filter.member.split(".")[0],
          name: filter.member,
          operator: filter.operator || "",
          values: filter.values || [],
          join: filter.join || "AND",
        })),
        calculations: report.reportData?.columns?.filter((col) => col.dataIndex),
        chartConfigurations: report.reportData?.settings?.chartConfigurations || [],
      };
      form.setFieldsValue(values);
      setFormData(values);
    }
  }, [form, formData, report]);

  return {
    form,
    previewModal,
    isColumnModalOpen,
    openPreviewModal,
    openColumnModal,
    closeColumnModal,
    selectedType,
    activeTab,
    toggleTab,
    activeSetting,
    handleSettingChange,
    onFinish,
    handleValuesChange,
    formData,
    setFormData,
  };
};

export default useReportForm;
