import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DateFiltersProvider } from '../../../components/DateRangeFilters/useDateFilters';
import Loading from '../../../components/Loaders/Loading';
import Pagination from '../../../components/NTable/Pagination';
import { renderUserName } from '../../../utils/helpers/user';
import { useAccess } from '../../../utils/hooks/access';
import { useCompanyDateFormat } from '../../../utils/hooks/company';
import { useReports } from "../api";
import { useGenerateModal } from '../ReportTable/useGenerateModal';
import ReportDesktopMenu from './components/Menu';
import DesktopContextMenu from './desktopContextMenu';
import FolderIcon from './icons/folderIcon';
import ReportIcon from './icons/icon';
import './styles.scss';
import { useCreateFolderModal } from './useCreateFolderModal';
import { useDesktopContextMenu } from './useDesktopContextMenu';
import { useRenameFolderModal } from './useRenameFolderModal';

const ReportDesktop = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { parentId } = useParams();
  const activeFolderId = parentId || null;
  const [folderPath, setFolderPath] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const cardBodyRef = useRef(null);
  const { open, modal } = useGenerateModal();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = cardBodyRef.current?.clientWidth > 800 ? 10 : 100;
  const [infoModal, setInfoModal] = useState({ visible: false, item: null });

  const { hasAccess: canGenerate } = useAccess("reports.canGenerate");

  const dateFormat = useCompanyDateFormat();

  const handleInfoItem = useCallback((item) => {
    setInfoModal({ visible: true, item });
  }, []);

  const {
    getReports,
    createFolder,
    updateReportParent,
    getFolderById,
    deleteFolder,
    deleteReport,
    updateFolderName,
    getItemsByParentId,
    setItemsCache,
    itemsCache,
    data,
    setData,
    totalItems,
    loading,
  } = useReports();

  const handleFolderCreation = useCallback((folderName) => {
    createFolder(
      {
        name: folderName,
        type: 'folder',
        parentId: parentId || null,
        version: 2,
      },
      () => {
        setCurrentPage(0);
        getReports({ parentId, page: 1, perPage: itemsPerPage });
      },
      () => { }
    );
  }, [createFolder, parentId, getReports, itemsPerPage]);

  const { open: openCreateFolderModal, modal: createFolderModal } = useCreateFolderModal({
    onCreate: handleFolderCreation,
  });

  const { hasAccess: canCreate } = useAccess("reports.canCreate");

  const handleDeleteItem = useCallback((item) => {
    if (item.type === 'folder') {
      deleteFolder(item.id, () => {
        getReports({ parentId, page: currentPage + 1, perPage: itemsPerPage });
      }, () => { });
    } else {
      deleteReport(item.id, () => {
        getReports({ parentId, page: currentPage + 1, perPage: itemsPerPage });
      }, () => { });
    }
  }, [deleteFolder, deleteReport, getReports, parentId, currentPage, itemsPerPage]);

  const handleFolderRenaming = useCallback((folderId, newName) => {
    updateFolderName(folderId, newName, () => {
      getReports({ parentId, page: currentPage + 1, perPage: itemsPerPage });
    }, () => { });
  }, [updateFolderName, getReports, parentId, currentPage, itemsPerPage]);

  const { open: openRenameFolderModal, modal: renameFolderModal } = useRenameFolderModal({
    onRename: handleFolderRenaming,
  });

  const handleEditItem = useCallback((item) => {
    if (item.type === 'folder') {
      openRenameFolderModal(item);
    } else {
      navigate(`/reports/edit/${item.id}`);
    }
  }, [openRenameFolderModal, navigate]);

  const handleExportItem = useCallback(
    (item) => {
      open({ ...item, type: 'export' });
    },
    [open]
  );

  const { contextMenuProps, openContextMenu, closeContextMenu } = useDesktopContextMenu({
    onCreateFolder: openCreateFolderModal,
    onEditItem: handleEditItem,
    onDeleteItem: handleDeleteItem,
    onExportItem: handleExportItem,
    onInfoItem: handleInfoItem,
  });

  const handleItemContextMenu = useCallback((event, item) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedItemId(item.id);
    openContextMenu(event, item);
  }, [openContextMenu]);

  const handleDoubleClick = useCallback(
    (item) => {
      if (item.type === 'folder') {
        navigate(`/reports/folder/${item.id}`);
        setCurrentPage(0);
      } else if (item.type === 'report') {
        navigate(`/reports/view/${item.id}`);
      }
    },
    [navigate]
  );

  const handleDragEnd = useCallback(
    async (itemId, targetFolderId) => {
      if (itemId && targetFolderId !== undefined) {
        const currentFolderKey = parentId || 'root';
        const targetFolderKey = targetFolderId || 'root';

        setItemsCache((prev) => {
          const movedItem = data.find((item) => item.id === itemId);
          return {
            ...prev,
            [currentFolderKey]: prev[currentFolderKey]?.filter((item) => item.id !== itemId),
            [targetFolderKey]: movedItem
              ? [movedItem, ...(prev[targetFolderKey] || [])]
              : prev[targetFolderKey],
          };
        });

        updateReportParent(itemId, targetFolderId, async () => {
          const [sourceItems, targetItems] = await Promise.all([
            getReports({ parentId, page: currentPage + 1, perPage: itemsPerPage }),
            getReports({ parentId: targetFolderId, page: 1, perPage: itemsPerPage }),
          ]);
          setItemsCache((prev) => ({
            ...prev,
            [currentFolderKey]: sourceItems,
            [targetFolderKey]: targetItems,
          }));
        });
      }
    },
    [parentId, setItemsCache, updateReportParent, data, getReports, currentPage, itemsPerPage]
  );

  const create = useCallback(() => {
    navigate(parentId ? `/reports/folder/create/${parentId}` : "/reports/create");
  }, [navigate, parentId]);

  const handleBack = useCallback(() => {
    if (folderPath.length > 1) {
      const parentFolder = folderPath[folderPath.length - 2];
      const parentKey = parentFolder.id || 'root';

      if (itemsCache[parentKey]) {
        setData(itemsCache[parentKey]);
      }

      navigate(parentFolder.id ? `/reports/folder/${parentFolder.id}` : '/reports');
      setCurrentPage(0);
    }
  }, [folderPath, itemsCache, navigate, setData]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNavigate = useCallback(
    async (item) => {
      if (item.type === 'folder') {
        const folderKey = item.id || 'root';

        if (!itemsCache[folderKey]) {
          await getReports({ parentId: item.id, page: 1, perPage: itemsPerPage });
        }

        navigate(item.id ? `/reports/folder/${item.id}` : '/reports');
        setCurrentPage(0);
      } else if (item.type === 'report') {
        if (canGenerate) {
          open(item);
        }
      }
    },
    [navigate, getReports, open, itemsCache, itemsPerPage, canGenerate]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cardBodyRef.current &&
        !cardBodyRef.current.contains(event.target)
      ) {
        setSelectedItemId(null);
        closeContextMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [closeContextMenu]);

  useEffect(() => {
    const controller = new AbortController();
    getReports({ parentId, page: currentPage + 1, perPage: itemsPerPage }, controller);

    const fetchPath = async () => {
      const buildPath = async (currentId) => {
        if (!currentId) {
          return [{ id: null, name: t("reports-root") }];
        }
        try {
          const folder = await getFolderById(currentId, controller);
          if (folder) {
            const parentPath = await buildPath(folder.parentId);
            return [...parentPath, { id: folder.id, name: folder.name }];
          } else {
            return [{ id: null, name: t("reports-root") }];
          }
        } catch {
          return [{ id: null, name: t("reports-root") }];
        }
      };

      const path = await buildPath(parentId);
      setFolderPath(path);
    };

    fetchPath();

    return () => controller.abort();
  }, [parentId, getReports, getFolderById, t, currentPage, itemsPerPage]);

  return (
    <DateFiltersProvider>
      <DndProvider backend={HTML5Backend}>
        <Card className="report-desktop">
          <ReportDesktopMenu
            getItemsByParentId={getItemsByParentId}
            itemsCache={itemsCache}
            onNavigate={handleNavigate}
            onDrop={handleDragEnd}
            openContextMenu={openContextMenu}
            openCreateFolderModal={openCreateFolderModal}
            loading={loading}
            activeFolderId={activeFolderId}
            folderPath={folderPath}
          />
          <Card className="main-content">
            <CardHeader className="main-content__header">
              <div className='d-flex align-items-center'>
                {folderPath.length > 1 && (
                  <FontAwesomeIcon className='breadcrumb-container__back-button' icon={faChevronLeft} onClick={handleBack} size='sm' />
                )}
                <div className="breadcrumb-container">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb-container__breadcrumb">
                      {folderPath.map((folder, index) => (
                        <Fragment key={folder.id || 'root'}>
                          {index > 0 && <span className="breadcrumb-container__separator">/</span>}
                          <li
                            className={
                              classNames(
                                "breadcrumb-container__item",
                                index === folderPath.length - 1 && 'breadcrumb-container__item--active'
                              )
                            }
                            aria-current={index === folderPath.length - 1 ? 'page' : undefined}
                          >
                            {index === folderPath.length - 1 ? (
                              folder.name
                            ) : (
                              <a
                                href={folder.id ? `/reports/folder/${folder.id}` : '/reports'}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    folder.id ? `/reports/folder/${folder.id}` : '/reports'
                                  );
                                  setCurrentPage(0);
                                }}
                                className="breadcrumb-container__link"
                              >
                                {folder.name}
                              </a>
                            )}
                          </li>
                        </Fragment>
                      ))}
                    </ol>
                  </nav>
                </div>
              </div>

              <Button
                color="dark"
                onClick={create}
                className="create-button"
                disabled={!canCreate}
              >
                {t("report-create")}
              </Button>
            </CardHeader>

            <CardBody
              ref={cardBodyRef}
              onContextMenu={(e) => {
                e.preventDefault();
                setSelectedItemId(null);
                openContextMenu(e);
              }}
              onClick={() => setSelectedItemId(null)}
              className="main-content__body"
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className="main-content__items">
                    {data?.map(item => {
                      const Component = item?.type === 'folder' ? FolderIcon : ReportIcon;
                      const className = item?.type === 'folder' ? "main-content__folder-icon" : "main-content__report-icon";
                      return (
                        <Component
                          key={item.id}
                          item={item}
                          selected={item.id === selectedItemId}
                          onDoubleClick={() => handleDoubleClick(item)}
                          onDragEnd={handleDragEnd}
                          handleContextMenu={handleItemContextMenu}
                          className={className}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              <DesktopContextMenu
                {...contextMenuProps}
                className="context-menu"
              />

              {createFolderModal}
              {renameFolderModal}
              {modal}
              {infoModal.visible && infoModal.item && (
                <Modal isOpen={infoModal.visible} toggle={() => setInfoModal({ visible: false, item: null })}>
                  <ModalHeader toggle={() => setInfoModal({ visible: false, item: null })}>
                    {t('report-info')}
                  </ModalHeader>
                  <ModalBody>
                    <p><strong>{t('created-at')}:</strong> {moment(infoModal.item.createdAt).format(dateFormat)}</p>
                    <p><strong>{t('created-by')}:</strong> {infoModal.item.user ? renderUserName(infoModal.item.user) : t('unknown')}</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={() => setInfoModal({ visible: false, item: null })}>{t('close')}</Button>
                  </ModalFooter>
                </Modal>
              )}

              {totalPages > 1 && (
                <Pagination
                  page={currentPage}
                  perPage={itemsPerPage}
                  pageCount={totalPages}
                  gotoPage={setCurrentPage}
                  total={totalItems}
                  showOnSizeChange={false}
                  className="pagination"
                />
              )}
            </CardBody>
          </Card>
        </Card>
      </DndProvider>
    </DateFiltersProvider>
  );
};

export default ReportDesktop;
