import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { List, Field as BaseField } from 'rc-field-form';
import Select from '../../../../components/Inputs/Select';
import { useFields } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

const Order = ({ setFormData }) => {
  const { t } = useTranslation();

  const { fields, getFields, loading } = useFields();

  useEffect(() => {
    const controller = new AbortController();
    getFields(controller);
    return () => controller.abort();
  }, [getFields]);

  return loading ? null : (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, getFieldError }) => {
        const unions = getFieldValue('unions') || [];
        const dimensions = getFieldValue('dimensions') || [];

        const types = dimensions?.map(d => d?.type) || [];

        return (
          <List name="orders">
            {(data, { add, remove }) => {
              return (
                <div className="p-3 border rounded">
                  <h5 className="font-weight-bold d-flex align-items-center">
                    {t('order')} {' ('}
                    {data.length}
                    {')'}
                  </h5>
                  <p className="text-muted mb-3">{t('order-description')}</p>

                  {data?.map(({ key, name }) => {
                    const selectedType = getFieldValue(['orders', name, 'type']);
                    const selectedTypeData = fields?.find(f => f.name === selectedType);
                    const selectedFields = selectedTypeData?.fields || [];

                    const names = dimensions?.map((d) => d?.name?.split('.')?.[1]);
                    const options = unions?.length > 0 ? selectedFields?.filter(f => names?.includes(f?.name)) : selectedFields;

                    const [error] = getFieldError(['orders', name, 'name']);

                    return (
                      <div key={key} className="d-flex align-items-center gap-2 mb-2">
                        <BaseField
                          name={[name, 'type']}
                          rules={[
                            {
                              required: true,
                              message: t('required-type'),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t('select-type')}
                            showSearch
                            onChange={() => {
                              setFormData(prev => ({
                                ...prev,
                                orders: prev?.orders?.map((dim, index) => {
                                  if (index === name) {
                                    return {
                                      ...dim,
                                      name: undefined,
                                      sort: undefined
                                    };
                                  }
                                  return dim;
                                })
                              }));
                            }}
                          >
                            {fields
                              ?.filter(fieldOption => types?.includes(fieldOption.table))
                              ?.map(fieldOption => (
                                <Select.Option key={fieldOption.name} value={fieldOption.name}>
                                  {fieldOption.display_name}
                                </Select.Option>
                              ))}
                          </Select>
                        </BaseField>

                        {selectedType && (
                          <BaseField
                            name={[name, 'name']}
                            rules={[
                              {
                                required: true,
                                message: t('required-field'),
                              },
                            ]}
                          >
                            <Select
                              placeholder={t('select-field')}
                              showSearch
                              onChange={() => {
                                setFormData(prev => ({
                                  ...prev,
                                  orders: prev?.orders?.map((dim, index) => {
                                    if (index === name) {
                                      return {
                                        ...dim,
                                        sort: undefined
                                      };
                                    }
                                    return dim;
                                  })
                                }));
                              }}
                            >
                              {options
                                ?.map(fieldOption => (
                                  <Select.Option
                                    key={fieldOption.name}
                                    value={`${selectedType}.${fieldOption.name}`}
                                  >
                                    {fieldOption.display_name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </BaseField>
                        )}

                        <BaseField name={[name, 'sort']}>
                          <Select placeholder={t('select-sort')}>
                            <Select.Option value="asc">{t('ascending')}</Select.Option>
                            <Select.Option value="desc">{t('descending')}</Select.Option>
                          </Select>
                        </BaseField>

                        <Button color="outline-danger" onClick={() => remove(name)}>
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                        <div>{error && <span className="text-danger">{error}</span>}</div>
                      </div>
                    );
                  })}

                  <Button
                    color="outline-info"
                    // className={`d-flex align-items-center gap-2 ${isAddDisabled ? 'disabled' : ''}`}
                    onClick={() => {
                      add();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {t('add-order')}
                  </Button>
                </div>
              );
            }}
          </List>
        );
      }}
    </BaseField>
  );
};

export default Order;
