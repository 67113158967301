import classNames from "classnames";
import { forwardRef, useEffect, useRef } from "react";

function Checkbox(
	{
		id,
		indeterminate,
		className,
		parentClassName = "",
		labelClassName,
		children,
		hidden,
		...props
	},
	ref,
) {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<div className={classNames("custom-control custom-checkbox custom-checkbox-primary", parentClassName)}>
			<input
				ref={resolvedRef}
				className={classNames("custom-control-input", className)}
				type="checkbox"
				id={id}
				{...props}
			/>
			{!hidden && (
				<label
					className={classNames(
						"custom-control-label d-inline-flex",
						labelClassName,
					)}
					htmlFor={id}
				>
					{children}
				</label>
			)}
		</div>
	);
}

export default forwardRef(Checkbox);
