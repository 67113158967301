import { omit } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useLayoutPersistence = (
  layout,
  setLayout,
  hiddenItems,
  submit,
  userPreferences,
  initialLayout,
  setHiddenItems,
  setEditable,
  setResizeHandles,
  staticCards,
  setStaticCards
) => {
  const { t } = useTranslation();

  const onLayoutChange = useCallback(
    (values) => {
      const updatedLayout = values?.map((current) => {
        const existingItem = layout?.find(item => item?.i === current?.i);
        return {
          ...current,
          x: Number(current?.x),
          y: Number(current?.y),
          w: Number(current?.w),
          h: Number(current?.h),
          hidden: existingItem ? existingItem?.hidden : false,
          static: staticCards[current.i] || false,
          chartType: current?.chartType || existingItem?.chartType
        };
      }).filter(item => !item?.hidden);

      const data = [...updatedLayout, ...hiddenItems]?.reduce((total, current) => {
        total = {
          ...total,
          [current.i]: omit(current, ["i"]),
        };
        return total;
      }, {});

      setLayout(updatedLayout);
      toast.success(t("layout-saved"));
      setEditable(false);
      setResizeHandles([])

      submit({
        entity: "user",
        config: {
          ...userPreferences?.dashboardSettings,
          ...data,
        },
      });
    },
    [
      hiddenItems,
      layout,
      setEditable,
      setLayout,
      setResizeHandles,
      staticCards,
      submit,
      t,
      userPreferences?.dashboardSettings
    ]
  );

  const resetLayout = useCallback(() => {
    setLayout(initialLayout);

    const hiddenItemsAfterReset = initialLayout?.filter(item => item?.hidden);

    setHiddenItems(hiddenItemsAfterReset);
    toast.success(t("layout-reset"));
    setEditable(false);
    setResizeHandles([]);

    setStaticCards({});

    const initialConfig = initialLayout.reduce((acc, curr) => {
      acc[curr.i] = omit(curr, ["i"]);
      return acc;
    }, {});

    submit({
      entity: "user",
      config: initialConfig,
    });
  }, [
    initialLayout,
    setEditable,
    setHiddenItems,
    setLayout,
    setResizeHandles,
    setStaticCards,
    submit,
    t
  ]);

  return { onLayoutChange, resetLayout };
};
