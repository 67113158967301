import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import Select from "../../../../../../components/Inputs/Select";
import { firstToUpper } from "../../../../../../utils/helpers/string";
import MeasureSelect from "./MeasureSelect";

const capitalizeWords = (str = '') => {
  return str
    .replaceAll(/[_\\.]/g, ' ')
    .split(' ')
    .map(word => firstToUpper(word))
    .join(' ');
};

const MeasureContent = ({ data, dimensions }) => {
  const { t } = useTranslation();

  if (data?.length > 0) {
    return (
      <Field name="measures">
        <Select
          mode="multiple"
          placeholder={t("select-measure")}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {data?.map((item, index) => (
            <Select.Option key={index} value={`${item.name}.${item.aggregation}`}>
              {capitalizeWords(item.name)}{" "}
              {item.aggregation && t(item.aggregation)}
            </Select.Option>
          ))}
        </Select>
      </Field>
    );
  } else {
    return (
      <MeasureSelect dimensions={dimensions} />
    );
  }
};

export default MeasureContent;
