import Form from "rc-field-form";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Content from "./Content";

const ColumnModal = ({ isOpen, close, selectedType, formData, form, setFormData }) => {
  const { t } = useTranslation();
  const [baseForm] = Form.useForm();

  const baseFormData = Form.useWatch(undefined, form);

  const baseDimension = useMemo(() => {
    return formData?.dimensions || baseFormData?.dimensions || [];
  }, [formData?.dimensions, baseFormData?.dimensions]);

  const onClose = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      measures: baseForm?.getFieldValue("measures") || prev?.measures || baseFormData?.measures,
      calculations: baseForm?.getFieldValue("calculations") || prev?.calculations || baseFormData?.calculations,
    }));
    baseForm.resetFields();
    close();
  }, [setFormData, baseForm, close, baseFormData]);

  const data = useMemo(() => {
    return selectedType === "calculations" ? formData?.calculations || baseFormData?.calculations : formData?.measures || baseFormData?.measures;
  }, [selectedType, formData, baseFormData]);

  const title = useMemo(() => {
    return selectedType === "calculations" ? t("calculations") : t("measures");
  }, [selectedType, t]);

  const measureSubmit = useCallback(
    (values) => {
      const measureNames = data?.length > 0
        ? values?.measures?.map((measure) => `${measure?.name?.split(".")[0]}.${measure?.name?.split(".")[1]}`)
        : values?.measures?.map((measure) => measure?.name);

      const dimensions = baseDimension?.filter((dimension) => !measureNames?.includes(dimension?.name));

      setFormData((prev) => {
        const measures = data?.length > 0
          ? values?.measures?.map((measure) => ({
            type: measure?.split(".")[0],
            name: `${measure?.split(".")[0]}.${measure?.split(".")[1]}`,
            aggregation: measure?.split(".")[2],
          }))
          : values?.measures;

        const existingDimensionNames = prev?.dimensions?.map((d) => d?.name) || [];
        const existingMeasures = prev?.measures?.map((measure) => measure?.name) || [];
        const newMeasures = measures?.filter((measure) => !existingDimensionNames?.includes(measure?.name) || !existingMeasures?.includes(measure?.name));

        const updatedDimensions = [...dimensions, ...newMeasures];

        const updatedData = {
          ...prev,
          dimensions: updatedDimensions,
          measures: data?.length > 0 || !prev?.measures
            ? prev?.measures
            : values?.measures,
        };

        if (data?.length === 0 || !data?.length) {
          updatedData.measures = values?.measures;
        }

        return updatedData;
      });

      baseForm.resetFields();
      close();
    },
    [data?.length, baseDimension, setFormData, baseForm, close]
  );

  const calculationSubmit = useCallback(
    (values) => {
      const calculationNames = data?.length > 0
        ? values?.calculations?.map((i) => `calculation_${i}`)
        : values?.calculations?.map((calc) => calc?.dataIndex);

      const dimensions = baseDimension?.filter((dimension) => !calculationNames?.includes(dimension?.name));

      setFormData((prev) => {
        const formCalculations = prev?.calculations;

        const calculations = data?.length > 0
          ? formCalculations?.filter((calc) => !calculationNames?.includes(calc?.dataIndex))?.map((calc) => ({
            type: "calculation",
            name: calc?.name,
            formula: calc?.formula,
            dataIndex: calc?.dataIndex,
          }))
          : values?.calculations?.map((calc, i) => ({
            type: "calculation",
            name: calc?.name,
            formula: calc?.formula,
            dataIndex: `calculation_${i}`,
          }));

        const existingDimensionNames = prev?.dimensions?.map((d) => d?.name) || [];
        const existingCalculations = prev?.calculations?.map((calc) => calc?.name) || [];
        const newCalculations = calculations?.filter((calc) => !existingDimensionNames?.includes(calc?.name) || !existingCalculations?.includes(calc?.name));

        const updatedDimensions = [...dimensions, ...newCalculations];

        const updatedData = {
          ...prev,
          dimensions: updatedDimensions,
          calculations: data?.length > 0 || !prev?.calculations
            ? prev?.calculations
            : values?.calculations,
        };

        if (data?.length === 0 || !data?.length) {
          updatedData.calculations = values?.calculations;
        }

        return updatedData;
      });

      baseForm.resetFields();
      close();
    },
    [data?.length, baseDimension, setFormData, baseForm, close]
  );

  const onFinish = useCallback(
    (values) => {
      if (selectedType === "measures") {
        measureSubmit(values);
      } else if (selectedType === "calculations") {
        calculationSubmit(values);
      }
    },
    [measureSubmit, calculationSubmit, selectedType]
  );

  return (
    <Modal title={title} isOpen={isOpen} toggle={onClose} centered size="xl">
      <Form onFinish={onFinish}>
        <Content
          selectedType={selectedType}
          data={data}
          dimensions={formData?.dimensions || baseFormData?.dimensions}
          setFormData={setFormData}
        />

        <div className="modal-footer w-100 text-right">
          <Button type="button" color="muted" onClick={onClose}>
            {t("cancel")}
          </Button>

          <Button type="submit" color="primary">
            {t("save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ColumnModal;
