const { memo, useState, useEffect } = require("react");
const { useDrop } = require("react-dnd");
const { ItemTypes } = require("../../constants");
const { faFolderBlank, faFile, faChevronDown, faChevronRight } = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");

const FolderTreeItem = memo(({
  item,
  getItemsByParentId,
  itemsCache,
  onNavigate,
  onDrop,
  level = 0,
  openContextMenu,
  activeFolderId,
  activeFolderIds
}) => {
  const isFolder = item?.type === 'folder';
  const [isExpanded, setIsExpanded] = useState(false);
  const isActive = item?.id === activeFolderId;
  const isInActivePath = activeFolderIds?.includes(item?.id);

  useEffect(() => {
    if (isFolder && isActive) {
      setIsExpanded(true);
    }
  }, [isFolder, isActive]);

  useEffect(() => {
    if (isFolder && isInActivePath) {
      setIsExpanded(true);
    }
  }, [isFolder, isInActivePath]);

  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.REPORT, ItemTypes.FOLDER],
    canDrop: () => isFolder,
    drop: (draggedItem) => {
      if (draggedItem.id !== item.id && isFolder) {
        onDrop(draggedItem.id, item.id);
      }
    },
    collect: (monitor) => ({
      isOver: isFolder ? monitor.isOver() : false,
    }),
  });

  useEffect(() => {
    if (isFolder && isExpanded && !itemsCache[item.id]) {
      const controller = new AbortController();
      getItemsByParentId(item.id, controller);
      return () => controller.abort();
    }
  }, [isFolder, isExpanded, item.id, getItemsByParentId, itemsCache]);

  const children = itemsCache[item.id] || [];

  const toggleExpand = () => {
    if (isFolder) {
      setIsExpanded((prev) => !prev);
    }
  };

  const icon = isFolder ? faFolderBlank : faFile;

  return (
    <li
      ref={isFolder ? drop : null}
      className={`folder-item ${isActive ? 'folder-item--active' : ''}`}
    >
      <div
        className={`folder-item__row ${isOver ? 'folder-item__row--hovered' : ''}`}
        style={{ paddingLeft: `${level * 1}rem` }}
      >
        <div
          className="folder-item__header"
          onDoubleClick={() => onNavigate(item)}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openContextMenu(e, item);
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            className={`folder-item__icon ${isFolder ? 'folder-icon' : 'report-icon'}`}
            size="lg"
          />
          <span className="folder-item__name">{item.name}</span>
        </div>
        {isFolder && (
          <div
            className="folder-item__toggle-container"
            onClick={(e) => {
              e.stopPropagation();
              toggleExpand();
            }}
          >
            <FontAwesomeIcon
              icon={isExpanded ? faChevronDown : faChevronRight}
              className="folder-item__toggle"
            />
          </div>
        )}
      </div>
      {isFolder && isExpanded && (
        <ul className="folder-item__children">
          {children.map((childItem) => (
            <FolderTreeItem
              key={childItem.id}
              item={childItem}
              getItemsByParentId={getItemsByParentId}
              itemsCache={itemsCache}
              onNavigate={onNavigate}
              onDrop={onDrop}
              openContextMenu={openContextMenu}
              level={level + 1}
            />
          ))}
        </ul>
      )}
    </li>
  );
});

export default FolderTreeItem;
