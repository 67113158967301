import FormElement from "rc-field-form";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useAuthentication } from "../../../../Authentication/context";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { OrgLevelGroupsContext } from "../../../General/OrganisationLevelGroups/context";
import { months } from "./Content";
import { weekdays } from "../../../../../components/Inputs/WeekdaySelect";

const getFormattedWeeksValue = (values, week) => {
    return {
        amount: values?.[week]?.amount || 0,
        hours: values?.[week]?.hours || 0,
    };
};

const getFormattedMonthValue = (values, month) => {
    return {
        amount: values?.months[month]?.amount || 0,
        hours: values?.months[month]?.hours || 0,
    };
};

function getWeeksInYear(year, startDayOfWeek) {
    const weeks = [];
    let startDate = moment().year(year).startOf('year');
    const startOfYear = startDate.clone();

    // Adjust to the first occurrence of the desired start day
    while (startDate.weekday() !== startDayOfWeek) {
        startDate.add(1, 'day');
    }
    if (startOfYear.format('YYYY-MM-DD') !== startDate.format('YYYY-MM-DD')) {
        weeks.push({
            start: startOfYear.format('YYYY-MM-DD'),
            end: startDate.clone().subtract(1, 'day').format('YYYY-MM-DD'),
        });
    }

    // Iterate through the year, adding weeks
    while (startDate.year() === year) {
        const weekStart = startDate.clone();
        const weekEnd = weekStart.clone().add(6, 'days'); // Week is 7 days
        weeks.push({
            start: weekStart.format('YYYY-MM-DD'),
            end: weekEnd.year() === year ? weekEnd.format('YYYY-MM-DD') : weekStart.endOf('year').format('YYYY-MM-DD'),
        });
        startDate.add(7, 'days'); // Move to the next week
    }

    return weeks;
}

function Form({ mode, values, error, loading, submit, close }) {

    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const isMasterCompany = useIsMasterCompany();
    const [weekDates, setWeekDates] = useState([]);
    const [budgetYear, setBudgetYear] = useState(Number(`${values?.year || moment().year()}`));
    const [startOfWeek, setStartOfWeek] = useState(values?.startOfWeek || "sunday");
    const [currentDow, setCurrentDow] = useState(
        weekdays.indexOf(values?.startOfWeek || "sunday") !== -1 ?
            weekdays.indexOf(values?.startOfWeek || "sunday") : 0
    );

    const { access } = useModuleAccess("settings.payPolicies.budget");
    const disabled = useIsFieldDisabled({ ...access, mode });
    
    const { company, user } = useAuthentication();

    const { groups } = useContext(OrgLevelGroupsContext);

    const group = useMemo(() => groups?.find((group) => group.hasBudget), [groups]);

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const onFinish = useCallback(
        (formValues) => {
            const year = moment(formValues.year).format("YYYY");
            const type = formValues?.budgetType;
            const data = {
                company: formValues?.company?.id,
                location: formValues?.location?.id,
                payGroup: formValues?.payGroup?.id,
                level: formValues?.level?.id,
                orgLevelGroupId: group?.id,
                year,
                startOfWeek: formValues?.startOfWeek,
                type: formValues?.budgetType,
                weekDays: formValues?.days.map((day) => day),
                status: "active",
            };

            if (type === "weekly") {
                const _startOfWeek = formValues?.startOfWeek;
                const dow = weekdays.indexOf(_startOfWeek);
                const weeksOfYear = getWeeksInYear(Number(year), dow);

                const formattedWeeks = weeksOfYear.reduce((total, _, i) => {
                    const week = `week_${i + 1}`;

                    return {
                        ...total,
                        [week]: getFormattedWeeksValue(formValues, week),
                    };
                }, {});
                data.weeks = formattedWeeks;
            } else {
                data.months = months.reduce((total, month) => {
                    return {
                        ...total,
                        [month]: getFormattedMonthValue(formValues, month),
                    };
                }, {});
            }
            submit(data);
        },
        [submit, group]
    );

    const onValuesChange = useCallback((changedValues) => {
        if(changedValues.startOfWeek && changedValues.startOfWeek !== startOfWeek) {
            setStartOfWeek(changedValues.startOfWeek);
        }
        if (changedValues.year) {
            const selectedYear = moment(changedValues.year).year();
            setBudgetYear(selectedYear);
        }
    }, [setStartOfWeek, startOfWeek, budgetYear]);


	const calendarStartDay = useMemo(() => {
		const startDay = user?.calculationGroup?.startDay
			? user?.calculationGroup?.startDay
			: company?.settings?.startDay
			   ? company?.settings?.startDay
			   : "sunday";

		return startDay
    }, [company?.settings?.startDay, user?.calculationGroup?.startDay]);
    useEffect(() => {
        if (!values?.startOfWeek) {
            setStartOfWeek(calendarStartDay);
            setCurrentDow(weekdays.indexOf(calendarStartDay));
        } else {
            setStartOfWeek(values?.startOfWeek);
            setCurrentDow(weekdays.indexOf(values?.startOfWeek));

        }
    }, [calendarStartDay, values]);

    useEffect(() => {
        if (startOfWeek && budgetYear && currentDow !== null) {
            const dow = weekdays.indexOf(startOfWeek?.toLowerCase());
            if (dow !== currentDow) {
                setCurrentDow(dow);
            }
            const weeks = getWeeksInYear(budgetYear, dow);
            setWeekDates(weeks);
            const formattedWeeks = weeks.reduce((total, _, i) => {
                const week = `week_${i + 1}`;

                return {
                    ...total,
                    [week]: getFormattedWeeksValue({ [week]: form.getFieldValue(week) }, week),
                };
            }, {});
            form.setFieldsValue({
                ...formattedWeeks,
            });
        }
    }, [startOfWeek, budgetYear, currentDow, form]);

    useEffect(() => {
        const _startOfWeek = values?.startOfWeek || startOfWeek?.toLowerCase() ||  "sunday";

        const dow = weekdays.indexOf(_startOfWeek);
        const year = Number(`${values?.year || moment().year()}`); 
        const weeks = getWeeksInYear(year, dow);
        setWeekDates(weeks);
        const formattedWeeks = weeks.reduce((total, _, i) => {
            const week = `week_${i + 1}`;

            return {
                ...total,
                [week]: getFormattedWeeksValue(values?.weeks, week),
            };
        }, {});

        form.resetFields();
        form.setFieldsValue({
            year: mode === "edit" ? moment().year(Number(values?.year)).toDate() : moment().toDate(),
            budgetType: values?.type || "weekly",
            payGroup: values?.payGroup,
            company: values?.company,
            days: values?.weekDays || weekdays,
            level: values?.level ? values?.level : undefined,
            location: values?.location ? values.location : undefined,
            startOfWeek,
            ...formattedWeeks,
            ...months.reduce((total, month) => {
                return {
                    ...total,
                    [month]: getFormattedMonthValue(values, month),
                };
            }, {}),
        });
    }, [form, values, startOfWeek, company?.settings?.currency, mode]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(["location", "level", "year"], error);
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <FormElement form={form} className="d-flex flex-column justify-content-between h-100" onValuesChange={onValuesChange} onFinish={onFinish}>
            <div
                className="px-4 py-3"
                style={{
                    height: "calc(100% - 55px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <Content mode={mode} disabled={disabled || !isMasterCompany} weekDates={weekDates} />
            </div>

            <div className="d-flex justify-content-end p-3">
                <Button
                    onClick={onClose}
                    disabled={loading}
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                >
                    {t("cancel")}
                </Button>

                {(!disabled || isMasterCompany) && (
                    <Button type="submit" className="btn-dark btn-sm shadow-none" loading={loading}>
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
}

export default Form;
