import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field as BaseField, List } from "rc-field-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import Select from "../../../../../../components/Inputs/Select";
import Text from "../../../../../../components/Inputs/Text";
import { useFields } from "../../../../api";

const MeasureSelect = ({ dimensions }) => {
  const { t } = useTranslation();

  const {
    fields,
    getFields,
    loading,
  } = useFields();

  useEffect(() => {
    const controller = new AbortController();
    getFields(controller);
    return () => controller.abort();
  }, [getFields]);

  return loading ? null : (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, setFieldsValue, getFieldError }
      ) => {
        const selectedDimensionTypes = (dimensions || [])
          .map(dim => dim?.type)
          .filter(Boolean);

        const allowedFieldTypes = new Set(selectedDimensionTypes);

        return (
          <List name="measures">
            {(data, { add, remove }) => (
              <div className="p-3 border rounded">
                <h5 className="font-weight-bold">{t("measures")} {" ("}{data.length}{")"}</h5>
                <p className="text-muted mb-3">{t("measures-description")}</p>

                {data?.map(({ key, name }) => {
                  const [nameError, aggregationError] = getFieldError([
                    ["measures", name, "name"],
                    ["measures", name, "aggregation"],
                  ]).map(({ errors }) => errors).flat();

                  const selectedType = getFieldValue(["measures", name, "type"]);
                  const selectedTypeData = fields.find(f => f.table === selectedType);
                  const selectedFields = selectedTypeData?.fields?.filter(field => field.type === "INTEGER") || [];

                  return (
                    <div key={key} className="mb-3">
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <BaseField
                          name={[name, "type"]}
                          rules={[{
                            required: true,
                            message: t("required-type"),
                          }]}
                        >
                          <Select
                            placeholder={t("select-field-type")}
                            showSearch
                            onChange={(value) => {
                              setFieldsValue({
                                measures: {
                                  ...getFieldValue("measures"),
                                  [name]: {
                                    type: value,
                                    name: undefined,
                                    aggregation: undefined,
                                    alias: getFieldValue(["measures", name, "alias"]),
                                  },
                                },
                              });
                            }}
                          >
                            {fields
                              .filter(fieldOption => allowedFieldTypes.has(fieldOption.table))
                              .map((fieldOption) => (
                                <Select.Option
                                  key={fieldOption.table}
                                  value={fieldOption.table}
                                >
                                  {fieldOption.display_name}
                                </Select.Option>
                              ))}
                          </Select>
                        </BaseField>

                        {selectedType && (
                          <BaseField
                            name={[name, "name"]}
                            rules={[{
                              required: true,
                              message: t("required-measure"),
                            }]}
                          >
                            <Select
                              placeholder={t("select-measure")}
                              showSearch
                              onChange={(value) => {
                                setFieldsValue({
                                  measures: {
                                    ...getFieldValue("measures"),
                                    [name]: {
                                      ...getFieldValue(["measures", name]),
                                      name: value,
                                    },
                                  },
                                });
                              }}
                            >
                              {selectedFields.map((fieldOption) => (
                                <Select.Option
                                  key={fieldOption.name}
                                  value={`${selectedType}.${fieldOption.name}`}
                                >
                                  {fieldOption.display_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </BaseField>
                        )}

                        <BaseField
                          name={[name, "aggregation"]}
                          rules={[{
                            required: true,
                            message: t("required-type"),
                          }]}
                        >
                          <Select placeholder={t("select-type")} showSearch>
                            <Select.Option value="sum">{t("sum")}</Select.Option>
                            <Select.Option value="avg">{t("average")}</Select.Option>
                            <Select.Option value="count">{t("count")}</Select.Option>
                            <Select.Option value="max">{t("max")}</Select.Option>
                            <Select.Option value="min">{t("min")}</Select.Option>
                          </Select>
                        </BaseField>

                        <BaseField name={[name, "alias"]}>
                          <Text placeholder={t("optional-display-name")} />
                        </BaseField>

                        <Button
                          color="outline-danger"
                          onClick={() => remove(name)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                      </div>

                      <div>
                        {(nameError || aggregationError) && (
                          <span className="text-danger">
                            {nameError || aggregationError}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}

                <Button
                  color="outline-info"
                  className="d-flex align-items-center gap-2 mt-3"
                  onClick={() => add()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {t("add-measure")}
                </Button>
              </div>
            )}
          </List>
        );
      }}
    </BaseField>
  );
};

export default MeasureSelect;
