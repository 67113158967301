import { DateFiltersProvider } from "../../../../components/DateRangeFilters/useDateFilters";
import Content from "./Content";

const ReportWidget = ({ reportId, chartType }) => {
  return (
    <DateFiltersProvider>
      <Content reportId={reportId} chartType={chartType} />
    </DateFiltersProvider>
  );
};

export default ReportWidget;
