import { useEffect, useMemo } from "react";
import { CardHeader } from "reactstrap";
import Loading from "../../../../components/Loaders/Loading";
import ChartView from "../../../ReportV2/Preview/ChartView";
import { useGetReport } from "../../../ReportV2/api";

const Content = ({ reportId, chartType }) => {
  const { getReport, report, loading } = useGetReport();

  useEffect(() => {
    const controller = new AbortController();
    getReport(reportId, controller);
    return () => controller.abort();
  }, [getReport, reportId]);

  const values = useMemo(
    () => ({
      ...report?.reportData?.settings,
      chartConfigurations: report?.reportData?.settings?.chartConfigurations?.filter(
        (item) => item.chartType === chartType
      ),
    }),
    [chartType, report?.reportData?.settings]
  );

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <CardHeader className="font-weight-bold">{report?.name}</CardHeader>
      {loading ? <Loading /> : <ChartView values={values} />}
    </div>
  );
};

export default Content;
