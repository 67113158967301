import { createContext, useEffect, useState, useMemo } from 'react';
import { io } from 'socket.io-client';
import { useAuthentication } from '../../../Authentication/context';
import { useSubDomain } from '../../../../utils/hooks/useSubDomain';
import { getChatUrl } from '../../../../utils/helpers/url';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const { token, user } = useAuthentication();
    const [socket, setSocket] = useState(null);
    const subDomain = useSubDomain();
    const baseURL = useMemo(() => getChatUrl(subDomain), [subDomain]);

    useEffect(() => {
        if (!user?.mainCompany) return;

        const socketConnection = io(
            `${baseURL}/${user?.mainCompany}`,
            {
                auth: {
                    token,
                },
            }
        );

        socketConnection.on("connect_error", (err) => {
            console.error("Connection error:", err.message);
        });

        socketConnection.on('connect', () => {
            console.log('Socket connected');
            setSocket(socketConnection);
        });

        socketConnection.on('disconnect', () => {
            console.log('Socket disconnected');
        });

        return () => {
            socketConnection.disconnect();
        };
    }, [token, user, baseURL, user?.mainCompany]);

    useEffect(() => {
        if (socket) {
            socket.on('ping', (message) => console.log('Ping:', message));
        }

        return () => {
            if (socket) {
                socket.off('ping');
            }
        };
    }, [socket]);

    return (
        <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
    );
};