import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import Select from "../../../../../../components/Inputs/Select";
import CalculationSelect from "./CalculationSelect";

const CalculationContent = ({ data, dimensions }) => {
  const { t } = useTranslation();

  return data?.length > 0 ? (
    <Field name="calculations">
      <Select
        mode="multiple"
        placeholder={t("select-calculation")}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {data.map((item, index) => (
          <Select.Option key={index} value={item?.dataIndex}>
            {item?.name}
          </Select.Option>
        ))}
      </Select>
    </Field>
  ) : (
    <CalculationSelect dimensions={dimensions} />
  );
};

export default CalculationContent;
