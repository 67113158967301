import { useCallback, useEffect, useMemo } from "react";
import { Card, CardHeader } from "reactstrap";
import {
	TableContext,
	useTableContext,
} from "../../../../../components/Table/context";
import Table from "../../../components/TimeSheetTable";
import Actions from "./Actions";
import AddTimesheetNote from "../../../components/TimeSheetTable/AddTimesheetNote";
import CopyTimesheetModal from "../../Actions/CopyTimesheetModal";
import { useAccess } from "../../../../../utils/hooks/access";

import { useFetchUserTimeSheets } from "../hooks/useFetchUserTimeSheets";

import { useDateIdMap } from "../../../components/TimeSheetTable/hooks/useDateIdMap";
import { useTimeSheetTable } from "../../../components/TimeSheetTable/hooks/useTable";

import { useDateActions } from "./hooks/useDateActions";
import { useEdit } from "./hooks/useEdit";
import { useRemoveActions } from "./hooks/useRemoveActions";
import { useBatchUpdate } from "./hooks/useBatchUpdate";

import { useColumns } from "../../../components/TimeSheetTable/useColumns";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { useDateTimesheetMap } from "../../../components/TimeSheetTable/hooks/useDateTimesheetMap";
import { useDateFilters } from "../../DateAndPayGroupFilters/useDateFilters";
import { useFilters } from "../../../filtersContext";

import { useConfigurationModule } from "../../../../Settings/Configuration/hooks/useConfigurationModule";
import { useConfigColumns } from "../../../../Settings/Configuration/hooks/useConfigColumns";
import { useSyncColumnVisibilityWithConfig } from "../../../../Settings/Configuration/hooks/useSyncColumnVisibilityWithConfig";
import { usePayGroupItems } from "../../../components/TimeSheetTable/hooks/usePayGroupItems";
import { useSortTimeSheets } from "../../../components/TimeSheetTable/hooks/useSortTimeSheets";

function TimeSheetTable({ user, state, openHistory, openDaySummary }) {
	const hideDailySummary = useConfigurationModule(
		"timesheetManagerView.timeSheet.hideDailySummary"
	);

	const [filters] = useFilters();
	const {
		setRowState,
		updateUser,
		refetchOverview,
	} = useTableContext();
	const { data: baseData, loading } = state;
    const data = useSortTimeSheets(baseData);
	const { from, to } = useDateFilters();

	const setState = useMemo(() => {
		return setRowState.bind(null, user?.id);
	}, [user, setRowState]);

	const {
		mutate: refetchTimeSheets,
		scheduledHours: scheduleInfo,
		mutateScheduledHours,
		clockExceptions,
		mutateClockExceptions
	} = useFetchUserTimeSheets({
		params: { from: state?.date, to: state?.date, filters },
		user: user.id,
		setState
	});

	const actionsRefetch = useCallback(
		(value) => {
			const { result } = value;
			refetchOverview();
			updateUser(result[0]?.user);
		},
		[updateUser, refetchOverview],
	);

	const { approve, unapprove, lock, unlock } = useDateActions(
		actionsRefetch,
		filters,
		user?.id,
	);

	const { modal: removeModal, remove } = useRemoveActions({
		refetch: actionsRefetch,
		setState,
		filters,
		userId: user?.id
	});

	const {
		visible: editVisible,
		open: openEdit,
		close: closeEdit,
		selected: editSelected,
		loading: editLoading,
		error: editError,
		submit: editSubmit,
	} = useEdit({ refetch: actionsRefetch, filters });

	const viewSummary = useCallback(
		(date) => {
			openDaySummary(user, date);
		},
		[user, openDaySummary],
	);

	const {
		visible: copyModalVisible,
		selected: selectedInfo,
		open: openCopyModal,
		close: closeCopyModal,
	} = useVisible();

	const { hasAccess: canCreate } = useAccess("timeSheet.canCreate");
	const { hasAccess: canEdit } = useAccess("timeSheet.canEdit");
	const { hasAccess: canViewOriginalClocks } = useAccess(
		"timeSheet.canViewOriginalClocks",
	);
	const { hasAccess: canViewHistory } = useAccess("timeSheet.canViewHistory");

	const dateIdMap = useDateIdMap({ data });
	const dateTimesheetMap = useDateTimesheetMap({ data });
	const columns = useColumns({
		user,
		canCreate,
		canEdit,
		dateIdMap,
		dateTimesheetMap,
		approve,
		unapprove,
		lock,
		unlock,
		viewSummary: !hideDailySummary && viewSummary,
		edit: editSubmit ? openEdit : null,
		view: canViewHistory || canViewOriginalClocks ? openHistory : undefined,
		remove,
		copy: openCopyModal,
		scheduleInfo,
		clockExceptions
	});

	const batchUpdateRefetch = useCallback(
		(user) => {
			updateUser(user);
			return Promise.all([
				refetchOverview(),
				refetchTimeSheets(),
				mutateScheduledHours(),
				mutateClockExceptions()
			]);
		},
		[updateUser, refetchOverview, refetchTimeSheets, mutateScheduledHours, mutateClockExceptions],
	);

	const { submit: batchUpdateSubmit, loading: batchUpdateLoading } =
		useBatchUpdate({
			user,
			refetch: batchUpdateRefetch,
			filters,
			from,
			to,
		});

	const payGroupItems = usePayGroupItems(user);
	const table = useTimeSheetTable({
		user,
		columns: useConfigColumns("timesheetManagerView.timeSheet", columns),
		data,
		dateIdMap,
		batchUpdate: batchUpdateSubmit,
		submitting: batchUpdateLoading,
		payGroupItems,
	});

	useSyncColumnVisibilityWithConfig({
		table,
		configuration: "timesheetManagerView.timeSheet",
		userPreferences: "timesheetManagerViewUserTimeSheet",
	});

	useEffect(() => {
		setState((prev) => ({
			...prev,
			hasChanges: table.hasChanges,
		}));
	}, [table.hasChanges, setState])

	return (
		<TableContext.Provider value={table}>
			<Card className="mb-auto position-sticky">
				<CardHeader className="border-top p-3">
					<Actions
						user={user}
						filters={filters}
						approve={approve}
						unapprove={unapprove}
						lock={lock}
						unlock={unlock}
						remove={remove}
					/>
				</CardHeader>
			</Card>

			<Table loading={loading} />

			{editSubmit && (
				<AddTimesheetNote
					visible={editVisible}
					close={closeEdit}
					onFinish={editSubmit}
					error={editError}
					submitting={editLoading || loading}
					values={editSelected}
				/>
			)}

			{copyModalVisible && (
				<CopyTimesheetModal
					user={selectedInfo[0]?.user}
					isOpen={copyModalVisible}
					close={closeCopyModal}
					timesheet={selectedInfo}
					refetch={batchUpdateRefetch}
					filters={filters}
				/>
			)}

			{removeModal}
		</TableContext.Provider>
	);
}

export default TimeSheetTable;
