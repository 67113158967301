import { Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import moment from "moment-timezone";
import { AuthenticationProvider } from "./features/Authentication/context";
import AppLoader from "./components/Loaders/AppLoader";
import Routing from "./Routing";
import packageJson from "../package.json";
import { compareVer } from "./utils/helpers/compareVer";
import { defaults } from "react-sweet-state";
import i18n from "./utils/i18n";
import getEnTranslate from "./utils/helpers/moment/getEnTranslate";
import getSqTranslate from "./utils/helpers/moment/getSqTranslate";
import getEsTranslate from "./utils/helpers/moment/getEsTranslate";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { SWRConfig } from "./utils/SWRConfig";
import { EarningGroupsProvider } from "./features/TimeSheet/earningGroupsContext";
import { OrgLevelGroupProvider } from "./features/Settings/General/OrganisationLevelGroups/context";
import { CustomFieldsProvider } from "./features/TimeSheet/hooks/useCustomFields";
import { FirebaseProvider } from "./features/Notifications/firebaseContext";
import { SocketProvider } from "./features/Communication/Chat/contexts/SocketContext";
import { ChatProvider } from "./features/Communication/Chat/contexts/ChatContext";
defaults.devtools = true;

// const appVersion = packageJson.version;

function App() {
	// const refreshCacheAndReload = () => {
	// 	if (caches) {
	// 		caches.keys().then((names) => {
	// 			for (const name of names) {
	// 				caches.delete(name);
	// 			}
	// 		});
	// 	}
	// 	window.location.reload();
	// };
	// useEffect(() => {
	// 	fetch('/meta.json')
	// 		.then((res) => res.json())
	// 		.then((meta) => {
	// 			const latest = meta.version;
	// 			const current = appVersion;
	// 			const shouldClear = compareVer(latest, current);
	// 			if (shouldClear) {
	// 				refreshCacheAndReload();
	// 			}
	// 		});
	// }, []);
	useEffect(() => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/service-worker.js')
				.then((registration) => {
					console.log('[SW] Registered:', registration);

					// Listen for messages from the service worker
					navigator.serviceWorker.addEventListener('message', (event) => {
						if (event.data && event.data.type === 'NEW_VERSION_AVAILABLE') {
							console.log('[SW] New version detected. Reloading...');
							// You can customize this behavior if needed; for now, we simply reload the page.
							window.location.reload();
						}
					});
				})
				.catch((error) => {
					console.error('[SW] Registration failed:', error);
				});
		}
	}, []);

	useEffect(() => {
		if (i18n.language === 'sq') {
			moment.updateLocale("sq", getSqTranslate());
		} else if (i18n.language === "es") {
			moment.updateLocale("es", getEsTranslate());
		} else {
			moment.updateLocale("en", getEnTranslate());
		}
	}, [i18n.language])

	return (
		<Suspense fallback={<AppLoader />}>
			<FirebaseProvider>
				<AuthenticationProvider>
					<SWRConfig>
						<OrgLevelGroupProvider>
							<EarningGroupsProvider>
								<CustomFieldsProvider>
									<SocketProvider>
										<ChatProvider>
											<Routing />
										</ChatProvider>
									</SocketProvider>
								</CustomFieldsProvider>
							</EarningGroupsProvider>
						</OrgLevelGroupProvider>
					</SWRConfig>

					<ToastContainer
						position="top-center"
						hideProgressBar
						autoClose={2500}
						pauseOnHover
						draggable
					/>
				</AuthenticationProvider>
			</FirebaseProvider>

			<div id="datepicker-portal" />
		</Suspense>
	);
}

export default App;
