import { Card, Table as BaseTable } from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useTableContext } from "../../../../components/Table/context";
import Pagination from "../../../../components/Table/Pagination";
import Loading from "../../../../components/Loaders/Loading";
import TimeSheetTable from "./TimeSheetTable";
import "./_style.scss";

function Table({ loading, openDaySummary, openAllSummary }) {
	const { t } = useTranslation();

	const {
		getTableProps,
		headerGroups,
		footerGroups,
		getTableBodyProps,
		page,
		prepareRow,
		pageCount,
		state: { pageIndex, pageSize },
		gotoPage,
		setPageSize,
		totalItems,
	} = useTableContext();

	return (
		<Card className="team-table mb-0 position-static h-100">
			<BaseTable
				className="align-items-center table-flush"
				hover
				responsive
				{...getTableProps()}
			>
				<thead className="thead-light" style={{ zIndex: 5 }}>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								const props = column.id === "selection"
									? {}
									: column.getSortByToggleProps();
								return (
									<th
										{...column.getHeaderProps({
											...props,
											...column.headCustomProps,
										})}
										style={{ color: "#000000b5" }}>
										{column.render("Header")}

										{column.id !== "selection" &&
											column.canSort && (
												<i
													className={classNames(
														"ml-1 fa-solid",
														column.isSorted
															? column.isSortedDesc
																? "fa-sort-down"
																: "fa-sort-up"
															: "fa-sort",
													)}
												/>
											)}
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{loading ? (
						<tr>
							<td colSpan="999">
								<div className="d-flex justify-content-center">
									<Loading />
								</div>
							</td>
						</tr>
					) : page.length === 0 ? (
						<tr>
							<td colSpan="999">
								<div className="d-flex justify-content-center">
									{t("no-data")}
								</div>
							</td>
						</tr>
					) : (
						page.map((row) => {
							prepareRow(row);
							return (
								<>
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											const props = cell.column.getCellCustomProps?.(cell.row.original) || {};
											if (props.rowSpan === 0) {
												return null;
											}
											return (
												<td {...cell.getCellProps(props)}>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>

									{row.isExpanded && (
										<tr>
											<td className="timesheet-table-container" colSpan={1000}>
												<TimeSheetTable
													user={row.original}
													state={row.state}
													openDaySummary={openDaySummary}
													openAllSummary={openAllSummary}
												/>
											</td>
										</tr>
									)}
								</>
							);
						})
					)}
				</tbody>

				<tfoot className="bg-info text-white font-weight-bolder">
					{footerGroups.map(group => (
						<tr {...group.getFooterGroupProps()}>
							{group.headers.map(column => (
								<td {...column.getFooterProps()}>{column.render('Footer')}</td>
							))}
						</tr>
					))}
				</tfoot>
			</BaseTable>

			<Pagination
				page={pageIndex}
				gotoPage={gotoPage}
				pageCount={pageCount}
				perPage={pageSize}
				setPerPage={setPageSize}
				totalItems={totalItems}
			/>
		</Card>
	);
}

export default Table;
