import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../../components/Loaders/Loading';
import FolderTreeItem from './FolderTreeItem';

const ReportDesktopMenu = ({
  getItemsByParentId,
  itemsCache,
  onNavigate,
  onDrop,
  openContextMenu,
  openCreateFolderModal,
  loading,
  activeFolderId,
  folderPath
}) => {
  const { t } = useTranslation();
  const rootItems = (itemsCache['root'] || []).filter(item => item.type === 'folder');

  const activeFolderIds = folderPath.map(folder => folder.id).filter(id => id);

  useEffect(() => {
    if (!itemsCache['root']) {
      const controller = new AbortController();
      getItemsByParentId(null, controller);
      return () => controller.abort();
    }
  }, [getItemsByParentId, itemsCache]);

  return (
    <div className="menu">
      <div className="menu__title text-info">
        {t("folders")}
        <FontAwesomeIcon icon={faPlus} onClick={openCreateFolderModal} className="menu__add-folder-icon" />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <ul className="menu__list">
          {rootItems.map((item) => (
            <FolderTreeItem
              key={item.id}
              item={item}
              getItemsByParentId={getItemsByParentId}
              itemsCache={itemsCache}
              onNavigate={onNavigate}
              onDrop={onDrop}
              openContextMenu={openContextMenu}
              loading={loading}
              activeFolderId={activeFolderId}
              activeFolderIds={activeFolderIds}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default ReportDesktopMenu;
