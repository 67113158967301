import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import classNames from "classnames";
import { CardHeader, CardBody, Row, Col } from "reactstrap";
import InformationRow from "../InformationRow";
import SecondaryJob from "./SecondaryJob";
import { ProfileContext } from "../context";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";
import { getPrimaryActiveResource } from "../../../utils/helpers/user";
import { useCompany } from "../../../utils/hooks/company";
import { useJob } from "../../../utils/api/jobs";
import {
	renderPaymentType,
} from "../../../utils/helpers/job";
import { currencyFormatter } from "../../../utils/helpers/currencyFormatter";
import { dateTimeFormat } from "../../../utils/helpers/date";
import { Access } from "../../../utils/hooks/access";

function JobPay({ isOpenFromTimesheet = false }) {
	const { t } = useTranslation();
	const { user } = useContext(ProfileContext);
	const company = useCompany();

	const userJob = useMemo(
		() => getPrimaryActiveResource(user?.jobs || []),
		[user],
	);

	const { data: job, loading: loadingJob } = useJob(userJob?.jobId);

	const userOrganisationLevels = useMemo(
		() => [user?.level1, user?.level2, user?.level3, user?.level4],
		[user],
	);

	return (
		<>
			<CardHeader
				className={classNames(
					isOpenFromTimesheet && "profile-card-header",
				)}
			>
				<Row>
					<Col xs="6" className="">
						<h3 className="mb-0 text-uppercase">{t("job-and-pay")}</h3>
					</Col>
				</Row>
			</CardHeader>

			<CardBody
				style={{ overflowY: "auto" }}
				className="profile-scroll"
			>
				<h6 className="heading-small text-muted mb-4 remove-mb">
					{t("job-information")}
				</h6>

				<div className="pl-lg-4 remove-mb">
					<InformationRow
						title={t("job-group")}
						value={
							user?.jobGroup
								? renderSettingsResourceLabel(user?.jobGroup)
								: "--"
						}
					/>

					<InformationRow
						title={t("job-code")}
						value={userJob?.code ? userJob.code : "--"}
					/>

					<InformationRow
						title={t("job-title")}
						value={
							userJob
								? renderSettingsResourceLabel(userJob)
								: "--"
						}
					/>

					<InformationRow
						title={t("date-in-job")}
						value={
							userJob?.startDate
								? dateTimeFormat(
									userJob.startDate,
									company?.settings.dateFormat,
								)
								: "--"
						}
					/>

					<InformationRow
						title={t("time-in-job")}
						value={
							userJob?.startDate
								? moment
									.duration(
										moment().diff(userJob?.startDate),
									)
									.humanize()
								: "--"
						}
					/>

					<InformationRow
						title={t("employee-type")}
						value={
							user?.employeeType
								? renderSettingsResourceLabel(user.employeeType)
								: "--"
						}
					/>

					<InformationRow
						title={t("user-groups")}
						value={
							user?.userGroups?.length > 0 ? (
								user?.userGroups?.map(
									(userGroup) => renderSettingsResourceLabel(userGroup)
								).join(", ")
							) : (
								"--"
							)
						}
					/>

					<InformationRow
						title={t("groupings")}
						value={
							user?.groupings?.length > 0 ? (
								user?.groupings?.map(renderSettingsResourceLabel).join(", ")
							) : (
								"--"
							)
						}
					/>

					<InformationRow
						title={t("union")}
						value={
							user?.union
								? renderSettingsResourceLabel(user.union)
								: "--"
						}
					/>

					<InformationRow
						title={t("project")}
						value={
							user?.project
								? renderSettingsResourceLabel(user.project)
								: "--"
						}
					/>

					<InformationRow
						title={t("skills")}
						value={
							loadingJob ? (
								<div
									className="spinner spinner-border spinner-border-sm mr-2"
									role="status"
								/>
							) : job?.skills?.length > 0 ? (
								job?.skills?.join(", ")
							) : (
								"--"
							)
						}
					/>

					<InformationRow
						title={t("certification")}
						value={
							loadingJob ? (
								<div
									className="spinner spinner-border spinner-border-sm mr-2"
									role="status"
								/>
							) : job?.licenseCertificates?.length > 0 ? (
								job?.licenseCertificates?.join(", ")
							) : (
								"--"
							)
						}
					/>

					<InformationRow
						title={t("pay-group")}
						value={
							user?.payGroup
								? renderSettingsResourceLabel(user?.payGroup)
								: "--"
						}
					/>

					<InformationRow
						title={t("pay-frequency")}
						value={
							user?.payGroup?.frequency
								? t(user.payGroup.frequency)
								: "--"
						}
					/>
				</div>

				<hr className="my-4" />

				<Access accessPath="people.showHourlyRate">

					<h6 className="heading-small text-muted mb-4">
						{t("compensation")}
					</h6>

					<div className="pl-lg-4 remove-mb">
						<InformationRow
							title={t("hourly-or-salary")}
							value={t(renderPaymentType(userJob?.hourlyOrSalaried))}
						/>

						<InformationRow
							title={t("pay-currency")}
							value={t(userJob?.currency)}
						/>

						{userJob?.hourlyOrSalaried === "H" ? ( <>
								<InformationRow
									title={t("rate-of-pay")}
									value={
										userJob?.hourlyRate
											? currencyFormatter(
												userJob.hourlyRate,
												2,
												userJob.currency,
											)
											: "--"
									}
							/>
							<InformationRow
								title={t("other-rate1")}
								value={
									userJob?.otherRate1
										? currencyFormatter(
											userJob.otherRate1,
											2,
											userJob.currency,
										)
										: "--"
								}
							/>
							<InformationRow
								title={t("other-rate2")}
								value={
									userJob?.otherRate2
										? currencyFormatter(
											userJob.otherRate2,
											2,
											userJob.currency,
										)
										: "--"
								}
							/>
							<InformationRow
								title={t("other-rate3")}
								value={
									userJob?.otherRate3
										? currencyFormatter(
											userJob.otherRate3,
											2,
											userJob.currency,
										)
										: "--"
								}
							/>
							<InformationRow
								title={t("other-rate4")}
								value={
									userJob?.otherRate4
										? currencyFormatter(
											userJob.otherRate4,
											2,
											userJob.currency,
										)
										: "--"
								}
							/>
							</>
						) : (
							<InformationRow
								title={t("salary")}
								value={
									userJob?.salary
										? currencyFormatter(
											userJob.salary,
											2,
											userJob.currency,
										)
										: "--"
								}
							/>
						)}
					</div>

					<hr className="my-4" />
				</Access>

				<h6 className="heading-small text-muted mb-4">
					{t("organization")}
				</h6>

				<div className="pl-lg-4 remove-mb">
					{user?.orgLevelGroups?.filter((group) => group?.status !== "deleted")?.map(({ description }, i) => (
						<InformationRow
							title={description}
							value={
								userOrganisationLevels[i]
									? renderSettingsResourceLabel(
										userOrganisationLevels[i],
									)
									: "--"
							}
						/>
					))}
				</div>
				<hr className="my-4" />

				<SecondaryJob />
			</CardBody>
		</>
	);
}

export default JobPay;
