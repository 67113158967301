import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field as BaseField, List } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import Select from "../../../../components/Inputs/Select";
import { firstToUpper } from "../../../../utils/helpers/string";
import { Input } from "reactstrap";

const ChartConfiguration = () => {
  const { t } = useTranslation();

  return (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, getFieldsError }) => {
        const dimensions = getFieldValue("dimensions") || [];
        const measures = getFieldValue("measures") || [];
        const calculations = getFieldValue("calculations") || [];

        const dimensionColumns = dimensions.map((dimension) => ({
          label:
            dimension?.alias ||
            firstToUpper(dimension?.name?.replace(/[_\\.]/g, " ")),
          value: dimension?.name,
          dataType: dimension?.dataType || "STRING",
        }));

        const measureColumns = measures.map((measure) => ({
          label:
            measure?.alias ||
            `${firstToUpper(measure?.name?.replace(/[_\\.]/g, " "))} ${measure?.aggregation}`,
          value: `${measure?.name}.${measure?.aggregation}`,
          dataType: measure?.isCurrency ? "MONEY" : "NUMBER",
        }));

        const calculationColumns = calculations.map((calc, i) => ({
          label: calc?.name || `calculation_${i}`,
          value: `calculation_${i}`,
          dataType: "NUMBER",
        }));

        const availableColumns = [
          ...dimensionColumns,
          ...measureColumns,
          ...calculationColumns,
        ];

        return (
          <List name="chartConfigurations">
            {(charts, { add, remove }) => (
              <div className="p-3 border rounded">
                <h5 className="font-weight-bold">
                  {t("chart-configurations")} ({charts.length})
                </h5>
                <p className="text-muted mb-3">
                  {t("chart-configuration-description")}
                </p>

                {charts.map(({ key, name, ...restField }, index) => {
                  const chartConfigs = getFieldValue("chartConfigurations") || [];
                  const currentChart = chartConfigs[index] || {};
                  const selectedY = currentChart.y || "";

                  const xOptions = availableColumns.filter(
                    (column) => column.value !== selectedY
                  );

                  const [chartTypeError] = getFieldsError([
                    ["chartConfigurations", name, "chartType"],
                  ])
                    .map(({ errors }) => errors)
                    .flat();

                  const [xError] = getFieldsError([
                    ["chartConfigurations", name, "x"],
                  ])
                    .map(({ errors }) => errors)
                    .flat();

                  const [yError] = getFieldsError([
                    ["chartConfigurations", name, "y"],
                  ])
                    .map(({ errors }) => errors)
                    .flat();

                  return (
                    <div key={key} className="mb-4">
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <BaseField
                          {...restField}
                          name={[name, "chartType"]}
                          rules={[{ required: true, message: t("required") }]}
                        >
                          <Select placeholder={t("select-chart-type")} showSearch>
                            <Select.Option value="bar">
                              {t("bar-chart")}
                            </Select.Option>
                            <Select.Option value="line">
                              {t("line-chart")}
                            </Select.Option>
                            <Select.Option value="area">
                              {t("area-chart")}
                            </Select.Option>
                            <Select.Option value="pie">
                              {t("pie-chart")}
                            </Select.Option>
                          </Select>
                        </BaseField>

                        <BaseField
                          {...restField}
                          name={[name, "x"]}
                          rules={[{ required: true, message: t("required") }]}
                        >
                          <Select
                            placeholder={t("select-x")}
                            mode="multiple"
                            showSearch
                          >
                            {xOptions.map((column) => (
                              <Select.Option
                                key={column.value}
                                value={column.value}
                                dataType={column.dataType}
                              >
                                {column.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </BaseField>

                        <BaseField shouldUpdate>
                          {(_, __, { getFieldValue }) => {
                            return (
                              <BaseField
                                {...restField}
                                name={[name, "y"]}
                                rules={[{ required: true, message: t("required") }]}
                              >
                                <Select
                                  placeholder={t("select-y")}
                                  showSearch
                                >
                                  {measureColumns.map((column) => (
                                    <Select.Option
                                      key={column.value}
                                      value={column.value}
                                      dataType={column.dataType}
                                    >
                                      {column.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </BaseField>
                            );
                          }}
                        </BaseField>

                        <BaseField
                          {...restField}
                          name={[name, "color"]}
                          initialValue="#8884d8"
                          rules={[{ required: true, message: t("required") }]}
                        >
                          {({ value, onChange }) => (
                            <div className="w-25">
                              <Input
                                type="color"
                                value={value || "#8884d8"}
                                onChange={(e) => onChange(e.target.value)}
                                className="color-input"
                              />
                              <div
                                className="color-preview"
                                style={{ backgroundColor: value || "#8884d8" }}
                              />
                            </div>
                          )}
                        </BaseField>

                        <Button
                          color="outline-danger"
                          onClick={() => remove(name)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                      </div>

                      {chartTypeError && (
                        <div>
                          <span className="text-danger">{chartTypeError}</span>
                        </div>
                      )}
                      {xError && (
                        <div>
                          <span className="text-danger">{xError}</span>
                        </div>
                      )}
                      {yError && (
                        <div>
                          <span className="text-danger">{yError}</span>
                        </div>
                      )}
                    </div>
                  );
                })}

                <Button color="outline-info" onClick={() => add()}>
                  <FontAwesomeIcon icon={faPlus} /> {t("add-chart")}
                </Button>
              </div>
            )}
          </List>
        );
      }}
    </BaseField>
  );
};

export default ChartConfiguration;
