import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { CardHeader } from "reactstrap";
import { useAccess } from "../../utils/hooks/access";
import { useSearchParams } from "react-router-dom";

const config = {
    pto: [],
    clock: ["all", "shift", "edit-shift"],
    schedule: [
        "all",
        "schedule",
        "edit-schedule",
        "submit-schedule",
        "swap-shifts",
        "offer-shifts",
    ],
    availability: ["all", "recurring", "temporary"]
};


function Topbar({
    query,
    namespace,
    timeoff = false,
    filterType,
    setFilterType,
    setSearchParams,
    setHasChanges
}) {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { hasAccess } = useAccess("canManageUsers");
    const requestType = query.get("requestType");
    const mode = useMemo(() => {
        if (hasAccess) {
            return "team";
        } else {
            return "my";
        }
    }, [hasAccess]);

    const btnText = useMemo(() => {
        switch (namespace) {
            case "clock": {
                return t("my-clock-request")
            }
            case "pto": {
                return t("my-pto-request")
            }
            case "schedule": {
                return t("my-schedule-request")
            }
            case "availability": {
                return t("availability")
            }
            default:
                return ""
        }
    }, [namespace, t]);

    const teamBtnText = useMemo(() => {
        switch (namespace) {
            case "clock": {
                return t("team-clock-request")
            }
            case "pto": {
                return t("team-pto-request")
            }
            case "schedule": {
                return t("team-schedule-request")
            }
            case "availability": {
                return t("team-availability")
            }
            default:
                return ""
        }
    }, [namespace, t]);

    const onRequestTypeChange = useCallback((value) => {
        setFilterType("all");
        setSearchParams((prev) => {
            prev.set("requestType", value);
            return prev;
        });
    }, [setSearchParams, setFilterType]);

    const onFilterTypeChange = useCallback((value) => {
        setFilterType(value);
        setHasChanges(true);
    }, [setFilterType, setHasChanges]);

    useEffect(() => {
        const id = searchParams.get("id");
        if (!requestType && !id) {
            onRequestTypeChange(mode);
        }
    }, [mode, requestType, searchParams, onRequestTypeChange]);

    return !timeoff && (
        <CardHeader className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center gap-2 my-2">
                <span
                    color="muted"
                    className={classNames(
                        (requestType || mode) === "my"
                            ? "bg-primary text-white"
                            : "text-primary",
                        "py-1 px-4 text-sm rounded border border-primary cursor-pointer"
                    )}
                    onClick={() => onRequestTypeChange("my")}
                >
                    {btnText}
                </span>

                <span
                    color="muted"
                    className={classNames(
                        (requestType || mode) === "team"
                            ? "bg-primary text-white"
                            : "text-primary",
                        "py-1 px-4 text-sm rounded border-primary border cursor-pointer"
                    )}
                    onClick={() => onRequestTypeChange("team")}
                >
                    {teamBtnText}
                </span>
            </div>

            <div>
                {config?.[namespace]?.map((item) => (
                    <span
                        className={classNames(
                            filterType === item && "bg-primary text-white",
                            "px-4 py-1 rounded text-sm cursor-pointer"
                        )}
                        onClick={() => {
                            onFilterTypeChange(item);
                        }}
                    >
                        {t(item)}
                    </span>
                ))}
            </div>
        </CardHeader>
    );
}

export default Topbar;
