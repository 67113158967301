import { cloneElement, isValidElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import Text from "../../../Inputs/Text";
import Field from "../../../Field";
import Select from "../../../Inputs/Select";
import classNames from "classnames";

export const getDefaultFilterValue = (type) => {
	switch (type) {
		case "text":
			return "";
		case "resource-select":
			return [];
		case "select":
			return null;
		default:
			throw new Error(`Type '${type}' not recognized`);
	}
}

export const getFieldMethod = (type) => {
	switch (type) {
		case "text":
			return "contains";
		case "resource-select":
		case "select":
			return "exact";
		default:
			return "exact";
	}
};

function Filter({
	changeFieldMethod,
	changeFieldValue,
	config,
	name,
	add,
	remove,
	form
}) {
	const { t } = useTranslation();
	const list = Form.useWatch("filters");
	const current = useMemo(() => list?.[name], [name, list]);

	const { type, disabled } = useMemo(() => {
		return config?.find((configItem) => configItem.name === current?.name) || {};
	}, [config, current?.name]);

	const fields = useMemo(() => {
		return config
			?.filter(({ name }) => {
				return (
					!(list?.findIndex((item) => item.name === name) !== -1) ||
					current?.name === name
				);
			})
			.map(({ name, label }) => ({ name, label }));
	}, [config, list, current]);

	const input = useMemo(() => {
		const itemConfig = config?.find(
			(configItem) => configItem.name === current?.name,
		) || {};
		const { component, disabled } = itemConfig;
		if (component) {
			if (!isValidElement(component)) {
				return component;
			}
			if (component.props.disabled !== undefined) {
				return component;
			}
			return cloneElement(
				component,
				{ disabled: disabled },
			);
		}
		return (
			<Text
				className="form-control-sm px-4"
				type="text"
				placeholder={t("value")}
				disabled={disabled}
			/>
		);
	}, [config, current?.name, t]);

	const onFieldChange = useCallback(
		(fieldName) => {
			const type = config?.find(
				(configItem) => configItem.name === fieldName,
			)?.type;
			const method = getFieldMethod(type);
			form.setFieldValue(["filters", name, "value"], "")
			changeFieldMethod(name, method);
			if (type !== "text") {
				changeFieldValue(name, getDefaultFilterValue(type));
			}
		},
		[config, name, changeFieldMethod, changeFieldValue, form],
	);

	useEffect(() => {
		const method = getFieldMethod(type);
		changeFieldMethod(name, method);
	}, [name, type, changeFieldMethod]);

	return (
		<div className="d-flex justify-content-end align-items-center">
			<div style={{ width: 210 }}>
				<Field
					name={[name, "name"]}
					className="mb-0 mr-2"
				>
					<Select
						placeholder={t("field")}
						onChange={onFieldChange}
						disabled={disabled}
						showSearch
					>
						{fields.map(({ name, label }) => (
							<Select.Option key={name} value={name}>
								{t(label) || t(name)}
							</Select.Option>
						))}
					</Select>
				</Field>
			</div>

			<BaseField name={[name, "method"]} hidden />

			<div style={{ width: 210 }}>
				<Field name={[name, "value"]} className="mb-0 mr-2 w-100">
					{input}
				</Field>
			</div>

			{(
				list?.length < config.length &&
				list?.length < 3
			) && (
					<span className="mr-1" style={{ height: 25, width: 25 }}>
						<i
							className="ni ni-fat-add text-info"
							style={{ fontSize: "25px", cursor: "pointer" }}
							onClick={add}
						/>
					</span>
				)}

			{list?.length > 1 && (
				<span style={{ height: 25, width: 25 }}>
					<i
						className={classNames("ni ni-fat-delete", disabled ? "text-gray" : "text-info")}
						style={{ fontSize: "25px", cursor: disabled ? "initial" : "pointer" }}
						onClick={disabled ? undefined : remove}
					/>
				</span>
			)}
		</div>
	);
}

export default Filter;
