import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field as BaseField, List } from 'rc-field-form';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import Field from '../../../../components/Field';
import Text from '../../../../components/Inputs/Text';
import { useFields } from '../../api';

const isOperator = (char) => ['+', '-', '*', '/'].includes(char);

const tokenize = (formula) => {
  const tokens = [];
  let currentToken = '';
  for (let i = 0; i < formula.length; i++) {
    const char = formula[i];
    if (char === ' ') {
      continue;
    }
    if (isOperator(char) || char === '(' || char === ')') {
      if (currentToken) {
        tokens.push(currentToken);
        currentToken = '';
      }
      tokens.push(char);
    } else {
      currentToken += char;
    }
  }
  if (currentToken) {
    tokens.push(currentToken);
  }
  return tokens;
};

const Calculation = ({ setFormData }) => {
  const { t } = useTranslation();
  const { fields, getFields, loading } = useFields();
  const inputRefs = useRef({});

  const [suggestions, setSuggestions] = useState({});
  const [results, setResults] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    getFields(controller);
    return () => controller.abort();
  }, [getFields]);

  if (loading) return null;

  return (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, setFieldsValue }) => {
        const dimensions = getFieldValue('dimensions') || [];

        const types = dimensions?.map((d) => d?.type) || [];

        const integerFields = fields
          .filter((dim) => types?.includes(dim.table))
          .flatMap((dim) =>
            dim.fields
              .filter((f) => f.type === 'INTEGER')
              .map((f) => ({
                dimensionName: dim.name,
                dimensionDisplayName: dim.display_name || dim.name,
                name: f.name,
                displayName: f.display_name || f.name,
                fullName: `${dim.name}.${f.name}`,
              }))
          );

        const validFieldNames = integerFields.map((field) => field.fullName);

        const isNumber = (str) => {
          return /^\d+(\.\d+)?$/.test(str);
        };

        const validateFormula = (_, value) => {
          const validCharacters = /^([a-zA-Z0-9_.+\-*/()\s])+$/;
          if (!validCharacters.test(value)) {
            return Promise.reject(new Error(t('invalid-formula')));
          }

          let balance = 0;
          for (let char of value) {
            if (char === '(') balance++;
            if (char === ')') balance--;
            if (balance < 0) break;
          }
          if (balance !== 0) {
            return Promise.reject(new Error(t('unbalanced-parentheses')));
          }

          const endsWithOperator = /[+\-*/]$/.test(value.trim());
          if (endsWithOperator) {
            return Promise.reject(new Error(t('formula-ends-with-operator')));
          }

          const tokens = tokenize(value);
          if (tokens.length === 0) {
            return Promise.reject(new Error(t('empty-formula')));
          }

          let expectOperand = true;
          let hasDimension = false;
          for (let i = 0; i < tokens.length; i++) {
            const token = tokens[i];
            if (token === '(') {
              if (!expectOperand) {
                return Promise.reject(
                  new Error(t('unexpected-open-parenthesis'))
                );
              }
              expectOperand = true;
            } else if (token === ')') {
              if (expectOperand) {
                return Promise.reject(
                  new Error(t('unexpected-close-parenthesis'))
                );
              }
              expectOperand = false;
            } else if (isOperator(token)) {
              if (expectOperand) {
                return Promise.reject(new Error(t('unexpected-operator')));
              }
              expectOperand = true;
            } else {
              if (!validFieldNames.includes(token) && !isNumber(token)) {
                return Promise.reject(
                  new Error(`${t('invalid-variable')}: ${token}`)
                );
              }
              if (validFieldNames.includes(token)) {
                hasDimension = true;
              }
              if (!expectOperand) {
                return Promise.reject(new Error(t('missing-operator')));
              }
              expectOperand = false;
            }
          }

          if (expectOperand) {
            return Promise.reject(
              new Error(t('formula-cannot-end-with-operator'))
            );
          }

          if (!hasDimension) {
            return Promise.reject(new Error(t('must-contain-dimension')));
          }

          if (/\(\)/.test(value)) {
            return Promise.reject(new Error(t('empty-parentheses')));
          }

          return Promise.resolve();
        };

        const handleFormulaChange = (value, key) => {
          const cursorPosition = inputRefs.current[key]?.selectionStart || 0;
          const textUpToCursor = value.slice(0, cursorPosition);
          const regex = /([a-zA-Z0-9_.]+)$/;
          const lastWordMatch = regex.exec(textUpToCursor);
          const lastWord = lastWordMatch ? lastWordMatch[1] : '';

          if (lastWord.length > 0) {
            const matchedFields = integerFields.filter((field) =>
              field.fullName.toLowerCase().startsWith(lastWord.toLowerCase())
            );

            const groupedSuggestions = matchedFields.reduce((acc, field) => {
              const { dimensionDisplayName } = field;
              if (!acc[dimensionDisplayName]) {
                acc[dimensionDisplayName] = [];
              }
              acc[dimensionDisplayName].push(field);
              return acc;
            }, {});

            const suggestionsArray = Object.keys(groupedSuggestions).map(
              (dimName) => ({
                dimensionDisplayName: dimName,
                fields: groupedSuggestions[dimName],
              })
            );

            setSuggestions((prev) => ({
              ...prev,
              [key]: suggestionsArray,
            }));
          } else {
            setSuggestions((prev) => ({
              ...prev,
              [key]: [],
            }));
          }

          if (value.trim() === '') {
            setResults((prev) => ({
              ...prev,
              [key]: '',
            }));
          } else {
            const operations = (value.match(/[+\-*/]/g) || []).length;
            setResults((prev) => ({
              ...prev,
              [key]: `${t('operations-count')}: ${operations}`,
            }));
          }
        };

        const insertDimension = (dimension, key) => {
          const input = inputRefs.current[key];
          if (input) {
            const cursorPosition = input.selectionStart || 0;
            const currentValue = getFieldValue(['calculations', key, 'formula']) || '';
            const textUpToCursor = currentValue.slice(0, cursorPosition);
            const regex = /([a-zA-Z0-9_.]+)$/;
            const lastWordMatch = regex.exec(textUpToCursor);
            const startPos = lastWordMatch ? lastWordMatch.index : cursorPosition;
            const newValue =
              currentValue.substring(0, startPos) +
              dimension.fullName +
              currentValue.substring(cursorPosition);

            setFieldsValue({
              calculations: {
                [key]: {
                  formula: newValue,
                },
              },
            });

            setTimeout(() => {
              if (input) {
                input.focus();
                const cursorPos = startPos + dimension.fullName.length;
                input.setSelectionRange(cursorPos, cursorPos);
              }
            }, 0);

            setSuggestions((prev) => ({
              ...prev,
              [key]: [],
            }));
          }
        };

        return (
          <List name="calculations">
            {(calcs, { add, remove }) => (
              <div className="p-3 border rounded">
                <h5 className="font-weight-bold">
                  {t('calculations')} ({calcs.length})
                </h5>
                <p className="text-muted mb-3">{t('calculations-description')}</p>
                {calcs.map(({ key, name }) => (
                  <div key={key} className="mb-4 p-3 border rounded">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <span className="font-weight-bold text-info">
                        {t('calculation')} #{parseInt(name) + 1}
                      </span>
                      <Button
                        color="outline-danger"
                        onClick={() => {
                          remove(name);
                          setFormData((prev) => ({
                            ...prev,
                            dimensions: prev?.dimensions?.filter((dimension) => dimension?.dataIndex !== `calculation_${name}`),
                          }));
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </Button>
                    </div>
                    <Field
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: t('required-calculation-name'),
                        },
                      ]}
                      label={t('name')}
                    >
                      <Text placeholder={t('enter-calculation-name')} />
                    </Field>

                    <BaseField name={[name, 'dataIndex']} initialValue={`calculation_${name}`} />

                    <Field
                      name={[name, 'formula']}
                      rules={[
                        {
                          required: true,
                          message: t('required-formula'),
                        },
                        {
                          validator: validateFormula,
                        },
                      ]}
                      label={t('formula')}
                    >
                      <Text
                        id={`calcExpr${name}`}
                        placeholder={t('enter-calculation-formula')}
                        innerRef={(el) => (inputRefs.current[key] = el)}
                        onChange={(e) =>
                          handleFormulaChange(e.target.value, key)
                        }
                        onBlur={() =>
                          setSuggestions((prev) => ({
                            ...prev,
                            [key]: [],
                          }))
                        }
                        autoComplete="off"
                      />
                    </Field>

                    {suggestions[key] && suggestions[key].length > 0 && (
                      <div className="border mt-1 rounded bg-white">
                        {suggestions[key].map((category, i) => (
                          <div key={i}>
                            <div className="p-2 font-weight-bold bg-light">
                              {category.dimensionDisplayName}
                            </div>
                            {category.fields.map((field, j) => (
                              <div
                                key={j}
                                className="p-2 pl-4 cursor-pointer"
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                  insertDimension(field, key);
                                }}
                              >
                                {field.displayName}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}

                    {results[key] && (
                      <div className="mb-2">
                        <span className="text-muted">{results[key]}</span>
                      </div>
                    )}

                    <Button
                      color="link"
                      size="sm"
                      className="mt-2 text-info"
                      onClick={() => {
                        const groupedSuggestions = integerFields.reduce(
                          (acc, field) => {
                            const { dimensionDisplayName } = field;
                            if (!acc[dimensionDisplayName]) {
                              acc[dimensionDisplayName] = [];
                            }
                            acc[dimensionDisplayName].push(field);
                            return acc;
                          },
                          {}
                        );

                        const suggestionsArray = Object.keys(groupedSuggestions).map(
                          (dimName) => ({
                            dimensionDisplayName: dimName,
                            fields: groupedSuggestions[dimName],
                          })
                        );

                        setSuggestions((prev) => ({
                          ...prev,
                          [key]: suggestionsArray,
                        }));
                      }}
                    >
                      {t('show-dimensions')}
                    </Button>

                    <div className="mt-2">
                      <span className="text-muted">
                        {t('calculation-instructions')}
                        <code>{t('calculation-example')}</code>
                      </span>
                    </div>
                  </div>
                ))}

                <Button
                  color="outline-info"
                  onClick={() => {
                    add({ name: '', formula: '' });
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {t('add-calculation')}
                </Button>
              </div>
            )}
          </List>
        );
      }}
    </BaseField>
  );
};

export default Calculation;
