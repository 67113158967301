import { useCallback } from "react";

export const useCardActions = (setLayout, setHiddenItems) => {
  const handleHideCard = useCallback(
    (key) => {
      setLayout((prevLayout) => {
        const updatedLayout = prevLayout.map((item) =>
          item.i === key ? { ...item, hidden: true } : item
        );

        const hiddenItem = updatedLayout.find((item) => item.i === key);
        if (hiddenItem) {
          setHiddenItems((prevHiddenItems) => {
            const prevItems = prevHiddenItems.filter((item) => item.i !== hiddenItem.i);
            return [...(prevItems || []), hiddenItem];
          });
        }

        const visibleLayout = updatedLayout.filter((item) => !item.hidden);

        return visibleLayout;
      });
    },
    [setHiddenItems, setLayout]
  );

  const handleAddCard = useCallback(
    (item) => {
      setHiddenItems((prevHiddenItems) => prevHiddenItems.filter((i) => i.i !== item.i));

      setLayout((prevLayout) => {
        const updatedLayout = [...prevLayout, { ...item, hidden: false }];
        return updatedLayout;
      });
    },
    [setHiddenItems, setLayout]
  );

  return { handleHideCard, handleAddCard };
};
