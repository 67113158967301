import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import Select from "../Select";

export const PayCodeCalcRule = {
	UNPAID: "00", // 0
	REG: "01", // Pay rate * hours * Multiplier
	REGB: "02", // (Pay rate + additional rate) * Multiplier
	AMT: "40", // Flat amount
	RATE1: "65", // Hours * Other Rate 1 * Multiplier
	RATE2: "66", // Hours * Other Rate 2 * Multiplier
	RATE3: "67", // Hours * Other Rate 3 * Multiplier
	RATE4: "68", // Hours * Other Rate 4 * Multiplier
}

function PaycodeCalcRules({ ...props }) {
	const { t } = useTranslation();

	return (
		<Select {...props} showSearch>
			{props?.hourType === "unpaid" && (
				<Option value={PayCodeCalcRule.UNPAID} key={PayCodeCalcRule.UNPAID}>
					{t("unpaid")}
				</Option>
			)}

			<Option value={PayCodeCalcRule.REG} key={PayCodeCalcRule.REG}>
				{t("payrate-hours-multiplier")}
			</Option>

			<Option value={PayCodeCalcRule.REGB} key={PayCodeCalcRule.REGB}>
				{t("pay-rate-and-additionalrate")}
			</Option>

			{props?.hourType === "amount" && (
				<Option value={PayCodeCalcRule.AMT} key={PayCodeCalcRule.AMT}>
					{t("flat-amount")}
				</Option>
			)}

			<Option value={PayCodeCalcRule.RATE1} key={PayCodeCalcRule.RATE1}>
				{t("hours-other-rate1-multiplier")}
			</Option>
			<Option value={PayCodeCalcRule.RATE2} key={PayCodeCalcRule.RATE2}>
				{t("hours-other-rate2-multiplier")}
			</Option>
			<Option value={PayCodeCalcRule.RATE3} key={PayCodeCalcRule.RATE3}>
				{t("hours-other-rate3-multiplier")}
			</Option>
			<Option value={PayCodeCalcRule.RATE4} key={PayCodeCalcRule.RATE4}>
				{t("hours-other-rate4-multiplier")}
			</Option>
		</Select>
	);
}

export default PaycodeCalcRules;
