import classnames from "classnames";
import { Field as BaseField } from "rc-field-form";
import { Nav, NavItem, NavLink } from "reactstrap";

const NavTabs = ({ activeTab, toggleTab, t }) => (
  <Nav tabs className="mb-3">
    <NavItem>
      <NavLink
        className={classnames("cursor-pointer text-info", {
          active: activeTab === "columns",
        })}
        onClick={() => toggleTab("columns")}
      >
        {t("columns")}
      </NavLink>
    </NavItem>
    <NavItem>
      <BaseField shouldUpdate>
        {(_, __, { getFieldValue }) => {
          const dimensions = getFieldValue("dimensions");
          const hasDimensions = dimensions?.some((item) => !!item?.name);
          return (
            <NavLink
              className={classnames("cursor-pointer text-info", {
                active: activeTab === "settings",
              })}
              disabled={!hasDimensions}
              onClick={() => hasDimensions && toggleTab("settings")}
            >
              {t("settings")}
            </NavLink>
          );
        }}
      </BaseField>
    </NavItem>
    <NavItem>
      <BaseField shouldUpdate>
        {(_, __, { getFieldValue }) => {
          const dimensions = getFieldValue("dimensions");
          const hasDimensions = dimensions?.some((item) => !!item?.name);
          return (
            <NavLink
              className={classnames("cursor-pointer text-info", {
                active: activeTab === "chart",
              })}
              disabled={!hasDimensions}
              onClick={() => hasDimensions && toggleTab("chart")}
            >
              {t("chart")}
            </NavLink>
          );
        }}
      </BaseField>
    </NavItem>
  </Nav>
);

export default NavTabs;
