import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../utils/api";
import { getErrorMessage, isCancelError } from "../../utils/helpers/errors";

export const useHolidaysApi = () => {
    const { t } = useTranslation();
    const [holidays, setHolidays] = useState();
    const [blackoutDates, setBlackoutDates] = useState();
    const [holidayLoading, setHolidayLoading] = useState(false);
    const [blackoutDatesLoading, setBlackoutDatesLoading] = useState(false);

    const { authGet } = useApi();

    const fetchHolidays = useCallback(
        async (controller) => {
            setHolidayLoading(true)
            try {
                const response = await authGet("/holiday/team", {
                    signal: controller?.signal,
                });
                if (response) {
                    setHolidays(response);
                }
                setHolidayLoading(false);
            } catch (error) {
                getErrorMessage(error, t);
                if (error?.code === "ERR_CANCELED" || error?.message === "canceled" || isCancelError(error)) {
                    return;
                }
                setHolidayLoading(false);
            }
        },
        [authGet, t]
    );

    const fetchBlackoutDates = useCallback(
        async (controller) => {
            setBlackoutDatesLoading(true);
            try {
                const response = await authGet("/black-out-calendar-date", {
                    signal: controller?.signal,
                });
                if (response) {
                    setBlackoutDates(response.result);
                }
                setBlackoutDatesLoading(false);
            } catch (error) {
                getErrorMessage(error, t);
                if (error?.code === "ERR_CANCELED" || error?.message === "canceled" || isCancelError(error)) {
                    return;
                }
                setBlackoutDatesLoading(false);
            }
        },
        [authGet, t]
    );

    return {
        fetchHolidays,
        fetchBlackoutDates,
        holidays,
        blackoutDates,
        holidayLoading,
        blackoutDatesLoading
    };
};
