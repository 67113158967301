import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Modal from "../../../../components/Modal";
import Field from "../../../../components/Field";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Select from "../../../../components/Inputs/Select";
import Button from "../../../../components/Button";

const renderLabel = (value) => {
    if (value) {
        const { name } = value;
        return name;
    }
};

const getSearchFilters = (value) => ({
    name: value,
});

const ReportConfigModal = ({ initialLayout, closeDrawer, open, close, handleAddCard }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onFinish = useCallback(
        (values) => {
            const reports = values.reports?.map((item) => ({
                id: item?.id,
                chartType: values[item?.id],
                name: item?.name,
            }));

            const data = reports?.map((item, i) => ({
                i: `report-${item?.id}-${item?.chartType}`,
                hidden: false,
                static: false,
                minW: 3,
                minH: 12,
                w: 4,
                h: 14,
                x: (i + 1) % 3 === 0 ? 8 : (i + 1) % 2 === 0 ? 4 : 0,
                y: initialLayout[initialLayout.length - 1].y + initialLayout[initialLayout.length - 1].h,
                chartType: item?.chartType,
                name: item?.name,
            }));

            data?.map((item) => handleAddCard(item));
            close();
            closeDrawer();
        },
        [close, closeDrawer, handleAddCard, initialLayout]
    );

    return (
        <Modal isOpen={open} toggle={close} centered>
            <div className="d-flex justify-content-end cursor-pointer mb-4">
                <FontAwesomeIcon icon={faXmark} onClick={close} />
            </div>
            <Form form={form} onFinish={onFinish}>
                <Field
                    label={t("reports")}
                    name="reports"
                    rules={[
                        {
                            required: true,
                            message: t("report-is-required"),
                        },
                    ]}
                >
                    <ResourceSelect
                        resourcePath="/reports/v2/charts"
                        renderLabel={renderLabel}
                        hasSearch
                        mode="multiple"
                        getSearchFilters={getSearchFilters}
                    />
                </Field>
                <BaseField shouldUpdate>
                    {(__, _, { getFieldValue }) => {
                        const reports = getFieldValue("reports");

                        if (reports?.length > 0) {
                            return reports.map((report, i) => {
                                return (
                                    <Field key={i} name={report.id} label={t(report?.name)}>
                                        <Select placeholder={t("select-chart")}>
                                            {report.reportData.settings.chartConfigurations.map((chart, i) => {
                                                return (
                                                    <Select.Option key={i} value={chart?.chartType}>
                                                        {chart?.chartType}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Field>
                                );
                            });
                        }
                    }}
                </BaseField>
                <div className="d-flex justify-content-end">
                    <Button onClick={close}>{t("cancel")}</Button>
                    <Button type="submit" className="bg-primary text-white">
                        {t("add")}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ReportConfigModal;
