import { useCallback, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import GlobalLayout from '../../components/GlobalLayout';
import Authentication from '../Authentication';
import DomainValidation from '../DomainValidation';
import Redirect from '../Redirect';

import CompanyOnboarding from '../../features/Authentication/CompanyOnboarding';
import CompanySelect from '../../features/Authentication/CompanySelect';
import Confirm from '../../features/Authentication/Confirm';
import DomainSelect from '../../features/Authentication/DomainSelect';
import LogIn from '../../features/Authentication/LogIn';
import RequiredSetPassword from '../../features/Authentication/RequiredSetPassword';
import ResetPassword from '../../features/Authentication/ResetPassword';
import SetNewPassword from '../../features/Authentication/SetNewPassword';
import SignUp from '../../features/Authentication/SignUp';
import Home from '../../features/Home';
import People from '../../features/People';
import Profile from '../../features/Profile';
import TimeClockClockIn from '../../features/TimeClock/ClockIn';
import TimeClockTeam from '../../features/TimeClock/MyTeam';

import TimeSheetHome from "../../features/TimeSheet/Home";
import TimeSheetTeam from "../../features/TimeSheet/Team";
import TimeSheetWeekly from "../../features/TimeSheet/Weekly";

import AddEmployee from '../../features/People/AddEmployee';
import EmployeePersonalDetails from '../../features/People/EmployeePersonalDetails';
import Onboarding from '../../features/Settings/Onboarding';
import { useProfileRoute } from './profile';
import { useSettingsRoutes } from './settings';

import QrCodeClockin from '../../features/QrCodeClockin/routing';

import OriginalClocks from "../../features/TimeClock/OriginalClocks";
import TimeSheetRedirect from "../../features/TimeSheet/Redirect";
import RequestRedirect from "../../features/Request/Redirect";
import TimeClockRedirect from "../../features/TimeClock/Redirect";
import ManagerView from "../../features/TimeSheet/ManagerView";
import SchedulerRedirect from "../../features/Scheduler/Redirect";
import SchedulerHome from "../../features/Scheduler/Home";
import SchedulerTeam from "../../features/Scheduler/Team";
import SchedulerRedirectV2 from "../../features/NewScheduler/Redirect";
import SchedulerHomeV2 from "../../features/NewScheduler/Home";
import SchedulerTeamV2 from "../../features/NewScheduler/Team";
import SchedulerTeamTemplate from "../../features/NewScheduler/TeamTemplate";
import MyTeamTemplate from "../../features/Scheduler/MyTeamTemplate";
import SystemReports from "../../features/Reports/SystemReports";
import CustomReports from "../../features/Reports/CustomReports";
import Chat from '../../features/Communication/Chat';
import Reports from "../../features/Reports";
import GoogleAuth from "../../features/Authentication/GoogleAuth";
import { useUser } from "../../utils/hooks/user";
import { useAccessInList } from "../../utils/hooks/access";
import { getViewPermissions } from "../../utils/helpers/access";
import { useAuthentication } from "../../features/Authentication/context";
import NotificationsPage from "../../features/Notifications/NotificationsPage";
import CommunicationRedirect from '../../features/Communication/Chat/Redirect';
import Posts from '../../features/Communication/Posts';
import TimeOffRedirect from '../../features/TimeOff/Redirect';
import MyTimeOff from '../../features/TimeOff/Home';
import MyTeamTimeOff from '../../features/TimeOff/Team';
import Request from '../../features/Request';
import { useCompany } from '../../utils/hooks/company';
import ReportV2 from '../../features/ReportV2';
import ReportPreview from '../../features/ReportV2/Preview';
import NReportForm from '../../features/ReportV2/NReportForm';
import ReportDesktop from '../../features/ReportV2/ReportDesktop';
import BillingRedirect from '../../features/Billing/BillingRedirect';
import Clients from '../../features/Billing/Clients';
import BillingReports from '../../features/Billing/Reports';
import Invoices from '../../features/Billing/Invoices';
import ClientPage from '../../features/Billing/Clients/ClientPage';
import Export from '../../features/ReportV2/Export';

export const useRouteConfig = () => {
	const user = useUser();
	const settings = useSettingsRoutes(user?.permissions);
	const userProfile = useProfileRoute(user?.permissions?.people);
	const profile = useProfileRoute({
		...(user?.permissions?.profile || {}),
		notificationSettings: user?.permissions?.notificationSettings
	});

	const company = useCompany();

	const modules = useMemo(() => {
		return company?.settings?.modules || [];
	}, [company?.settings?.modules]);

  const timeClockPermissions = useMemo(
    () =>
      user?.permissions?.clockin
        ? getViewPermissions(user?.permissions?.clockin, 'clockin')
        : [],
    [user?.permissions?.clockin]
  );
  const { hasAccess: canViewClockin } = useAccessInList(timeClockPermissions);

  const getRoutes = useCallback((config) => {
    let routes = [];
    if (Array.isArray(config)) {
      config?.forEach((element, i) => {
        if (!element?.children && element?.access) {
          routes.push(element);
        } else if (
          element?.children &&
          (element?.access || typeof element?.access === 'undefined')
        ) {
          routes.push({
            ...element,
            children: getRoutes(element?.children),
          });
        }
      });
    }
    return routes;
  }, []);

  const routes = useMemo(() => {
    const hasUser = !!user;
    const permissions = user?.permissions;

		return [
				{
					path: "/",
					element: <DomainValidation domain="main" isSecure={false} />,
					children: [
						{
							path: "domain-select",
							element: <DomainSelect />,
							access: true,
						},
						{
							path: "signup",
							element: <SignUp />,
							access: true,
						},
					],
				},
				QrCodeClockin,
				{
					path: "/",
					element: <DomainValidation domain="sub" isSecure={false} />,
					children: [
						{
							path: "login",
							element: <LogIn />,
							access: true,
						},
						{
							path: "confirm",
							element: <Confirm />,
							access: true,
						},
						{
							path: "reset-password",
							element: <ResetPassword />,
							access: true,
						},
						{
							path: "set-new-password",
							element: <SetNewPassword />,
							access: true,
						},
						{
							path: "set-employee-personal-details",
							element: <EmployeePersonalDetails />,
							access: true,
						},
					],
				},
				{
					path: "/",
					element: <Authentication />,
					children: [
						{ path: "login/validate", element: <GoogleAuth />, access: hasUser },
						{ path: "company-selection", element: <CompanySelect />, access: hasUser },
						{ path: "company-onboarding", element: <CompanyOnboarding />, access: hasUser },
						{ path: "required-set-password", element: <RequiredSetPassword />, access: hasUser },
						{ path: "settings/onboarding", element: <Onboarding />, access: hasUser },
						{
							element: <GlobalLayout />,
							children: [
								{ index: true, element: <Home />, access: permissions?.dashboard?.canView },
								{
									path: "people",
									access: permissions?.people?.canView,
									children: [
										{ index: true, element: <People />, access: permissions?.people?.canView },
										{ path: "add-employee", element: <AddEmployee />,  access: permissions?.people?.canCreate },
										{
											path: ":id",
											element: <Profile isSelf={false} />,
											access: permissions?.people?.canView,
											children: userProfile,
										},
									],
								},
								{
									path: "time-clock",
									access: canViewClockin && modules.includes("timeClock"),
									children: [
										{
											index: true,
											element: <TimeClockRedirect />,
											access: permissions?.canManageUsers
												? permissions?.canManageUsers
												: (permissions?.clockin?.canView || permissions?.clockin?.canViewOriginalClock)
										},
										{ path: "my", element: <TimeClockClockIn />, access: permissions?.clockin?.canView   },
										{ path: "team", element: <TimeClockTeam />, access: permissions?.canManageUsers },
										{
											path: "original-clocks",
											element: <OriginalClocks />,
											access: permissions?.clockin?.canViewOriginalClock
										},
									],
								},
								{
									path: "time-sheet",
									access:(permissions?.timeSheet?.canView || permissions?.timeSheet?.canViewMyTimesheet) && modules.includes("timesheet"),
									children: [
										{
											index: true,
											element: <TimeSheetRedirect />,
											access: permissions?.timeSheet?.canView || permissions?.timeSheet?.canViewMyTimesheet,
										},
										{ path: "my", element: <TimeSheetHome />, access: permissions?.timeSheet?.canViewMyTimesheet },
										{ path: "team", element: <TimeSheetTeam />, access: permissions?.timeSheet?.canView },
										{ path: "weekly", element: <TimeSheetWeekly />, access: permissions?.timeSheet?.canView },
										{ path: "manager-view", element: <ManagerView />, access: permissions?.timeSheet?.canView },
									],
								},
								{
									path: "scheduler",
									access: (permissions?.schedule?.canView || permissions?.schedule?.canViewMySchedule) && modules.includes("schedule"),
									children: [
										{
											index: true,
											element: <SchedulerRedirectV2 />,
											access: permissions?.schedule?.canView || permissions?.schedule?.canViewMySchedule,
										},
										{ path: "my", element: <SchedulerHomeV2 />, access: permissions?.schedule?.canViewMySchedule },
										{ path: "team", element: <SchedulerTeamV2 />, access: permissions?.schedule?.canView },
										{ path: "team-template", element: <SchedulerTeamTemplate />, access: permissions?.schedule?.canView },
									],
								},
								{
									path: "time-off",
									access:  (permissions?.timeOff?.canViewMyTimeOff || permissions?.timeOff?.canViewMyTeamTimeOff) && modules.includes("timeOff"),
									children: [
										{
											index: true,
											element: <TimeOffRedirect />,
											access: permissions?.timeOff?.canViewMyTimeOff || permissions?.timeOff?.canViewMyTimeOff
										},
										{
											path: "my", element: <MyTimeOff />, access: permissions?.timeOff?.canViewMyTimeOff,
										},
										{
											path: "team", element: <MyTeamTimeOff />, access: permissions?.timeOff?.canViewMyTeamTimeOff,
										}
									],
								},
								{
									path: "request",
									access: permissions?.request?.canView && modules.includes("requests"),
									children: [
										{
											index: true,
											element: <RequestRedirect />,
											access: permissions?.request?.canView
										},
										{ path: "clock", element: <Request namespace="clock" />, access: permissions?.request?.canView },
										{ path: "pto", element: <Request namespace="pto" />, access: permissions?.request?.canView },
										{ path: "schedule", element: <Request namespace="schedule" />, access: permissions?.request?.canView },
										{ path: "availability", element: <Request namespace="availability" />, access: permissions?.request?.canView },
									],
								},
								{
									path: "profile",
									element: <Profile isSelf={true} />,
									access: permissions?.profile?.canView,
									children: profile,
                },
                // {
                //   path: "reportv2",
                //   // element: <ReportV2 />,
                //   access: permissions?.reports?.canView && modules.includes("reports"),
                //   children: [
                //     { index: true, element: <ReportV2 />, access: true, },
                //     { path: "create", element: <NReportForm />, access: true, },
                //     { path: "edit/:id", element: <NReportForm />, access: true, },
                //     { path: "view/:id", element: <ReportPreview />, access: true, },
                //     { path: "folder/:parentId", element: <ReportDesktop />, access: true },
                //   ]
                // },
								{
									path: 'communication',
									access: permissions?.communication && modules.includes("communication"),
									children: [
										{
											index: true,
											element: <CommunicationRedirect />,
											access: permissions?.communication.canView,
										},
										{
											path: "messages",
											element: <Chat />,
											access: permissions?.communication.canView,
										},
										{
											path: "posts",
											element: <Posts />,
											access: permissions?.communication.canView
										}
									]
								},
								{
									path: "reports",
									access: permissions?.reports?.canView && modules.includes("reports"),
									children: [
										{ index: true, element: <ReportV2 />, access: true, },
										{ path: "custom-reports", element: <ReportDesktop />, access: permissions?.reports?.canView },
										{ path: "exports", element: <Export />, access: permissions?.reports?.canView },
                    { path: "create", element: <NReportForm />, access: true, },
                    { path: "folder/create/:parentId", element: <NReportForm />, access: permissions?.reports?.canCreate, },
										{ path: "edit/:id", element: <NReportForm />, access: true, },
										{ path: "view/:id", element: <ReportPreview />, access: true, },
										{ path: "folder/:parentId", element: <ReportDesktop />, access: true },
										{ path: "old-system-reports", element: <SystemReports />, access: permissions?.reports?.canView },
										{ path: "old-custom-reports", element: <CustomReports />, access: permissions?.reports?.canView },
									]
								},
								{ path: "/notifications", element: <NotificationsPage />, access: hasUser },
								{
									path: "billing",
									access: permissions?.billingRate?.clients?.canView && modules.includes("billingRate"),
									children: [
										{ index: true, element: <BillingRedirect />, access: permissions?.billingRate?.clients?.canView, },
										{ path: "clients", access: permissions?.billingRate?.clients?.canView,
											children: [
												{
													index: true,
													element: <Clients />,
													access: permissions?.billingRate?.clients?.canView
												},
												{
													path: ":id",
													element: <ClientPage />,
													access: permissions?.billingRate?.clients?.canView,
												},
											],
										},
										{ path: "reports", element: <BillingReports />, access: permissions?.billingRate?.reports?.canView },
										{ path: "invoices", element: <Invoices />, access: permissions?.billingRate?.invoices?.canView },
									]
								},
								settings,
							],
						},
					],
				},
				{
					path: "/redirect",
					element: <Redirect />,
					access: true,
				},
				{
					path: "*",
					element: <Navigate to="/redirect" />,
					access: true
				},
		];
	}, [
		user,
		settings,
		userProfile,
		profile,
		canViewClockin,
		modules
	]);

  const { hasAuthenticated } = useAuthentication();

  const validRoutes = useMemo(() => {
    if (!hasAuthenticated) {
      return routes;
    } else {
      return getRoutes(routes);
    }
  }, [getRoutes, routes, hasAuthenticated]);

  return validRoutes;
};
