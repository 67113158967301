import moment from "moment-timezone";
import { combineDateAndTime } from "../../../../../../../../utils/helpers/date";

export const startValidation = (intervals, getFieldValue, sections, date, name, t) => {
    return {
        validator: (_, value) => {
            let end = getFieldValue(["sections", sections?.length - 1, "end"]);
            end = end ? combineDateAndTime(moment(date), moment(end)) : undefined;

            let currentEnd = getFieldValue(["sections", name, "end"]);
            currentEnd = currentEnd ? combineDateAndTime(moment(date), moment(currentEnd)) : undefined;

            if (value) {
                value = combineDateAndTime(moment(date), moment(value));
                if (currentEnd) {
                    if (moment(currentEnd).isBefore(moment(value), "seconds")) {
                        currentEnd = moment(currentEnd).add(1, "day");
                    }

                    const duration = moment(currentEnd).diff(moment(value), "seconds");
                    
                    if (duration > 86100) {
                        return Promise.reject(
                            new Error(t("max-duration", {
                                duration: "23:55"
                            }))
                        );
                    } else if (duration < 300) {
                        return Promise.reject(t("section-start-difference-from-shift-end"))
                    } else {
                        return Promise.resolve();
                    }
                }

                if (end) {
                    if (moment(end).isBefore(moment(value), "seconds")) {
                        end = moment(end).add(1, "day");
                    }

                    const duration = moment(end).diff(moment(value), "seconds");

                    if (duration > 86100) {
                        return Promise.reject(
                            new Error(t("max-duration", {
                                duration: "23:55"
                            }))
                        );
                    } else if (duration < 300) {
                        return Promise.reject(t("section-start-difference-from-shift-end"))
                    } else {
                        return Promise.resolve();
                    }
                }

                if (intervals?.length > 0) {
                    const allDayNonWorkingTime = intervals?.find((interval) => interval.allDay && !interval.isWorking);

                    const hasError = intervals
                        ?.filter((interval) => interval?.isWorking)
                        ?.find((interval) => {
                            const start = moment.parseZone(interval.startDate);
                            const end = moment.parseZone(interval.endDate);
                            return !moment(value).utcOffset(start.utcOffset(), true).isBetween(moment(start), moment(end), "seconds", "[]");
                        });
                    if (!!hasError) {
                        return Promise.reject(new Error(t("event-invalid-time")))
                    } else if (allDayNonWorkingTime) {
                        return Promise.reject(new Error(t("day-not-available")))
                    } else {
                        return Promise.resolve();
                    }
                }

            }
            return Promise.resolve();
        }
    }
}

export const endValidation = (intervals, getFieldValue, name, date, t) => {
    return {
        validator: (_, value) => {
            let currentStart = getFieldValue(["sections", name, "start"]);
            currentStart = currentStart ? combineDateAndTime(moment(date), moment(currentStart)) : undefined;

            let start = getFieldValue(["sections", 0, "start"]);
            start = start ? combineDateAndTime(moment(date), moment(start)) : undefined;
          
            if (value) {
                value = combineDateAndTime(moment(date), moment(value));
                if (currentStart) {
                    if (moment(value).isBefore(moment(currentStart), "seconds")) {
                        value = moment(value).add(1, "day");
                    }
                    const duration = moment(value).diff(moment(currentStart), "seconds");

                    if (duration > 86100) {
                        return Promise.reject(
                            new Error(t("max-duration", {
                                duration: "23:55"
                            }))
                        );
                    } else if (duration < 300) {
                        return Promise.reject(t("section-start-difference-from-shift-end"))
                    } 
                    // else {
                    //     // return Promise.resolve();
                    // }
                }

                if (start) {
                    if (moment(value).isBefore(moment(start), "seconds")) {
                        value = moment(value).add(1, "day");
                    }
                    const duration = moment(value).diff(moment(start), "seconds");

                    if (duration > 86100) {
                        return Promise.reject(
                            new Error(t("max-duration", {
                                duration: "23:55"
                            }))
                        );
                    } else if (duration < 300) {
                        return Promise.reject(t("section-start-difference-from-shift-end"))
                    } 
                    // else {
                    //     return Promise.resolve();
                    // }
                }

                if (intervals?.length > 0) {
                    const allDayNonWorkingTime = intervals?.find((interval) => interval.allDay && !interval.isWorking);
                   
                    const hasError = intervals
                        ?.filter((interval) => interval?.isWorking)
                        ?.find((interval) => {
                            const start = moment.parseZone(interval.startDate);
                            const end = moment.parseZone(interval.endDate);
                            return !moment(value).utcOffset(start.utcOffset(), true).isBetween(moment(start), moment(end), "seconds", "[]");
                        });
                    if (!!hasError) {
                        return Promise.reject(new Error(t("event-invalid-time")))
                    } else if (allDayNonWorkingTime) {
                        return Promise.reject(new Error(t("day-not-available")))
                    } 
                    // else {
                    //     return Promise.resolve();
                    // }
                }

            }

            return Promise.resolve();
        }
    }
}