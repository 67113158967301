import { faGripVertical, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field as BaseField } from "rc-field-form";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import Select from "../../../../../components/Inputs/Select";
import Text from "../../../../../components/Inputs/Text";

function ListItem({
  key,
  index,
  options,
  name,
  selectedType,
  selectedFields,
  fields,
  remove,
  error,
  moveCard,
  setFormData
}) {
  const { t } = useTranslation();
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "item",
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;
  drag(drop(ref));

  return (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue }) => {
        const value = getFieldValue(["dimensions", name]);

        if (value?.dataIndex && value?.dataIndex?.includes("calculation")) {
          return (
            <div className="d-flex w-100 align-items-center gap-2 mb-4">
              <FontAwesomeIcon
                icon={faGripVertical}
                className="cursor-move"
              />

              <BaseField name={[name, "name"]} />
              <BaseField name={[name, "formula"]} />
              <BaseField name={[name, "type"]} />
              <BaseField name={[name, "dataIndex"]} />

              <div
                key={key}
                ref={ref}
                style={{ opacity }}
                className="px-3 py-2 border rounded bg-white d-flex align-items-center justify-content-between w-100"
                data-handler-id={handlerId}
              >
                <div className="d-flex flex-row gap-8">
                  <div>
                    <strong>{t("name")}:</strong> {value.name}
                  </div>
                  <div>
                    <strong>{t("formula")}:</strong> {value.formula}
                  </div>
                </div>

                <Button color="outline-danger" onClick={() => remove(name)}>
                  <FontAwesomeIcon icon={faTrashCan} />
                </Button>
              </div>
            </div>
          );
        }

        return (
          <div key={key} ref={ref} style={{ opacity }} className="mb-4">
            <div className="d-flex align-items-center gap-2 mb-2">
              <FontAwesomeIcon
                icon={faGripVertical}
                data-handler-id={handlerId}
                className="cursor-move"
              />
              <BaseField
                name={[name, "type"]}
                rules={[
                  {
                    required: true,
                    message: t("required-type"),
                  },
                ]}
              >
                <Select
                  placeholder={t("select-type")}
                  showSearch
                  onChange={() => {
                    setFormData(prev => ({
                      ...prev,
                      dimensions: prev?.dimensions?.map((dim, index) => {
                        if (index === name) {
                          return {
                            ...dim,
                            name: undefined,
                            alias: undefined,
                            aggregation: undefined,
                          };
                        }
                        return dim;
                      })
                    }));
                  }}
                >
                  {fields
                    .filter((fieldOption) => options?.includes(fieldOption.table))
                    .map((fieldOption) => (
                      <Select.Option key={fieldOption.name} value={fieldOption.name}>
                        {fieldOption.display_name}
                      </Select.Option>
                    ))}
                </Select>
              </BaseField>

              {selectedType && (
                <BaseField
                  name={[name, "name"]}
                  rules={[
                    {
                      required: true,
                      message: t("required-field"),
                    },
                  ]}
                >
                  <Select
                    placeholder={t("select-field")}
                    showSearch
                    onChange={() => {
                      setFormData(prev => ({
                        ...prev,
                        dimensions: prev?.dimensions?.map((dim, index) => {
                          if (index === name) {
                            return {
                              ...dim,
                              alias: undefined,
                            };
                          }
                          return dim;
                        })
                      }));
                    }}
                  >
                    {selectedFields?.map((fieldOption) => (
                      <Select.Option
                        key={fieldOption.name}
                        value={`${selectedType}.${fieldOption.name}`}
                      >
                        {fieldOption.display_name}
                      </Select.Option>
                    ))}
                  </Select>
                </BaseField>
              )}

              <BaseField name={[name, "aggregation"]} />

              <BaseField name={[name, "alias"]}>
                <Text placeholder={t("optional-display-name")} />
              </BaseField>

              <Button color="outline-danger" onClick={() => remove(name)}>
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
              <div>{error && <span className="text-danger">{error}</span>}</div>
            </div>
          </div>
        );
      }}
    </BaseField>
  );
}

export default ListItem;
