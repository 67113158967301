import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useQrCodeClockIn } from "../context";

import { useValidate } from "./useValidate";
import { useValidateLocation } from "./useValidateLocation";
import { useAuthenticate } from "./useAuthenticate";
import { usePosition } from "./usePosition";
import { useRevalidateOnFocus } from "./useRevalidateOnFocus";

import AppLoader from "../../../components/Loaders/AppLoader";
import { useArea } from "./useArea";
import { useTranslation } from "react-i18next";
import { useGetFlyers } from "./useGetFlyers";
import { useGetAttestations } from "./useGetAttestations";

function LogicProvider() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { pathname } = useLocation();
    const {
        hasValidatedDomain,
        domainValid,

        hasAuthenticated,
        user,
        token,

        area,
        hasCheckedPosition,
        position,
        isGeolocationEnabled,
        isPointInsidePolygon,

        hasValidatedLocation,
        fetchingArea,
        isValidatingLocation,
        qrCode,

        clockMode,
    } = useQrCodeClockIn();

    useValidate();
    useArea(id);
    useGetFlyers(user, token);
    useGetAttestations(user, token);
    useValidateLocation();
    useAuthenticate();
    usePosition();
    useRevalidateOnFocus();

    const hasPosition = !!position;
    const hasUser = !!user;
    const hasLocation = !!qrCode;

    useEffect(() => {
        localStorage.removeItem("TOKEN");
        sessionStorage.removeItem("TOKEN");
        if (hasUser &&  clockMode === "attestation" ) {
            return navigate(`/qr-code-clock-in/${id}/${clockMode}`);
        }

        if (
            hasUser && (
                clockMode === "clock-in" ||
                clockMode === "end-break" ||
                clockMode === "clock-out" ||
                clockMode === "start-break"
            )
        ) {
            return navigate(`/qr-code-clock-in/${id}/success/${clockMode}`);
        }

        if (
            (hasValidatedDomain && !domainValid)
            || (hasValidatedDomain && domainValid && !isGeolocationEnabled)
            || (hasValidatedLocation && !hasLocation)
            || (hasCheckedPosition && !isGeolocationEnabled)
            || (isGeolocationEnabled && hasPosition && !isPointInsidePolygon)
        ) {
            return navigate(`/qr-code-clock-in/${id}/not-valid`);
        }

        if (hasUser && clockMode === null) {
            return navigate(`/qr-code-clock-in/${id}/clocks`);
        }

        if (domainValid && hasLocation && !hasUser) {
            return navigate(`/qr-code-clock-in/${id}/login`);
        }
    }, [
        navigate,
        clockMode,
        id,
        hasPosition,
        hasUser,
        isGeolocationEnabled,
        hasCheckedPosition,
        isPointInsidePolygon,
        hasValidatedDomain,
        domainValid,
        hasValidatedLocation,
        hasLocation,
    ]);

    // WORKAROUND
    if (
        area
        && area[0].length === 1
        && area[0][0] === null
        && pathname !== `/qr-code-clock-in/${id}/not-valid`
    ) {
        return <Navigate to={`/qr-code-clock-in/${id}/not-valid`} />;
    }

    if (
        !hasValidatedDomain
        || (token && !hasAuthenticated)
        || fetchingArea
        || isValidatingLocation
        || !hasCheckedPosition
    ) {
        let message;
        if (!hasValidatedDomain) {
            message = t("validating-domain");
        } else if (token && !hasAuthenticated) {
            message = t("authenticating");
        } else if (fetchingArea) {
            message = t("getting-area");
        } else if (isValidatingLocation) {
            message = t("getting-qr-code");
        } else if (!hasCheckedPosition) {
            message = t("checking-current-position");
        }
        return (
            <AppLoader>
                <div>
                    {message}
                </div>
            </AppLoader>
        );
    }

    return <Outlet />;
}

export default LogicProvider;
