import { omit } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useDateFilters } from "../../../components/DateRangeFilters/useDateFilters";
import { useGenerateReport, useGetReport } from "../api";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A28BFA", "#F45B69", "#FA7B17", "#D83A56"];

const ChartView = ({ values }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { from, to } = useDateFilters();
  const { getReport, report, loading: reportLoading } = useGetReport();
  const { generateReport, generate, data: generatedData, loading: dataLoading } = useGenerateReport();
  const [opacity, setOpacity] = useState({});

  const processFilterValues = (values) => {
    if (!values) return [];

    const processValue = (v) => {
      if (v == null) return null;

      if (v instanceof Date || moment.isMoment(v)) {
        return moment(v).format("YYYY-MM-DD");
      }

      if (typeof v === "object") {
        if (v.id) {
          return {
            id: v?.id,
            code: v?.code,
            description: v?.description,
            glSegment: v?.glSegment,
            name: v?.name,
          }
        }
        return null;
      }

      if (typeof v === "string") {
        if (moment(v, moment.ISO_8601, true).isValid()) {
          return moment(v).format("YYYY-MM-DD");
        }
        return v;
      }

      return String(v);
    };

    if (Array.isArray(values)) {
      return values.map(processValue).filter(Boolean);
    }

    if (typeof values === "string" && values.includes(",")) {
      return values
        .split(",")
        .map((s) => s.trim())
        .filter(Boolean);
    }

    const result = processValue(values);
    return result ? [result] : [];
  };

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
  }

  const handleMouseLeave = (o) => {
    const { dataKey } = o;
    setOpacity((op) => ({ ...op, [dataKey]: 1 }));
  };

  useEffect(() => {
    const controller = new AbortController();
    if (id && !values) {
      getReport(id, controller);
      generateReport(
        {
          reportId: id,
          limit: 1200,
          offset: 0,
          total: true,
          from: moment(from).format("YYYY-MM-DD"),
          to: moment(to).format("YYYY-MM-DD"),
        },
        controller
      );
    } else if (values) {
      const dimensions = values?.dimensions?.map((dim) => omit(dim, ["relationships", "table"])) || [];
      if (values?.calculations?.length > 0) {
        const calculations = values.calculations.map((calc, i) => ({
          ...calc,
          dataIndex: "calculation_" + i,
        }));
        dimensions.push(...calculations);
      }

      const data = {
        ...values,
        filters: values?.filters?.map((filter, i) => ({
          member: filter.name,
          operator: filter.operator,
          values: processFilterValues(filter?.values),
          join: values?.filters?.[i - 1]?.join || "AND",
        })),
        dimensions: dimensions?.flat(),
        limit: 1200,
        offset: 0,
        total: true,
        order: values?.orders || [],
        measures: values?.measures?.map((measure) => ({
          name: `${measure?.name}.${measure?.aggregation}`,
          alias: measure.alias || undefined,
        })) || [],
        union: values?.unions || [],
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      };
      generate(omit(data, ["calculations"]), controller);
    }
    return () => controller.abort();
  }, [id, getReport, generateReport, generate, from, to, values]);

  const chartConfigurations = useMemo(
    () => values?.chartConfigurations || report?.reportData?.settings?.chartConfigurations,
    [values, report]
  );

  const data = (generatedData || []).slice(0, 10);

  const columns = useMemo(() => {
    if (values) {
      return [
        ...(values?.dimensions?.map((dim) => ({
          ...dim,
          type: dim?.dataType || "STRING",
        })) || []),
        ...(values?.measures?.map((measure) => ({
          name: `${measure?.name}.${measure?.aggregation}`,
          alias: measure.alias || `${measure?.name}.${measure?.aggregation}`,
          type: measure?.isCurrency ? "MONEY" : "NUMBER",
        })) || []),
        ...(values?.calculations?.map((calc, i) => ({
          name: "calculation_" + i,
          alias: calc.alias || "calculation_" + i,
          type: "NUMBER",
        })) || []),
      ];
    }
    return report?.reportData?.columns || [];
  }, [report, values]);

  const renderCharts = () => {
    if (dataLoading || reportLoading) {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status" />
        </div>
      );
    }

    if (!chartConfigurations || !chartConfigurations.length || !data.length) {
      return <p className="text-center">{t("no-data")}</p>;
    }

    const columnNameMap = {};
    const columnTypeMap = {};
    columns.forEach((column) => {
      const key = column.name.replace(/\./g, "_");
      columnNameMap[key] = column.alias || column.name;
      columnTypeMap[key] = column.type;
    });

    return chartConfigurations.map((chartConfig, index) => {
      const { chartType, x, y, color } = chartConfig;

      if (!x || !y) {
        return <p key={index}>{t("no-columns-selected")}</p>;
      }

      const xArray = Array.isArray(x) ? x : [x];
      const combinedXKey = xArray.map((xItem) => xItem.replace(/\./g, "_")).join("_AND_");

      const xTypes = xArray.map((xItem) => {
        const col = columns.find((c) => c.name === xItem);
        return col ? col.type : "STRING";
      });

      const yKey = y.replace(/\./g, "_");
      const yType = columnTypeMap[yKey] || "NUMBER";

      const measureFormat = yType === "MONEY" ? "currency" : "number";

      const parseValueToNumber = (value) => {
        if (typeof value === 'string') {
          return parseFloat(value.replace(/[\\$,]/g, '')) || 0;
        }
        if (typeof value === 'number') {
          return value;
        }
        if (value === null || value === undefined) {
          return 0;
        }
        return parseFloat(String(value).replace(/[\\$,]/g, '')) || 0;
      };

      const chartData = data.map((item, idx) => {
        const dataPoint = {};

        const combinedXValue = xArray.map((xItem) => {
          let val = item[xItem.replace(/\./g, "_")];
          const typeIndex = xArray.indexOf(xItem);
          if (xTypes[typeIndex] === "DATE" && val) {
            val = moment(val).format("YYYY-MM-DD");
          }
          return val || "-";
        }).join(" & ");

        dataPoint[combinedXKey] = combinedXValue;

        const yValue = item[yKey];
        const numericY = parseValueToNumber(yValue);
        dataPoint[yKey] = numericY;

        return dataPoint;
      });

      const selectedColor = color || "#8884d8";

      const xTickFormatter = (tick) => {
        const hasDate = xTypes.some(type => type === "DATE");
        if (hasDate) {
          const parts = tick.split(" & ");
          const formattedParts = parts.map(part => {
            const date = moment(part, "YYYY-MM-DD", true);
            return date.isValid() ? date.format("MMM DD, YYYY") : part;
          });
          return formattedParts.join(" & ");
        }
        return tick;
      };

      return (
        <div key={index} className="mb-4 flex-grow-1 d-flex flex-column">
          <h5>{t(`${chartType}-chart`)}</h5>
          <ResponsiveContainer width="100%" height={300}>
            {chartType === "bar" && (
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey={combinedXKey}
                  // label={{ value: combinedXKey.replace(/_/g, " "), position: "insideBottom", offset: -5 }}
                  tickFormatter={xTickFormatter}
                />
                <YAxis />
                <Tooltip
                  formatter={(value) => [
                    measureFormat === "currency" ? `$${value.toFixed(2)}` : value,
                    columnNameMap[yKey],
                  ]}
                  labelFormatter={(value) => value}
                />
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                <Bar
                  dataKey={yKey}
                  fill={selectedColor}
                  name={columnNameMap[yKey]}
                  strokeOpacity={opacity[yKey]}
                  activeBar={{ r: 8 }}
                />
              </BarChart>
            )}
            {chartType === "line" && (
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey={combinedXKey}
                  // label={{ value: combinedXKey.replace(/_/g, " "), position: "insideBottom", offset: -5 }}
                  tickFormatter={xTickFormatter}
                />
                <YAxis />
                <Tooltip
                  formatter={(value) => [
                    measureFormat === "currency" ? `$${value.toFixed(2)}` : value,
                    columnNameMap[yKey],
                  ]}
                  labelFormatter={(value) => value}
                />
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                <Line
                  type="monotone"
                  dataKey={yKey}
                  stroke={selectedColor}
                  name={columnNameMap[yKey]}
                  strokeOpacity={opacity[yKey]}
                />
              </LineChart>
            )}
            {chartType === "area" && (
              <AreaChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey={combinedXKey}
                  // label={{ value: combinedXKey.replace(/_/g, " "), position: "insideBottom", offset: -5 }}
                  tickFormatter={xTickFormatter}
                />
                <YAxis />
                <Tooltip
                  formatter={(value) => [
                    measureFormat === "currency" ? `$${value.toFixed(2)}` : value,
                    columnNameMap[yKey],
                  ]}
                  labelFormatter={(value) => value}
                />
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                <Area
                  type="monotone"
                  dataKey={yKey}
                  stroke={selectedColor}
                  fill={selectedColor}
                  name={columnNameMap[yKey]}
                  strokeOpacity={opacity[yKey]}
                />
              </AreaChart>
            )}
            {chartType === "pie" && (
              <PieChart width={400} height={400}>
                <Tooltip
                  formatter={(value) => `$${value.toFixed(2)}`}
                  labelFormatter={(label) => `Category: ${label}`}
                />

                <Legend verticalAlign="bottom" align="center" layout="horizontal" />

                <Pie
                  data={chartData.map((item) => ({
                    name: item[combinedXKey],
                    value: item[yKey],
                  }))}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  innerRadius={50}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(1)}%)`}
                  labelLine={false}
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            )}
          </ResponsiveContainer>
        </div>
      );
    });
  };

  return (
    <Card className="w-100 h-100 d-flex flex-column m-0 p-0">
      <CardHeader>
        <h3 className="mb-0">{values?.name || report?.name}</h3>
      </CardHeader>
      <CardBody className="w-100 h-100 d-flex flex-column">
        <div className="flex-grow-1 d-flex flex-column">
          {renderCharts()}
        </div>
      </CardBody>
    </Card>
  );
};

export default ChartView;
