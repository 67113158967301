import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import Text from "../../../../../components/Inputs/Text";
import Switch from "../../../../../components/Inputs/Switch";
import HourTypeSelect from "../../../../../components/Inputs/HourTypeSelect";
import NumberInput from "../../../../../components/Inputs/NumberInput";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import PaycodeCalcRules from "../../../../../components/Inputs/PaycodeCalcRules";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import StatusField from "../../../../../components/Field/StatusField";
import { getCompaniesSearchFilters } from "../../../../Profile/Personal/Actions/ChangeCompanyInformation";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import { getHourTypeColor } from "../../../../../utils/helpers/hourType";
import { useCompanyCurrency } from "../../../../../utils/hooks/company";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";

function Content({ form, mode, disabled }) {
	const { t } = useTranslation();
	const currency = useCompanyCurrency();

	const onHourTypeChange = useCallback(
		(value) => {
			form.setFieldsValue({ color: getHourTypeColor(value), payCode: undefined });
			if (value === "unit" || value === "amount") {
				form.setFieldValue("forSchedule", false);
			}
			if (value === 'amount') {
				form.setFieldValue("calcRule", "40");
			} else if (value === 'unpaid') {
				form.setFieldValue("calcRule", "00");
			} else if (form.getFieldValue("calcRule") === "40" || form.getFieldValue("calcRule") === "00" || !form.getFieldValue("calcRule")) {
				form.setFieldValue("calcRule", "01");
			}
		},
		[form],
	);

	const onEarningGroupChange = useCallback(() => {
		form.setFieldsValue({ payCode: undefined });
	}, [form]);

	const onAllowedAllocationTypeChange = useCallback((value) => {
		form.setFieldsValue({
			includeOrExcludeAllocated: value !== null,
			allowedCompanies: [],
			allowedPayGroups: [],
			excludedCompanies: [],
			excludedPayGroups: [],
		});
	}, [form]);

	return (
		<>
			<Field
				label={t("code")}
				name="code"
				rules={[
					{
						required: true,
						message: t("required-code"),
					},
					{
						message: t("paycode-code-max-length"),
						min: 1,
						max: 6,
					},
					{
						pattern: /^[a-zA-Z0-9]+$/,
						message: t(
							"code-pattern",
						),
					},
				]}
			>
				<Text
					placeholder={t("code")}
					disabled={mode === "edit" || disabled}
				/>
			</Field>

			<Field
				label={t("description")}
				name="description"
				rules={[
					{
						required: true,
						message: t("required-description"),
					},
				]}
			>
				<Text placeholder={t("description")} disabled={disabled} />
			</Field>

			<StatusField disabled={disabled} />

			<Field
				name="earningGroup"
				label={t("earning-group")}
				rules={[
					{
						required: true,
						message: t("required-earning-group"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="description"
					renderLabel={renderSettingsResourceLabel}
					resourcePath="/earning-group"
					placeholder={t("earning-group")}
					onChange={onEarningGroupChange}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={disabled}
				/>
			</Field>

			<Field
				name="hourType"
				label={t("hour-type")}
				rules={[
					{
						required: true,
						message: t("required-hour-type"),
					},
				]}
			>
				<HourTypeSelect
					placeholder={t("hour-type")}
					disabled={disabled}
					onChange={onHourTypeChange}
				/>
			</Field>

			<BaseField shouldUpdate noStyle className="w-100">
				{({ }, { }, { getFieldValue }) => {
					const hourType = getFieldValue("hourType");

					return (
						<>
							<Field
								name="calcRule"
								label={t("calc-rule")}
								rules={[
									{
										required: true,
										message: t("required-calc-rule"),
									},
								]}
							>
								<PaycodeCalcRules
									placeholder={t("calc-rule")}
									disabled={["unpaid", "amount"].includes(hourType) || disabled}
									hourType={hourType}
								/>
							</Field>
						</>
					);
				}}
			</BaseField>

			<Field
				name="multiplier"
				label={t("multiplier")}
				rules={[
					{
						required: true,
						message: t("required-multiplier"),
					},
				]}
			>
				<NumberInput
					placeholder={t("multiplier")}
					disabled={disabled}
				/>
			</Field>

			<Field
				className="d-flex flex-column"
				name="defaultRate"
				label={t("default-rate")}
			>
				<MoneyInput
					placeholder={t("default-rate")}
					disabled={disabled}
					prefix={renderCurrencySymbol(currency)}
				/>
			</Field>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) => {
					const calcRule = getFieldValue("calcRule");

					return calcRule === "02" && (
						<Field
							className="d-flex flex-column"
							name="extraRate"
							label={t("extra-rate")}
						>
							<MoneyInput
								placeholder={t("extra-rate")}
								disabled={disabled}
								prefix={renderCurrencySymbol(currency)}
							/>
						</Field>
					);
				}}
			</BaseField>

			<Field
				name="color"
				label={t("color")}
				rules={[
					{
						required: true,
						message: t("required-color"),
					},
				]}
				required
			>
				<Text type="color" placeholder={t("color")} disabled />
			</Field>

			<BaseField shouldUpdate noStyle className="w-100">
				{({ }, { }, { getFieldValue }) => {
					const allowedAllocationType = getFieldValue(
						"allowedAllocationType",
					);
					const includeOrExclude = getFieldValue(
						"includeOrExcludeAllocated"
					);
					const companyAllocation =
						allowedAllocationType === "company-allocation";
					const payGroupAllocation =
						allowedAllocationType === "pay-group-allocation";

					return (
						<>
							<Field
								name="allowedAllocationType"
								label={t("allowed-allocation-type")}
							>
								<Select
									placeholder={t("allowed-allocation-type")}
									onChange={onAllowedAllocationTypeChange}
									disabled={disabled}
								>
									<Select.Option value={null}>
										{t("none")}
									</Select.Option>

									<Select.Option value="company-allocation">
										{t("company-allocation")}
									</Select.Option>

									<Select.Option value="pay-group-allocation">
										{t("pay-group-allocation")}
									</Select.Option>
								</Select>
							</Field>

							{(companyAllocation || payGroupAllocation) && (
								<Field
									name="includeOrExcludeAllocated"
									label={t("include-or-exclude-allocated")}
									valuePropName="checked"
								>
									<Switch disabled={disabled} />
								</Field>
							)}

							{companyAllocation && (
								<Field
									name={includeOrExclude ? "allowedCompanies" : "excludedCompanies"}
									label={includeOrExclude ? t("allowed-companies") : t("excluded-companies")}
								>
									<ResourceSelect
										labelPropName="name"
										resourcePath="/companies"
										placeholder={t("select-companies")}
										hasSearch
										getSearchFilters={
											getCompaniesSearchFilters
										}
										mode="multiple"
										disabled={disabled}
									/>
								</Field>
							)}

							{payGroupAllocation && (
								<Field
									name={includeOrExclude ? "allowedPayGroups" : "excludedPayGroups"}
									label={includeOrExclude ? t("allowed-pay-groups") : t("excluded-pay-groups")}
								>
									<ResourceSelect
										mode="multiple"
										labelPropName="description"
										resourcePath="/pay-group"
										placeholder={t("pay-group")}
										renderLabel={
											renderSettingsResourceLabel
										}
										hasSearch
										getSearchFilters={
											getSettingsResourceSearchFilters
										}
										disabled={disabled}
									/>
								</Field >
							)}
						</>
					);
				}}
			</BaseField >

			<Field
				name="inculdeForExport"
				label={t("include-for-export")}
				valuePropName="checked"
			>
				<Switch disabled={disabled} />
			</Field>

			<Field
				name="forBreak"
				label={t("for-break")}
				valuePropName="checked"
			>
				<Switch disabled={disabled} />
			</Field>

			<Field
				name="forAbsence"
				label={t("for-absence")}
				valuePropName="checked"
			>
				<Switch disabled={disabled} />
			</Field>

			<Field
				name="inputDurationAsNumber"
				label={t("input-duration-as-number")}
				valuePropName="checked"
			>
				<Switch disabled={disabled} />
			</Field>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) => {
					const hourType = getFieldValue("hourType");

					return (
						<Field
							name="forSchedule"
							label={t("for-schedule")}
							valuePropName="checked"
						>
							<Switch disabled={disabled} />
						</Field>
					);
				}}
			</BaseField>

			<Field
				name="overridePayCodeOnReport"
				label={t("override-pay-code-on-report")}
				valuePropName="checked"
			>
				<Switch disabled={disabled} />
			</Field>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) => {
					const hourType = getFieldValue("hourType");
					const overridePayCodeOnReport = getFieldValue("overridePayCodeOnReport");
					const earningGroup = getFieldValue("earningGroup");
					const query = new URLSearchParams();
					if (hourType) {
						query.append("filter[hourType][method]", "exact");
						query.append("filter[hourType][value]", hourType);
					}

					if (earningGroup) {
						query.append("filter[earningGroup][method]", "exact");
						query.append("filter[earningGroup][value]", earningGroup.id);
					}

					const resourcePath = hourType || earningGroup ? `/pay-code?${query.toString()}` : undefined;

					return overridePayCodeOnReport ? (
						<>
							<Field
								name="payCode"
								label={t("pay-codes")}
								rules={[
									{
										required: true,
										message: t("required-pay-code")
									}
								]}
							>
								<ResourceSelect
									labelPropName="description"
									resourcePath={resourcePath}
									placeholder={t("pay-code")}
									renderLabel={
										renderSettingsResourceLabel
									}
									hasSearch
									getSearchFilters={
										getSettingsResourceSearchFilters
									}
								/>
							</Field>
							{!resourcePath && (
								<div className="invalid-feedback d-block">
									{t("select-hour-type")}
								</div>
							)}
						</>
					) : null;
				}}
			</BaseField>
		</>
	);
}

export default Content;
