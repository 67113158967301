import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Modal from "../../../components/Modal";
import Form from "./Form";
import { useGetIpAddress } from "../../TimeClock/ClockIn/api";
import { useMyRequests } from "../api";
import { PayCodesProvider } from "../../NewScheduler/Component/Drawers/Event/PayCodeSelect/usePayCodes";
import { useUser } from "../../../utils/hooks/user";
import { combineDateAndTime } from "../../../utils/helpers/date";
import { useCompany } from "../../../utils/hooks/company";
import { calculateSectionsWithAutomaticBreaks, getAutomaticBreaks, recalculateSections } from "../../NewScheduler/Component/helpers/sections";
import "./styles.scss";
import { getFormFormattedValues } from "../../NewScheduler/Component/helpers/getFormFormattedValues";

function AddSchedule({ isOpen, onClose, setData }) {
	const { t } = useTranslation();
	const user = useUser();
	const company = useCompany();
	const { ip, getIp } = useGetIpAddress();
	const { loading, error, create } = useMyRequests();

	const onFinish = useCallback((values) => {
		if (!user) {
			return;
		}

		const date = moment(values.date).format('YYYY-MM-DD');

        let startDate = combineDateAndTime(moment(date), moment(values.startDate));
        let endDate = combineDateAndTime(moment(date), moment(values.endDate));

        if (startDate && endDate && moment(endDate).isBefore(startDate, "seconds")) {
            endDate.add(1, "day");
        }

        const isMandatoryBreak = user?.calculationGroup?.breaks?.manual?.mandatory;
        const breakAmount = isMandatoryBreak
            ? Number(user?.calculationGroup?.breaks?.manual?.duration || 0)
            : 0;

        let sections = recalculateSections(
            values.sections,
            {},
            breakAmount * 60,
            user?.calculationGroup?.breaks,
			date
        );

        let breaks = getAutomaticBreaks(
            user?.calculationGroup?.breaks,
            sections,
            {}
        );
        const sectionBreaks = sections.filter(section => section?.type === "break");
        breaks = breaks.slice(sectionBreaks.length);

		let calculatedSections = sections;
        if (breaks.length > 0 &&user?.calculationGroup?.breaks?.automatic?.status ) {
            const lastBreakIndex = sections.findLastIndex(shift => shift.type === "break");
            calculatedSections = [
                ...sections.slice(0, lastBreakIndex + 1),
                ...calculateSectionsWithAutomaticBreaks(sections.slice(lastBreakIndex + 1), breaks)
            ];
        }

		const data = getFormFormattedValues({
            ...values,
            startDate,
            endDate,
            sections: calculatedSections,
            company
        });
		const request = {
            user: user?.id,
            type: "schedule",
            device: "web-application",
            ip: ip ? ip : "",
            data,
            namespace: "schedule",
        }
		create(request, (response) => {
			onClose();
			setData((prev) => (prev || [])?.concat(response));
			toast.success(t("request-created-successfully"));
		})
	}, [user, company, ip, create, setData, onClose, t]);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);
		return () => controller.abort();
	}, [getIp]);

	return (
		<Modal
			className="add-schedule-modal modal-dialog-centered modal-lg pb-0 shadow-none"
			title={t("add-schedule-request")}
			isOpen={isOpen}
			toggle={onClose}
			size="lg"
			scrollable
			centered
		>
			<PayCodesProvider>
				<Form
					loading={loading}
					error={error}
					onClose={onClose}
					onFinish={onFinish}
				/>
			</PayCodesProvider>
		</Modal>
	)
}

export default AddSchedule;
