import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";

const DashboardControls = (
  {
    editable,
    toggleEditable,
    onLayoutChange,
    enableAllHandles,
    resetLayout,
    layout,
    showDrawer
  },
) => {
  const { t } = useTranslation();

  return (
    <div className="dashboard-controls d-flex justify-content-between align-items-center mb-2">
      <div className="d-flex">
        <Button onClick={toggleEditable} className="btn-sm">
          {editable ? t("disable-edit") : t("enable-edit")}
        </Button>
        {editable && (
          <>
            <Button
              onClick={() => {
                onLayoutChange(layout);
              }}
              className="btn-sm"
            >
              {t("save-layout")}
            </Button>
            <Button
              onClick={() => {
                enableAllHandles();
              }}
              className="btn-sm"
            >
              {t("enable-all-handles")}
            </Button>
            <Button
              onClick={() => {
                resetLayout();
              }}
              className="btn-sm btn-danger"
            >
              {t("reset-layout")}
            </Button>
          </>
        )}
      </div>
      {editable && (
        <div className="d-flex">
          <Button onClick={showDrawer} className="btn-sm">
            {t("toggle-drawer")}
          </Button>
        </div>
      )}
    </div>
  )
}

export default DashboardControls
