import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field as BaseField, List } from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import DateInput from "../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Select from "../../../../components/Inputs/Select";
import Text from "../../../../components/Inputs/Text";
import { getSettingsResourceSearchFilters } from "../../../../utils/helpers/settings";
import { getCompaniesSearchFilters } from "../../../Profile/Personal/Actions/ChangeCompanyInformation";
import { renderLabel } from "../../../Reports/CustomReports/CreateReport/ReportDispaly/useColumns";
import { useFilterFields } from "../../api";

const Filter = ({ setFormData }) => {
  const { t } = useTranslation();

  const { filterFields, getFilterFields, loading } = useFilterFields();

  useEffect(() => {
    const controller = new AbortController();
    getFilterFields(controller);
    return () => {
      controller.abort();
    };
  }, [getFilterFields]);

  const input = useCallback(
    (field) => {
      if (field?.type === "RESOURCE-SELECT") {
        const endpoint =
          field?.endpoint === "/companies"
            ? "/companies?pagination=false"
            : field?.endpoint;

        return (
          <ResourceSelect
            placeholder={t("value")}
            resourcePath={endpoint}
            renderLabel={(item) =>
              renderLabel(field?.display_name || field?.name, item)
            }
            mode={field?.type === "RESOURCE-SELECT" ? " multiple" : "single"}
            getSearchFilters={
              field?.endpoint === "/companies"
                ? getCompaniesSearchFilters
                : getSettingsResourceSearchFilters
            }
            hasSearch
          />
        );
      } else if (field?.fieldType?.toLowerCase() === "select") {
        return (
          <Select>
            {field?.options?.map((option) => (
              <Select.Option value={option.value} key={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        );
      } else if (field?.fieldType?.toLowerCase() === "datepicker") {
        return <DateInput placeholderText={t("date")} />;
      } else if (field?.type === "INTEGER") {
        return <Text type="number" placeholder={t("value")} />;
      } else if (field?.type === "STRING") {
        return <Text type="text" placeholder={t("value")} />;
      } else {
        return <Text placeholder={t("value")} />;
      }
    },
    [t]
  );

  return loading ? null : (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, getFieldsError, setFieldValue }) => {
        const dimensions = getFieldValue("dimensions") || [];
        const types = dimensions?.map(d => d?.type) || [];

        return (
          <List name="filters">
            {(data, { add, remove }) => {
              return (
                <div className="p-3 border rounded">
                  <h5 className="font-weight-bold">
                    {t("filter")} {" ("}{data.length}{")"}
                  </h5>
                  <p className="text-muted mb-3">{t("filter-description")}</p>

                  {data?.map(({ key, name }, index) => {
                    const selectedType = getFieldValue(["filters", name, "type"]);

                    const selectedTypeData = filterFields.find(f => f.name === selectedType);
                    const selectedFields = selectedTypeData?.fields?.filter(
                      (f) => f.canFilter
                    ) || [];

                    const [error] = getFieldsError([
                      ["filters", name, "name"],
                      ["filters", name, "values"],
                    ]).map(({ errors }) => errors).flat();

                    const [logicError] = getFieldsError([["filters", name, "logic"]]).map(({ errors }) => errors).flat();

                    return (
                      <div key={key}>
                        <div className="d-flex flex-column mb-2">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <BaseField
                              name={[name, "type"]}
                              rules={[{
                                required: true,
                                message: t("required-type"),
                              }]}
                            >
                              <Select
                                placeholder={t("select-type")}
                                showSearch
                                onChange={() => {
                                  setFormData(prev => ({
                                    ...prev,
                                    filters: prev?.filters?.map((dim, index) => {
                                      if (index === name) {
                                        return {
                                          ...dim,
                                          name: undefined,
                                          values: "",
                                        };
                                      }
                                      return dim;
                                    })
                                  }));
                                }}
                              >
                                {filterFields
                                  .filter(fieldOption => types?.includes(fieldOption.table))
                                  .map(fieldOption => (
                                    <Select.Option key={fieldOption.name} value={fieldOption.name}>
                                      {fieldOption?.display_name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </BaseField>

                            {selectedType && (
                              <BaseField
                                name={[name, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("required-field"),
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={t("select-field")}
                                  showSearch
                                  onChange={
                                    (value) => {
                                      const selected = selectedFields?.find(f => `${selectedType}.${f.name}` === value);

                                      setTimeout(() => {
                                        setFieldValue(
                                          ["filters", name, "operator"],
                                          (selected?.type === "RESOURCE-SELECT" || selected?.fieldType === "select" || selected?.fieldType === "resource-select" || selected?.type === "select" || selected?.fieldType === "datepicker") ? "exact" : "contains"
                                        )
                                      }, 100);

                                      setFormData(prev => ({
                                        ...prev,
                                        filters: prev?.filters?.map((dim, index) => {
                                          if (index === name) {
                                            return {
                                              ...dim,
                                              values: "",
                                            };
                                          }
                                          return dim;
                                        })
                                      }));
                                    }
                                  }
                                >
                                  {selectedFields?.map(fieldOption => (
                                    <Select.Option
                                      key={fieldOption.name}
                                      value={`${selectedType}.${fieldOption.name}`}
                                    >
                                      {fieldOption.display_name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </BaseField>
                            )}

                            <BaseField name={[name, "operator"]} />

                            <BaseField shouldUpdate noStyle>
                              {(_, __, { getFieldValue }) => {
                                const value = getFieldValue(["filters", name]);
                                const field = filterFields
                                  ?.find((f) => f?.table === value?.type)
                                  ?.fields?.find((f) => f?.name === value?.name?.split(".")[1]);

                                return (
                                  <BaseField
                                    className="w-100"
                                    name={[name, "values"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: t("required"),
                                      },
                                    ]}
                                  >
                                    {input(field)}
                                  </BaseField>
                                );
                              }}
                            </BaseField>

                            <Button color="outline-danger" onClick={() => {
                              remove(name);
                            }}>
                              <FontAwesomeIcon icon={faTrashCan} />
                            </Button>
                          </div>

                          <div>{error && <span className="text-danger">{error}</span>}</div>
                        </div>

                        {data.length > 1 && index !== data.length - 1 && (
                          <div className="d-flex flex-column gap-2 mb-2 w-25">
                            <BaseField
                              name={[name, "join"]}
                              rules={[{
                                required: true,
                                message: t("required-logic"),
                              }]}
                            >
                              <Select placeholder={t("select-logic")}>
                                <Select.Option value="AND">{t("and")}</Select.Option>
                                <Select.Option value="OR">{t("or")}</Select.Option>
                              </Select>
                            </BaseField>

                            <div>{logicError && <span className="text-danger">{logicError}</span>}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <Button
                    color="outline-info"
                    onClick={() => {
                      add();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {t("add-filter")}
                  </Button>
                </div>
              );
            }}
          </List>
        );
      }}
    </BaseField>
  );
};

export default Filter;

