import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import { mutate as unboundMutate } from "swr";
import { TableContext } from "../../../components/Table/context";
import { useElementHeight } from "../../../utils/hooks/useElementHeight";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useConfigColumns } from "../../Settings/Configuration/hooks/useConfigColumns";
import { useConfigurationModule } from "../../Settings/Configuration/hooks/useConfigurationModule";
import { useSyncColumnVisibilityWithConfig } from "../../Settings/Configuration/hooks/useSyncColumnVisibilityWithConfig";
import { useGroups } from "../../Settings/General/OrganisationLevelGroups/context";
import Summary from "../Home/Actions/Summary";
import { useWeeklyTeam } from "../api/useWeeklyTeam";
import CancelModal from "../components/TimeSheetTable/CancelModal";
import { useOverview } from "../components/Topbar/context";
import { useFilters } from "../filtersContext";
import { useBindPayGroupWithTable } from "../hooks/useBindPayGroupWithTable";
import { useDateFilters } from "../hooks/useDateFilters";
import Actions from "./Actions";
import { useCopyTimeSheet } from "./Actions/useCopyTimeSheet";
import ProfileModal from "./ProfileDrawer";
import Table from "./Table";
import { useTeamTable } from "./Table/hooks/useTable";
import { useColumns } from "./Table/useColumns";
import { useBulkActions } from "./hooks/useBulkActions";
import { getUserTimeSheetsSWRKey } from "./hooks/useFetchUserTimeSheets";
import { useSummary } from "./hooks/useSummary";

const Content = () => {
  const hideUserProfile = useConfigurationModule(
    "timeSheetWeekly.hideUserProfile"
  );
  const { groups } = useGroups();
  const groupWithBudget = useMemo(
    () => groups?.find(({ hasBudget }) => hasBudget),
    [groups]
  );

  const { ref: actionsRef, height: actionsHeight } = useElementHeight();

  const { from, to, mode, payGroup } = useDateFilters();
  const [filters, changeFilters] = useFilters();
  const [state, setState] = useState();
  const [expandedUsers, setExpandedUsers] = useState([]);
  const tableFilters = useMemo(
    () => ({
      filters,
      ...state,
    }),
    [filters, state]
  );

  const refetchExpandedUsers = useCallback(() => {
    return Promise.all(
      expandedUsers.map((user) => {
        return unboundMutate(getUserTimeSheetsSWRKey(user));
      })
    );
  }, [expandedUsers]);

  const { fetch: baseFetchOverview } = useOverview();

  const {
    data: { result: data, totalItems },
    setData,
    isLoading: loadingUsers,
    mutate,
  } = useWeeklyTeam(
    state && {
      from,
      to,
      filters,
      ...state,
    }
  );

  const {
    visible: isProfileVisible,
    open: openProfile,
    selected: selectedUser,
    close: closeProfile,
  } = useVisible();

  const {
    visible: isCancelModalVisible,
    open: openCancelModal,
    selected: cancelCallback,
    close: closeCancelModal,
  } = useVisible();

  const fetchOverview = useCallback(
    (config) => {
      baseFetchOverview({
        url: "/time-sheet/weekly/team/overview",
        ...config,
        params: {
          ...config.params,
          budgetBy:
            groupWithBudget && `${groupWithBudget.level}`.replace("_", ""),
        },
      });
    },
    [baseFetchOverview, groupWithBudget]
  );

  const refetchOverview = useCallback(() => {
    fetchOverview({
      params: { from, to, filters: filters.filters },
    });
  }, [fetchOverview, from, to, filters]);

  const refetchUsers = useCallback(() => {
    return mutate();
  }, [mutate]);

  const { approve, unapprove, lock, unlock } = useBulkActions({
    refetch: useCallback(() => {
      return Promise.all([
        refetchOverview(),
        refetchUsers(),
        refetchExpandedUsers(),
      ]);
    }, [refetchOverview, refetchUsers, refetchExpandedUsers]),
    filters: tableFilters,
  });

  const {
    visible: summaryVisible,
    selected: summaryData,
    openDaySummary,
    openAllSummary,
    close: closeSummary,
  } = useSummary();

  const [openCopyModal, copyModal] = useCopyTimeSheet({
    filters: useMemo(() => {
      return {
        startDate: moment(from).format("YYYY-MM-DD"),
        endDate: moment(to).format("YYYY-MM-DD"),
      };
    }, [from, to]),
    refetch: useCallback(() => {
      return Promise.all([
        refetchOverview(),
        refetchUsers(),
        refetchExpandedUsers(),
      ]);
    }, [refetchOverview, refetchUsers, refetchExpandedUsers]),
  });

  const columns = useColumns({
    viewSummary: openAllSummary,
    viewProfile: !hideUserProfile && openProfile,
  });

  const table = useTeamTable({
    columns: useConfigColumns("timeSheetWeekly", columns),
    data,
    setData,
    totalItems,
    refetchOverview,
    refetchUsers,
    openCancelModal,
  });

  useEffect(() => {
    setExpandedUsers(Object.keys(table.state.expanded));
  }, [table.state.expanded, setExpandedUsers]);

  useSyncColumnVisibilityWithConfig({
    table,
    configuration: "timeSheetWeekly",
    userPreferences: "timeSheetWeekly",
  });

  const { pageIndex, pageSize, sortBy } = table.state;

  useEffect(() => {
    setState({ pageIndex, pageSize, sortBy });
  }, [pageIndex, pageSize, sortBy]);

  useEffect(() => {
    const controller = new AbortController();
    fetchOverview({
      params: {
        from,
        to,
        filters,
      },
      signal: controller.signal,
    });
    return () => controller.abort();
  }, [fetchOverview, from, to, filters]);

  useBindPayGroupWithTable({
    filters,
    setFilters: changeFilters,
    mode,
    payGroup,
  });

  return (
    <TableContext.Provider value={table}>
      <Card innerRef={actionsRef} className="mb-0 position-static">
        <CardHeader className="border-top p-3">
          <Actions
            approve={approve}
            unapprove={unapprove}
            lock={lock}
            unlock={unlock}
            copy={openCopyModal}
          />
        </CardHeader>
      </Card>

      <div style={{ maxHeight: `calc(100% - ${actionsHeight}px)` }}>
        <Table
          loading={loadingUsers}
          openDaySummary={openDaySummary}
          openCopyModal={openCopyModal}
        />
      </div>

      {isProfileVisible && (
        <ProfileModal
          user={selectedUser}
          visible={isProfileVisible}
          close={closeProfile}
        />
      )}

      {summaryVisible && (
        <Summary
          open={summaryVisible}
          close={closeSummary}
          user={summaryData?.user}
          range={summaryData?.range}
          filters={tableFilters?.filters}
        />
      )}

      {copyModal}

      <CancelModal
        isOpen={isCancelModalVisible}
        close={closeCancelModal}
        reset={cancelCallback}
      />
    </TableContext.Provider>
  );
};

export default Content;
