import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../components/Field";
import DateInput from "../../../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import LevelSelect from "../../../../../TimeSheet/components/LevelSelect";
import ObjectSelect from "./ObjectSelect";
import Sections from "./Sections";
import Progress from "./Progress";
import TimeInputs from "./TimeInputs";
import {
    renderSettingsResourceLabel,
    renderOrgLevelResourceLabel
} from "../../../../../../utils/helpers/settings";
import { getSettingsResourceSearchFilters } from "../../../../../../utils/helpers/settings";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { getCommonJobs } from "../../../helpers/getCommonJobs";
import { getCommonLocations } from "../../../helpers/getCommonLocations";
import { getHolidays } from "../../../helpers/getHolidays";

function Content({ form, eventRecord, resource, defaultSection, addAnotherEvent, isProfile = true }) {
    const { t } = useTranslation();
    const { allocatedGroups } = useGroups();
    const startDate = Form.useWatch("startDate", form);
    const jobs = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonJobs([resource]);
    }, [resource]);

    const holidays = useMemo(() => {
        if (!resource) {
            return []
        }
        return getHolidays(resource, startDate);
    }, [resource, startDate]);

    const locations = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonLocations([resource]);
    }, [resource]);

    return (
        <>
            <Field
                className="w-100 d-flex flex-column"
                name="date"
                label={t("shift-date")}
                rules={[
                    {
                        required: true,
                        message: t("required-start-date")
                    }
                ]}
            >
                <DateInput
                    className="w-100"
                    readOnly={true}
                    autocomplete="off"
                    placeholderText={t("shift-date")}
                />
            </Field>

            <BaseField name="resourceId" />

            {holidays?.length > 0 && (
                <div
                    className="w-100 border border-warning p-3 rounded mb-4 text-warning"
                    style={{
                        color: "#fff5e6"
                    }}
                >
                    <FontAwesomeIcon icon={faWarning} className="mr-3" />

                    {t("today-holiday", {
                        holiday: holidays.length === 1
                            ? holidays[0].name
                            : holidays.map((holiday) => holiday.name + ", ")
                    })}
                </div>
            )}

            <Field
                name="eventType"
                label={t("event-type")}
                rules={[
                    {
                        required: true,
                        message: t("required-event-type")
                    }
                ]}
            >
                <ResourceSelect
                    placeholder={t("event-type")}
                    labelPropName="description"
                    resourcePath="/earning-group?forSchedule=true"
                    mode="single"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    valuePropName="id"
                    hasSearch
                    disabled={true}
                />
            </Field>

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const eventType = getFieldValue("eventType");
                    const filter = `filter[earningGroup][method]=exact
							&filter[earningGroup][value][]=${eventType?.id}
							&forSchedule=true&withoutLookups=true`;
                    return !eventType?.allowSegmentation && (
                        <Field
                            name="payCode"
                            label={t("pay-code")}
                            rules={[
                                {
                                    required: true,
                                    message: t("required-pay-code")
                                }
                            ]}
                        >
                            <ResourceSelect
                                labelPropName="description"
                                renderLabel={renderSettingsResourceLabel}
                                valuePropName="code"
                                placeholder={t("pay-code")}
                                hasSearch
                                getSearchFilters={getSettingsResourceSearchFilters}
                                resourcePath={`/pay-code?${filter}`}
                            />
                        </Field>
                    );
                }}
            </BaseField>

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const eventType = getFieldValue("eventType");
                    return eventType?.allowSegmentation && (
                        <Progress />
                    );
                }}
            </BaseField>

            <TimeInputs form={form} isProfile={isProfile} />

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const eventType = getFieldValue("eventType");
                    return eventType?.allowSegmentation ? (
                        <Sections
                            form={form}
                            defaultSection={defaultSection}
                            resource={resource}
                            eventRecord={eventRecord}
                            addAnotherEvent={addAnotherEvent}
                            isProfile={isProfile}
                        />
                    ) : <>
                        <BaseField name="sections" />

                        {locations.length > 0 && (
                            <Field
                                name="location"
                                label={t("location")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-location"),
                                    },
                                ]}
                            >
                                <ObjectSelect propValue="id" options={locations} />
                            </Field>
                        )}

                        {jobs.length > 0 && (
                            <Field
                                name="job"
                                label={t("job")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-job"),
                                    },
                                ]}
                            >
                                <ObjectSelect propValue="id" options={jobs} />
                            </Field>
                        )}

                        {allocatedGroups?.map((group) => (
                            <BaseField shouldUpdate>
                                {({ }, { }, { getFieldValue }) => {
                                    const location = getFieldValue("location");
                                    return (
                                        <Field
                                            key={group.id}
                                            name={group?.level?.replace("_", "")}
                                            label={renderOrgLevelResourceLabel(group)}
                                            rules={[
                                                {
                                                    required: group?.isRequired,
                                                    message: t("required-group", {
                                                        group: renderOrgLevelResourceLabel(group)
                                                    })
                                                }
                                            ]}
                                        >
                                            <LevelSelect
                                                placeholder={renderOrgLevelResourceLabel(group)}
                                                group={group.id}
                                                location={location}
                                                allowClear={!group.isRequired}
                                            />
                                        </Field>
                                    );
                                }}
                            </BaseField>
                        ))}
                    </>
                }}
            </BaseField>
        </>
    );
}

export default Content;
