import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useApi } from "../../utils/api";
import { getErrorMessage } from "../../utils/helpers/errors";

export const useReports = () => {
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [itemsCache, setItemsCache] = useState({});

  const { authGet, authPost, authPatch, authDelete } = useApi();

  const getReports = useCallback(
    async (params, controller) => {
      setLoading(true);
      try {
        const endpoint = !params.parentId
          ? `/reports/v2/parent/root`
          : `/reports/v2/parent/${params.parentId}`;

        const response = await authGet(endpoint, {
          params,
          signal: controller?.signal,
        });

        if (response) {
          setData(response.result);
          setTotalItems(response.totalItems);
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        setLoading(false);
      }
    },
    [authGet]
  );

  const createFolder = useCallback(async (folderData, onSuccess, onError) => {
    setLoading(true);
    try {
      const response = await authPost(`/reports/v2`, { data: folderData });
      if (response) {
        const parentIdKey = folderData.parentId || 'root';
        setItemsCache(prevItemsCache => ({
          ...prevItemsCache,
          [parentIdKey]: [response, ...(prevItemsCache[parentIdKey] || [])],
        }));
        onSuccess?.(response);
      }
    } catch (error) {
      onError?.(error);
    } finally {
      setLoading(false);
    }
  }, [authPost]);

  const updateReportParent = useCallback(
    async (id, parentId, onSuccess, onError) => {
      setLoading(true);
      try {
        const response = await authPatch(`/reports/v2/${id}`, {
          data: { parentId },
        });
        if (response) {
          setData(prev =>
            prev.map(item => (item.id === id ? { ...item, parentId } : item))
          );
          onSuccess?.(response);
        }
      } catch (error) {
        onError?.(error);
      } finally {
        setLoading(false);
      }
    },
    [authPatch]
  );

  const getFolderById = useCallback(
    async (id, controller) => {
      try {
        const response = await authGet(`/reports/v2/${id}`, {
          signal: controller?.signal,
        });
        if (response) {
          return response;
        }
      } catch (error) {
        console.error("Error fetching folder:", error);
        throw error;
      }
    },
    [authGet]
  );

  const deleteFolder = useCallback(async (folderId, onSuccess, onError) => {
    const previousData = data;
    setData(prev => prev.filter(item => item.id !== folderId));

    try {
      await authDelete(`/reports/v2/${folderId}`);

      const deletedFolder = previousData.find(item => item.id === folderId);
      if (deletedFolder) {
        const parentIdKey = deletedFolder.parentId || 'root';
        setItemsCache(prevItemsCache => {
          const updatedItems = (prevItemsCache[parentIdKey] || []).filter(
            item => item.id !== folderId
          );
          return {
            ...prevItemsCache,
            [parentIdKey]: updatedItems,
          };
        });
      }

      onSuccess?.();
    } catch (error) {
      setData(previousData);
      onError?.(error);
    }
  }, [authDelete, data]);

  const deleteReport = useCallback(async (reportId, onSuccess, onError) => {
    const previousData = data;
    setData(prev => prev.filter(item => item.id !== reportId));

    try {
      await authDelete(`/reports/v2/${reportId}`);

      const deletedReport = previousData.find(item => item.id === reportId);
      if (deletedReport) {
        const parentIdKey = deletedReport.parentId || 'root';
        setItemsCache(prevItemsCache => {
          const updatedItems = (prevItemsCache[parentIdKey] || []).filter(
            item => item.id !== reportId
          );
          return {
            ...prevItemsCache,
            [parentIdKey]: updatedItems,
          };
        });
      }

      onSuccess?.();
    } catch (error) {
      setData(previousData);
      onError?.(error);
    }
  }, [authDelete, data]);

  const updateFolderName = useCallback(async (folderId, newName, onSuccess, onError) => {
    setLoading(true);

    try {
      await authPatch(`/reports/v2/${folderId}`, {
        data: { name: newName },
      });

      setData(prev =>
        prev.map(item => (item.id === folderId ? { ...item, name: newName } : item))
      );

      const folder = data.find(item => item.id === folderId);
      if (folder) {
        const parentIdKey = folder.parentId || 'root';

        setItemsCache(prevItemsCache => {
          const updatedItems = (prevItemsCache[parentIdKey] || []).map(item =>
            item.id === folderId ? { ...item, name: newName } : item
          );
          return {
            ...prevItemsCache,
            [parentIdKey]: updatedItems,
          };
        });
      }

      onSuccess?.();
    } catch (error) {
      onError?.(error);
    } finally {
      setLoading(false);
    }
  }, [authPatch, data]);

  const editReport = useCallback(
    async (reportId, reportData, onSuccess, onError) => {
      try {
        const response = await authPatch(`/reports/v2/${reportId}`, {
          data: reportData,
        });
        if (response) {
          setData(prev => prev.map(item => (item.id === reportId ? response : item)));
          onSuccess?.(response);
        }
      } catch (error) {
        onError?.(error);
      }
    },
    [authPatch]
  );

  const getItemsByParentId = useCallback(async (parentId, controller) => {
    try {
      const endpoint = !parentId ? `/reports/v2/parent/root` : `/reports/v2/parent/${parentId}`;
      const response = await authGet(endpoint, { signal: controller?.signal });
      if (response) {
        const sortedItems = response.result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setItemsCache(prevItemsCache => ({
          ...prevItemsCache,
          [parentId || 'root']: sortedItems,
        }));
        return sortedItems;
      }
    } catch (error) {
      console.error("Error fetching items:", error);
      return [];
    }
  }, [authGet]);

  return {
    getReports,
    createFolder,
    updateReportParent,
    getFolderById,
    deleteFolder,
    deleteReport,
    updateFolderName,
    editReport,
    getItemsByParentId,
    setItemsCache,
    setData,
    itemsCache,
    data,
    totalItems,
    loading,
  };
};

export const useFields = () => {
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const { authGet } = useApi();

  const getFields = useCallback(
    async (controller) => {
      setLoading(true);
      try {
        const response = await authGet(`/reports/v2/fields`, {
          signal: controller?.signal
        });
        if (response) {
          setFields(response);
        }
      } catch (error) {

      } finally {
        setLoading(false);
      }
    },
    [authGet]
  );

  return {
    getFields,
    fields,
    loading
  }
}

export const useFilterFields = () => {
  const [filterFields, setFilterFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const { authGet } = useApi();

  const getFilterFields = useCallback(
    async (controller) => {
      setLoading(true);
      try {
        const response = await authGet(`/reports/v2/filter-fields`, {
          signal: controller?.signal
        });
        if (response) {
          setFilterFields(response);
        }
      } catch (error) {

      } finally {
        setLoading(false);
      }
    },
    [authGet]
  );

  return {
    getFilterFields,
    filterFields,
    loading
  }
}

export const useCreateReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { authPost } = useApi();

  const createReport = useCallback(
    async (data, onSuccess, controller) => {
      setLoading(true);
      try {
        const response = await authPost(`/reports/v2`, {
          data,
          signal: controller?.signal
        });
        if (response) {
          setData(response);
          onSuccess?.(response);
        }
      } catch (error) {

      } finally {
        setLoading(false);
      }
    },
    [authPost]
  );

  return {
    createReport,
    data,
    loading
  }
}

export const useGenerateReport = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { authPost } = useApi();

  const generateReport = useCallback(
    async (params, controller) => {
      setLoading(true);
      setError(null);
      try {
        const response = await authPost(
          `/reports/v2/generate`,
          { data: params },
          { signal: controller?.signal }
        );
        if (response) {
          setData(response.data);
          setTotalItems(response.totalItems);
        }
      } catch (error) {
        if (error === "Request Timed Out") {
          setError(error);
          console.error("Error fetching report:", error);
          toast.error(
            t("could-not-load-report-data")
          )
        }
      } finally {
        setLoading(false);
      }
    },
    [authPost, t]
  );

  const generate = useCallback(async (data, controller) => {
    setLoading(true);
    try {
      const response = await authPost("/reports/v2/generate-preview", {
        data,
        signal: controller?.signal
      });
      if (response) {
        setData(response.data);
        setTotalItems(response.totalItems);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [authPost])

  return {
    generateReport,
    generate,
    data,
    totalItems,
    loading,
    error,
  };
};

export const useGetReport = () => {
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);

  const { authGet } = useApi();

  const getReport = useCallback(async (id, controller) => {
    setLoading(true);
    try {
      const response = await authGet(`/reports/v2/${id}`, {
        signal: controller?.signal
      });
      if (response) {
        setReport(response);
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }, [authGet]);

  return {
    getReport,
    report,
    loading
  }
}

export const useExportReport = () => {
  const [loading, setLoading] = useState(false);
  const { authPost } = useApi();
  const { t } = useTranslation();

  const downloadReportFromUrl = useCallback(async (url) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to download report: ${response.statusText}`);
      }

      const blob = await response.blob();
      const filename = "report.xlsx";

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading report:", error);
      toast.error("Could not download report. Please try again later.");
    }
  }, []);

  const exportReport = useCallback(
    async (data) => {
      setLoading(true);
      const toastId = toast.loading(t("report-exporting"));

      try {

        const response = await authPost(
          `/reports/v2/export`,
          { data },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response?.url) {
          await downloadReportFromUrl(response.url);
          toast.update(toastId, {
            render: t("report-exported-successfully"),
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });
          return { status: 200 };
        } else {
          throw new Error("No valid report URL found in response");
        }
      } catch (error) {
        toast.update(toastId, {
          render: t("could-not-export-report"),
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      } finally {
        setLoading(false);
      }
    },
    [authPost, downloadReportFromUrl, t]
  );

  return {
    exportReport,
    loading,
  };
};

export const useExportApi = () => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [totalItems, setTotalItems] = useState();

  const { authGet } = useApi();

  const getExports = useCallback(async (params, controller) => {
    try {
      const response = await authGet("/reports/v2/exported", {
        params,
        signal: controller?.signal
      });

      if (response) {
        setData(response.result);
        setTotalItems(response.totalItems);
      }

    } catch (error) {
      getErrorMessage(error, t)
    }

  }, [authGet, t])

  return {
    getExports,
    data,
    totalItems,
  }
}
