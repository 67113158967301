import classNames from "classnames";
import { isEqual } from "lodash";
import moment from "moment-timezone";
import Form, { List } from "rc-field-form";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useFilterFields } from "../../api";
import "./_style.scss";
import Filter from "./Filter";
import Toggle from "./Toggle";

export const initialFilters = [{ member: "", operator: "", values: "" }];

export const isFilterValid = ({ member, operator, values }) => {
  if (Array.isArray(values) && values.length === 0) {
    return false;
  }
  return !!member && !!operator && ![undefined, null, ""].includes(values);
};

function Filters({ report, columns, filters, setFilters }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { visible, toggle } = useVisible(false);
  const list = Form.useWatch("filters", form);

  const { filterFields, getFilterFields } = useFilterFields();

  // const config = useMemo(() => {
  //   let cols = columns;
  //   const dimensions = filterFields?.map((filter) => filter?.fields?.map((f) => ({ ...f, table: filter?.name })))?.flat();
  //   if (report?.reportData?.settings?.customFilters?.length > 0) {
  //     const customFilters = report?.reportData?.settings?.customFilters?.map((filter) => filter?.name);
  //     cols = dimensions?.filter((filter) => customFilters?.includes(`${filter?.table}.${filter?.name}`));

  //   } else {
  //     const columns = cols?.map((filter) => filter?.name);
  //     cols = dimensions?.filter((filter) => columns?.includes(`${filter?.table}.${filter?.name}`));
  //   }

  //   return cols?.map((col) => ({
  //     ...col,
  //     label: t((col?.name).replaceAll(/[_\\.]/g, " ")),
  //   }))
  // }, [columns, filterFields, report?.reportData?.settings?.customFilters, t]);

  const config = useMemo(() => {
    const columnTypes = report?.reportData?.columns?.map((item) =>
      item?.name?.split(".")[0]
    );

    const fields = filterFields?.reduce((total, current) => {
      if (columnTypes?.includes(current?.name)) {
        const fields = current?.fields?.map((field) => ({ ...field, table: current?.name }));
        total.push(fields);
      }
      return total.flat();
    }, []);

    return fields?.filter((field) => field?.canFilter);
  }, [filterFields, report?.reportData?.columns]);

  const hasChange = useMemo(() => {
    return !isEqual(filters, list);
  }, [filters, list]);

  const areValid = useMemo(() => {
    return list?.every(isFilterValid);
  }, [list]);

  const changeFieldMethod = useCallback(
    (name, operator) => {
      form.setFieldValue(["filters", name, "operator"], operator);
    },
    [form]
  );

  const changeFieldValue = useCallback(
    (name, value) => {
      form.setFieldValue(["filters", name, "values"], value);
    },
    [form]
  );

  const onFinish = useCallback(
    (values) => {
      setFilters(values.filters);
    },
    [setFilters]
  );

  const clearFilters = useCallback(() => {
    if (!isEqual(filters, initialFilters)) {
      setFilters(initialFilters);
    }
    form.setFieldValue("filters", initialFilters);
  }, [setFilters, form, filters]);

  useEffect(() => {
    const data = filters?.map((filter) => {
      const matchingItem = config?.find((item) => `${item?.table}.${item?.name}` === filter?.member);

      if (matchingItem) {
        return {
          ...filter,
          values: (
            (!matchingItem?.fieldType || matchingItem?.fieldType === "text") &&
            filter?.values &&
            Array.isArray(filter?.values)
          )
            ? moment(filter?.values?.[0], "YYYY-MM-DD", true).isValid() ? moment(filter?.values?.[0]).toDate() : filter?.values
            : moment(filter?.values, "YYYY-MM-DD", true).isValid() ? moment(filter?.values).toDate() : filter?.values,
        }
      } else {
        return {
          ...filter,
          values: (
            filter?.values &&
            Array.isArray(filter?.values)
          )
            ? moment(filter?.values?.[0], "YYYY-MM-DD", true).isValid() ? moment(filter?.values?.[0]).toDate() : filter?.values
            : moment(filter?.values, "YYYY-MM-DD", true).isValid() ? moment(filter?.values).toDate() : filter?.values,
        }
      }
    });

    form.setFieldValue("filters", data);
  }, [form, config, filters]);

  useEffect(() => {
    const controller = new AbortController();
    getFilterFields(controller);
    return () => controller.abort();
  }, [getFilterFields]);

  return (
    <Form
      initialValues={{ filters }}
      form={form}
      onFinish={onFinish}
      className="table-filters d-flex align-items-start justify-content-end w-100 my-3"
    >
      <div className={classNames("responsive-filters", visible ? "d-flex flex-column" : "d-none")}>
        <div className="d-flex flex-column gap-y-4 px-0 w-100">
          <List name="filters">
            {(fields, { add, remove }) =>
              fields.map(({ name, key }) => (
                <Filter
                  changeFieldMethod={changeFieldMethod}
                  changeFieldValue={changeFieldValue}
                  config={config}
                  name={name}
                  key={key}
                  form={form}
                  add={() => add({}, name + 1)}
                  remove={() => remove(name)}
                />
              ))
            }
          </List>
        </div>

        <div className="d-flex mt-3">
          <Button
            color="primary"
            size="sm"
            className="mr-2"
            disabled={!hasChange || !areValid}
          >
            {t("search")}
          </Button>
          <Button
            color="secondary"
            size="sm"
            onClick={clearFilters}
            disabled={!hasChange && isEqual(list, initialFilters)}
          >
            {t("clear-filters")}
          </Button>
        </div>
      </div>
      <div className="ml-3">
        <Toggle open={visible} toggle={toggle} />
      </div>
    </Form>
  );
}

export default Filters;
