import { useTranslation } from "react-i18next";

const EditControls = ({ item, handleHideCard, toggleStaticCard, staticCards, editable }) => {
  const { t } = useTranslation();
  if (!editable) return null;

  return (
    <div className="edit-controls">
      <div className="edit-controls-buttons">
        <span
          onMouseDown={(e) => e.stopPropagation()}
          onClick={() => handleHideCard(item.i)}
          disabled={!editable}
          className="btn-sm"
        >
          {t("hide")}
        </span>
        <span
          onMouseDown={(e) => e.stopPropagation()}
          onClick={() => toggleStaticCard(item.i)}
          disabled={!editable}
          className="btn-sm"
        >
          {staticCards[item.i] ? t("unlock") : t("lock")}
        </span>
      </div>
    </div>
  )
}

export default EditControls;
