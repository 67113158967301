import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useModal } from "../../../../../utils/hooks/useModal";
import { useDeleteEvents } from "../api/useDeleteEvents";

const icon = (
    <FontAwesomeIcon
        icon={faCircleXmark}
        className="text-danger w-100 mb-5"
        fontSize={70}
    />
);

export const useDeleteModal = ({ mutate, params, isTeam, setSelectedEvents }) => {
	const { t } = useTranslation();
	const { remove, removeUserEvents, bulkRemove } = useDeleteEvents({ isTeam });

	const head = useMemo(() => (
        <h2 className="mb-0 text-center font-weight-bolder">
            {t("are-you-sure")}
        </h2>
    ), [t]);

	const onConfirm = useCallback((value) => {
		if (value?.type === "cell") {
			const id = value?.event?.eventRecords?.[0]?.id;
			remove(id, mutate);
		} else if (value?.type === "row") {
			const data = {
				day: params?.day,
				filters: params
			}
			removeUserEvents(value?.userId, data, mutate);
		} else if(value?.type === "bulk") {
			const data = {
				day: params?.day,
				filters: params,
				ids: value?.ids
			}
			bulkRemove(data, mutate)
		}
		setSelectedEvents([]);
	}, [remove, removeUserEvents, bulkRemove, setSelectedEvents, mutate, params]);

	const { open, modal } = useModal({
		icon,
		head,
		color: "danger",
		message: t("do-you-want-to-delete-event"),
		onConfirm
	});

	return { open, modal };
}