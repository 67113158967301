import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field as BaseField, List } from "rc-field-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import Select from "../../../../components/Inputs/Select";
import { useFields } from "../../api";

const Unions = ({ setFormData }) => {
  const { t } = useTranslation();

  const { fields, getFields, loading } = useFields();

  useEffect(() => {
    const controller = new AbortController();
    getFields(controller);
    return () => controller.abort();
  }, [getFields]);

  return loading ? null : (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, getFieldsError }
      ) => {
        const dimensions = getFieldValue("dimensions") || [];
        const isAddDisabled = dimensions.length === 0;

        const selectedDimensionTypes = (dimensions || [])
          .map(dim => dim?.type)
          .filter(Boolean);

        const allowedFieldTypes = new Set(selectedDimensionTypes);

        return (
          <List name="unions">
            {(data, { add, remove }) => (
              <div className="p-3 border rounded">
                <h5 className="font-weight-bold">
                  {t("unions")} {" ("}{data.length}{")"}
                </h5>
                <p className="text-muted mb-3">{t("unions-description")}</p>

                {data?.map(({ key, name }) => {
                  const [error] = getFieldsError([
                    ["unions", name, "name"],
                    ["unions", name, "type"],
                  ]).map(({ errors }) => errors).flat();

                  const selectedFieldType = getFieldValue(["unions", name, "fieldType"]);
                  const selectedFieldTypeData = fields.find(f => f.table === selectedFieldType);
                  const selectedFields = selectedFieldTypeData?.fields || [];

                  return (
                    <div key={key} className="mb-3">
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <BaseField
                          name={[name, "fieldType"]}
                          rules={[{
                            required: true,
                            message: t("required-field-type"),
                          }]}
                        >
                          <Select
                            placeholder={t("select-field-type")}
                            showSearch
                            disabled={allowedFieldTypes.size === 0}
                            onChange={() => {
                              setFormData(prev => ({
                                ...prev,
                                unions: prev?.unions?.map((dim, index) => {
                                  if (index === name) {
                                    return {
                                      ...dim,
                                      name: undefined,
                                      type: undefined
                                    };
                                  }
                                  return dim;
                                })
                              }));
                            }}
                          >
                            {fields
                              .filter(fieldOption => allowedFieldTypes.has(fieldOption.table))
                              .map((fieldOption) => (
                                <Select.Option
                                  key={fieldOption.table}
                                  value={fieldOption.table}
                                >
                                  {fieldOption.display_name}
                                </Select.Option>
                              ))}
                          </Select>
                        </BaseField>

                        {selectedFieldType && (
                          <BaseField
                            name={[name, "name"]}
                            rules={[{
                              required: true,
                              message: t("required-union"),
                            },
                            {
                              validator: (_, value) => {
                                if (value) {
                                  const type = getFieldValue(["unions", name, "type"]);
                                  const selectedFieldType = getFieldValue(["unions", name, "fieldType"]);

                                  const field = fields
                                    ?.find(f => f.table === selectedFieldType)
                                    ?.fields
                                    ?.find(item => value === `${selectedFieldType}.${item.name}`);

                                  if (type && type !== "group" && field?.type !== "INTEGER") {
                                    return Promise.reject(new Error(
                                      t("integer-field")
                                    ));
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              }
                            }
                            ]}
                          >
                            <Select
                              placeholder={t("select-union-field")}
                              showSearch
                              onChange={() => {
                                setFormData(prev => ({
                                  ...prev,
                                  unions: prev?.unions?.map((dim, index) => {
                                    if (index === name) {
                                      return {
                                        ...dim,
                                        type: undefined
                                      };
                                    }
                                    return dim;
                                  })
                                }));
                              }}
                            >
                              {selectedFields.map((fieldOption) => (
                                <Select.Option
                                  key={fieldOption.name}
                                  value={`${selectedFieldType}.${fieldOption.name}`}
                                >
                                  {fieldOption.display_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </BaseField>
                        )}

                        <BaseField
                          name={[name, "type"]}
                          rules={[{
                            required: true,
                            message: t("required-type"),
                          },
                          {
                            validator: (_, value) => {
                              if (value) {
                                const unions = getFieldValue("unions");
                                const unionsWithGroup = unions?.filter((union) => union?.type === "group");

                                if (value === "group" && unionsWithGroup?.length > 1) {
                                  return Promise.reject(new Error(
                                    t("union-with-group")
                                  ));
                                } else if (unions?.length < 2) {
                                  return Promise.reject(new Error(
                                    t("unions-length")
                                  ));
                                } else {
                                  return Promise.resolve();
                                }
                              }
                              return Promise.resolve();
                            }
                          }
                          ]}
                        >
                          <Select placeholder={t("select-union-type")} showSearch>
                            <Select.Option value="group">{t("group")}</Select.Option>
                            <Select.Option value="sum">{t("sum")}</Select.Option>
                            <Select.Option value="avg">{t("average")}</Select.Option>
                            <Select.Option value="count">{t("count")}</Select.Option>
                            <Select.Option value="max">{t("max")}</Select.Option>
                            <Select.Option value="min">{t("min")}</Select.Option>
                          </Select>
                        </BaseField>

                        <Button
                          color="outline-danger"
                          onClick={() => remove(name)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                      </div>

                      <div>
                        {(error) && (
                          <span className="text-danger">
                            {error}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}

                {data.length < 2 && (
                  <Button
                    color="outline-info"
                    className={`d-flex align-items-center gap-2 mt-3 ${isAddDisabled ? "disabled" : ""}`}
                    onClick={() => {
                      if (isAddDisabled) return;
                      add();
                    }}
                    disabled={isAddDisabled}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {t("union-add")}
                  </Button>
                )}
              </div>
            )}
          </List>
        );
      }}
    </BaseField>
  );
};

export default Unions;
