import {
	useContext,
	useState,
	useMemo,
	useCallback,
	useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import Button from "../../../../components/Button";
import List from "./List";
import Form from "./Form";
import { ProfileContext } from "../../context";
import { useAccess } from "../../../../utils/hooks/access";
import { createPortal } from "react-dom";
import Drawer from "../../../../components/Drawer";

const useCustomDrawer = (getChildren) => {
	const [wrapperElement, setWrapperElement] = useState(null);

	useLayoutEffect(() => {
		const element = document.getElementById("custom-drawer");
		setWrapperElement(element);
	}, [setWrapperElement]);

	if (wrapperElement === null) return null;

	return createPortal(getChildren(), wrapperElement);
};

function SecondaryJob() {
	const { t } = useTranslation();
	const { isSelf, user } = useContext(ProfileContext);
	const [mode, setMode] = useState(null);
	const [selected, setSelected] = useState();

	const { hasAccess } = useAccess(
		isSelf ? "profile.changeJobInformation" : "people.changeJobInformation"
	);

	const title = useMemo(() => {
		switch (mode) {
			case "view":
				return t("view-secondary-job");
			case "create":
				return t("add-secondary-job");
			case "edit":
				return t("edit-secondary-job");
			case "clone":
				return t("clone-secondary-job");
			default:
				return "";
		}
	}, [mode, t]);

	const cancel = useCallback(() => {
		setMode(null);
		setSelected(undefined);
	}, [setMode, setSelected]);

	const drawer = useCustomDrawer(() => {
		return (
			<Drawer
				title={title}
				open={mode !== null}
				close={cancel}
				style={{
					maxWidth: "initial",
				}}
			>
				<Form mode={mode} values={selected} close={cancel} />
			</Drawer>
		);
	});

	const canChangeJobInformation = !user?.isApi && hasAccess && user?.isMultipleJob;

	const addJob = useCallback(() => {
		setMode("create");
		setSelected(undefined);
	}, [setMode, setSelected]);

	const editJob = useCallback(
		(job) => {
			setSelected(job);
			setMode(canChangeJobInformation ? "edit" : "view");
		},
		[canChangeJobInformation, setMode, setSelected]
	);

	const cloneJob = useCallback(
		(job) => {
			setMode("clone");
			setSelected(job);
		},
		[setMode, setSelected]
	);

	return (
		<Row>
			<Col xs="6">
				<h6 className="heading-small text-muted mb-4">
					{t("secondary-job")}
				</h6>
			</Col>

			{canChangeJobInformation && (
				<Col xs="6" className="text-right">
					<Button
						className="border-dark px-3 btn-round btn-icon"
						color="none"
						onClick={addJob}
						size="sm"
					>
						{t("add-secondary-job")}
					</Button>
				</Col>
			)}

			<Col xs="12">
				<List 
					clone={cloneJob} 
					edit={editJob}
				 />
			</Col>

			{drawer}
		</Row>
	);
}

export default SecondaryJob;
