import { faEye, faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Button from "../../../../components/Button";

const ReportButtons = ({ formData, openPreviewModal }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <BaseField shouldUpdate>
      {(_, __, { getFieldsValue, getFieldValue }) => {
        const values = getFieldsValue();
        const dimension = values?.dimensions?.find((item) => !!item?.name);
        const hasValue = values?.name && dimension;
        const unions = getFieldValue("unions");

        return (
          <>
            <Button
              color="secondary"
              className="d-flex align-items-center"
              type="button"
              disabled={!hasValue || unions?.length === 1}
              onClick={() => {
                openPreviewModal({
                  ...values,
                  ...formData,
                });
              }}
            >
              <FontAwesomeIcon icon={faEye} className="mr-2" />
              {t("report-preview")}
            </Button>
            <Button
              color="info"
              className="d-flex align-items-center"
              type="submit"
              disabled={!hasValue}
            >
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
              {id ? t("update-report") : t("report-create")}
            </Button>
          </>
        );
      }}
    </BaseField>
  );
};

export default ReportButtons;
